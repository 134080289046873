'use strict';

const dependencyList = [
    '$scope',
    '$modal',
    'Locations',
    'PRINTER_CONSTANTS',
    'notificationTranslationHelper'
];
const SetupMerchantStationCtrl = function (
    $scope,
    $modal,
    Locations,
    PRINTER_CONSTANTS,
    notificationTranslationHelper
) {
    // ---- BEGIN: DI ----
    this.ngScope = $scope;
    this.ngModal = $modal;
    this.Locations = Locations;
    this.PRINTER_CONSTANTS = PRINTER_CONSTANTS;
    this.notificationTranslationHelper = notificationTranslationHelper;
    // ---- END: DI ----

};

SetupMerchantStationCtrl.prototype.loadStations = function () {
    const _self = this;
    const _getManufacturerList = function (protocol) {
        return (protocol == 'tcp') ? _self.PRINTER_CONSTANTS.tcpIpManufacturers
            : ((protocol == 'usb') ? _self.PRINTER_CONSTANTS.usbManufacturers
                : _self.PRINTER_CONSTANTS.bluetoothManufacturers);
    };

    _self.Locations.getLocationPOSStations({locationId: _self.ngScope.currentLocationId}, async function (response) {
        // get transformed printer obj by station
        const stationPrintersMap = await _self.Locations.getStationGroupedPrinters();
        for (const station of response.entries) {
            const transformedPrinterConfig = stationPrintersMap.get(station.posStationId);
            if (transformedPrinterConfig && transformedPrinterConfig.length) {
                for (const printer of transformedPrinterConfig) {
                    const manufacturerList = _getManufacturerList(printer.protocol);
                    const selectedManufacturer = _.findWhere(manufacturerList, {value: printer.printerManufacturer});
                    printer.selectedManufacturer = selectedManufacturer;
                }

                station.transformedPrinterConfig = transformedPrinterConfig;
            }
        }

        _self.posStations = response.entries;
    }, function (error) {
        _self.notificationTranslationHelper.notifyError(error.data.error, null, false);
    });
};

SetupMerchantStationCtrl.prototype.addStation = function () {
    const newStation = {
        isNew: true,
        locationId: this.ngScope.currentLocationId,
        bluetoothEnabled: true,
        posPrinters: [],
        cardTerminalProperties: {
            type: 'manual'
        }
    };

    this.editStation(newStation);
};

SetupMerchantStationCtrl.prototype.editStation = function (station) {
    const _self = this;
    const location = {
        locationId: _self.ngScope.currentLocationId,
        selectedPosStation: station
    };

    const modalInstance = _self.ngModal.open({
        templateUrl: 'setup/modals/wizardEditStation.tpl.html',
        controller: 'WizardEditStationCtrl as wizardEditStationCtrl',
        windowClass: 'setup-wizard modal__setup-wizard modal__edit-station',
        backdrop: 'static',
        resolve: {
            location: function () {
                return location;
            },
            srmDevices: function () {
                return _self.ngScope.srmDevices;
            },
            selectedCompanyId: function () {
                return _self.ngScope.currentCompanyId;
            },
            isMevBoxCompany: function () {
                return _self.ngScope.isMevBoxCompany;
            }
        }
    });

    modalInstance.result.then(function (result) {
        _self.loadStations(_self.ngScope.currentLocationId);
    });
};

SetupMerchantStationCtrl.$inject = dependencyList;
export default SetupMerchantStationCtrl;

'use strict';

// When we add a new less file we should import it
// into the './css/main.less' file and it will be
// imported to the app.js file

import './css/font-awesome.min.css';
import './css/font-awesome-new.min.css';
import './css/linearicons.css';
import './css/knedlikaicons.css';
import './css/ace-fonts.css';
import './css/ace.min.css';
import './css/ace-skins.min.css';
import './lib/ng-grid/ng-grid.css';
import './common/freshideas/freshideas.css';
import './lib/animate.css/animate.css';
import './common/components/styles.less';
import 'ui-select/dist/select.css';
import './css/main.less';


// Javascript imports
import 'intersection-observer'; // intersection-observer polyfill
import './lib/ace.min.js';
import './lib/ace-extra.min.js';

import * as $ from 'jquery';
window.$ = $;
import 'jquery-ui';

import * as angular from 'angular';
import * as Honeybadger from 'honeybadger-js';
import * as moment from 'moment';
import './app-config.js';
import * as ngCookies from 'angular-cookies';
import './lib/ui-bootstrap-tpls-0.13.4.js';

import './lib/ng-grid/build/ng-grid.js';
import './lib/jQuery.print.js';

import 'bootstrap';

import * as ngSanitize from 'angular-sanitize';
import * as angularTranslate from 'angular-translate';
import 'angular-wizard';
import * as angularTranslateStorageCookie from 'angular-translate-storage-cookie';
import * as angularTranslateStorageLocal from 'angular-translate-storage-local';
import * as angularTranslateLoaderPartial from 'angular-translate-loader-partial';
import * as angularTranslateLoaderStaticFiles from 'angular-translate-loader-static-files';

import freshideasSecurity from './common/security/freshideas-security.js';
import freshideasSecurityUser from './common/security/freshideas-security-user.js';
import freshideasSecurityInspector from './common/freshideas/freshideas-security-interceptor.js';
import freshideasSecurityPincode from './common/freshideas/freshideas-security-pincode.js';
import freshideasSecurityNotifications from './common/freshideas/freshideas-angular-notifications.js';
import freshideasAngularI18n from './common/freshideas/freshideas-angular-i18n.js';
import freshideasAngularDate from './common/freshideas/freshideas-angular-date.js';
import freshideasAngularFilters from './common/freshideas/freshideas-angular-filters.js';
import freshideasAngularUtils from './common/freshideas/freshideas-angular-utils.js';
import freshideasDirectivesJquery from './common/freshideas/freshideas-angular-jquery-directives.js';
import freshideasDirectivesCommon from './common/freshideas/freshideas-angular-directives.js';
import freshideasServicesBreadcrumbs from './common/services/breadcrumbs.js';
import freshideasServicesPrint from './common/services/print-service.js';
import freshideasServicesPrintStatus from './common/services/print-status-service.js';
import freshideasServicesPosStatus from './common/services/pos-status-service.js';
import freshideasServicesPosAlert from './common/services/pos-alert-service.js';
import freshideasServicesKioskModal from './common/services/kiosk-modal-service.js';
import freshideasServicesAudio from './common/services/audio-service.js';
import freshideasServicesUserActivityType from './common/services/user-activity-type-service.js';
import freshideasServicesPosBuilder from './common/services/pos-builder-service.js';
import freshideasServicesReceiptBuilder from './common/services/receipt-builder-service.js';
import freshideasServicesKds from './common/services/kds-service.js';
import freshideasServicesDateRange from './common/services/date-range-service.js';
import freshideasServicesGuestLabel from './common/services/guest-label-service.js';
import freshideasServicesPosUsers from './common/services/pos-users-service.js';
import freshideasServicesErrorLogging from './common/services/error-logging-service.js';
import freshideasServicesGateway from './common/services/gateway/gateway-service.js';
import freshideasServicesKiosk from './common/services/kiosk-service.js';
import freshideasServicesCustomReceipt from './common/services/custom-receipt-service.js';
import freshideasServicesPreorder from './common/services/preorder.service.js';
import freshideasServicesHelp from './common/services/nown-support-service.js';

import navBar from './common/controllers/nav-bar.ctrl.js';
import freshideasSetup from './setup/setup.js';
import freshideasSettings from './settings/settings.js';
import freshideasLocation from './locations/location.js';
import freshideasLocations from './locations/locations.js';
import freshideasInventory from './inventory/inventory.js';
import freshideasInventoryV2 from './inventory/v2/inventory.module.js';
import freshideasProducts from './products/products.module.js';
import freshideasProducts3 from './products/menuV2/products.module.js';
import freshideasKds from './kds/kds.js';
import freshideasPosOrder from './pos/pos.order.parent.ctrl.js';
import freshideasPosSmb from './pos/smb/pos.module.js';
import freshideasReports from './reports/reports.js';
import freshideasServicesConversionUtil from './common/services/conversionUtils.js';
import freshideasServicesLookup from './common/services/lookup-service.js';
import freshideasServicesLocations from './common/services/location-service.js';
import freshideasServicesExport from './common/services/export-service.js';
import freshideasServicesImport from './common/services/import-service.js';
import freshideasServicesCalculate from './common/services/calculate-service.js';
import freshideasServicesMonitoring from './common/services/monitoring-service.js';
import freshideasServicesEventListener from './common/services/event-listener-service.js';
import freshideasUsers from './users/users.js';
import freshideasResourcesLookup from './common/resources/lookup.js';
import freshideasResourcesUsers from './common/resources/users.js';
import freshideasResourcesShopify from './common/resources/shopify.js';
import freshideasResourcesNetSuite from './common/resources/netsuite.js';
import freshideasResourcesHomebase from './common/resources/homebase.js';
import freshideasResourcesOrganization from './common/resources/organization.js';
import freshideasResourcesCompany from './common/resources/company.js';
import freshideasResourcesSettings from './common/resources/settings.js';
import freshideasResourcesAdmin from './common/resources/admin.js';
import freshideasResourcesMeals from './common/resources/meals.js';
import freshideasResourcesLocation from './common/resources/location.js';
import freshideasResourcesMenu from './common/resources/menu.js';
import freshideasResourcesInventory from './common/resources/inventory.js';
import freshideasResourcesCashier from './common/resources/cashier-shift.js';
import freshideasResourcesReports from './common/resources/reports.js';
import freshideasResourcesOfflineCache from './common/resources/offline-cache.js';
import freshideasResourcesOfflineTransaction from './common/resources/offline-transaction.js';

import freshideasResourcesOfflineTimeCard from './common/resources/offline-timecard.js';
import freshideasResourcesOfflineShift from './common/resources/offline-shift.js';
import freshideasResourcesOfflineEmailSignup from './common/resources/offline-email-signup.js';

import freshideasResourcesDeliverect from './common/resources/deliverect.js';
import freshideasResourcesSolink from './common/resources/solink.js';

import freshideasResourcesBridgePromise from './common/resources/bridged-promise.js';
import freshideasResourcesIosTcpClient from './common/resources/ios-tcp-client.js';
import freshideasResourcesIosTcpClientV2 from './common/resources/ios-tcp-client-v2.js';
import freshideasResourcesErrorLogging from './common/resources/error-logging.js';
import freshideasResourcesFiitMps from './common/resources/fiit-mps.js';
import freshideasResourcesAlphaPay from './common/resources/alpha-pay.js';
import freshideasResourcesQuickBooks from './common/resources/quickbooks.js';
import freshideasPatrons from './patrons/patrons.js';
import freshideasPatronsGrow from './patrons/components/customers-grow/customers-grow.component.js';
import freshideasPatronsLoyalty from './patrons/components/customers-loyalty/customers-loyalty.component.js';
import freshideasPatronsMealPlans from './patrons/meal-plans/meal-plans.js';
import freshideasSystem from './system/system.module.js';
import lucovaResources from './common/resources/lucova.js';

import * as uiRouter from '@uirouter/angularjs';
import * as uiBootstrap from 'angular-ui-bootstrap';
import * as angularGrowl from 'angular-growl-v2-webpack';
import * as uiTree from 'angular-ui-tree';
import * as uiGrid from 'angular-ui-grid';
import * as uiSelect from 'ui-select';
import * as colorpickerModule from 'angular-bootstrap-colorpicker-webpack';
import * as ngStrap from 'angular-strap';
import freshideasNgGridTemplates from './common/freshideas/freshideas-ng-grid-templates.js';
import freshideasUiGridTemplates from './common/freshideas/freshideas-ui-grid-templates.js';
import freshideasAngularFieldDirective from './common/freshideas/freshideas-angular-field-directive.js';
import freshideasServicesCompanyAttributes from './common/services/company-attributes-service.js';
import freshideasServicesTransaction from './common/services/transaction-service.js';
import freshideasServicesSolink from './common/services/solink-service.js';
import './lib/angular-bindonce/bindonce.js';
import 'd3';
import 'nvd3';
import * as angularnvd3 from 'angular-nvd3';
import ngTouchstart from './common/directives/ng-touchstart-directive.js';
import 'angular-clock';


// we need to use script-loader for scripts which would normally be included
// inside index.html as a <script> tag. When included like this, any variables
// declared inside the script become global. When we including these scripts
// here via "import" in app.js, then those variables do not become global.
// Import with "script-loader" to preserve behaviour of them becoming global

// eslint-disable-next-line import/no-unresolved
import 'script-loader!./lib/StarWebPrintBuilder.js';
// eslint-disable-next-line import/no-unresolved
import 'script-loader!./lib/StarWebPrintTrader.js';
// eslint-disable-next-line import/no-unresolved
import 'script-loader!./lib/ng-grid/plugins/ng-grid-flexible-height.js';

import 'hammerjs';
import 'jquery-hammerjs';
import 'angular-hammer';
import * as angularTooltips from 'angular-tooltips';
import 'angular-ui-scroll';
import 'async';
import 'angular-barcode';

var AppConstants = require('./common/freshideas/app-constants.js');

var freshideas = angular.module('freshideas', [
    'app.config',
    ngCookies,
    ngSanitize,
    angularTranslate,
    angularTranslateStorageCookie,
    angularTranslateStorageLocal,
    angularTranslateLoaderPartial,
    angularTranslateLoaderStaticFiles,
    freshideasSecurity.name,
    freshideasSecurityUser.name,
    freshideasSecurityInspector.name,
    freshideasSecurityPincode.name,
    freshideasSecurityNotifications.name,
    freshideasAngularI18n.name,
    freshideasAngularDate.name,
    freshideasAngularFilters.name,
    freshideasAngularUtils.name,
    freshideasDirectivesJquery.name,
    freshideasDirectivesCommon.name,
    freshideasServicesBreadcrumbs.name,
    freshideasServicesPrint.name,
    freshideasServicesPrintStatus.name,
    freshideasServicesPosStatus.name,
    freshideasServicesPosAlert.name,
    freshideasServicesKioskModal.name,
    freshideasServicesAudio.name,
    freshideasServicesUserActivityType.name,
    navBar.name,
    freshideasSetup.name,
    freshideasSettings.name,
    freshideasLocation.name,
    freshideasLocations.name,
    freshideasProducts.name,
    freshideasProducts3.name,
    freshideasKds.name,
    freshideasInventory.name,
    freshideasInventoryV2.name,
    freshideasPosSmb.name,
    freshideasPosOrder.name,
    freshideasReports.name,
    freshideasServicesConversionUtil.name,
    freshideasServicesLookup.name,
    freshideasServicesLocations.name,
    freshideasServicesExport.name,
    freshideasServicesImport.name,
    freshideasServicesCalculate.name,
    freshideasServicesMonitoring.name,
    freshideasServicesEventListener.name,
    freshideasServicesPosBuilder.name,
    freshideasServicesReceiptBuilder.name,
    freshideasServicesKds.name,
    freshideasServicesDateRange.name,
    freshideasServicesGuestLabel.name,
    freshideasServicesPosUsers.name,
    freshideasServicesCompanyAttributes.name,
    freshideasServicesSolink.name,
    freshideasServicesTransaction.name,
    freshideasServicesErrorLogging.name,
    freshideasServicesGateway.name,
    freshideasServicesKiosk.name,
    freshideasServicesCustomReceipt.name,
    freshideasServicesPreorder.name,
    freshideasServicesHelp.name,
    freshideasUsers.name,
    freshideasResourcesLookup.name,
    freshideasResourcesUsers.name,
    freshideasResourcesShopify.name,
    freshideasResourcesNetSuite.name,
    freshideasResourcesHomebase.name,
    freshideasResourcesOrganization.name,
    freshideasResourcesCompany.name,
    freshideasResourcesSettings.name,
    freshideasResourcesAdmin.name,
    freshideasResourcesMeals.name,
    freshideasResourcesLocation.name,
    freshideasResourcesMenu.name,
    freshideasResourcesInventory.name,
    freshideasResourcesCashier.name,
    freshideasResourcesReports.name,
    freshideasResourcesOfflineCache.name,
    freshideasResourcesOfflineTransaction.name,
    freshideasResourcesOfflineTimeCard.name,
    freshideasResourcesOfflineShift.name,
    freshideasResourcesOfflineEmailSignup.name,
    freshideasResourcesDeliverect.name,
    freshideasResourcesSolink.name,
    freshideasResourcesBridgePromise.name,
    freshideasResourcesIosTcpClient.name,
    freshideasResourcesIosTcpClientV2.name,
    freshideasResourcesErrorLogging.name,
    freshideasResourcesFiitMps.name,
    freshideasResourcesAlphaPay.name,
    freshideasResourcesQuickBooks.name,
    freshideasPatrons.name,
    freshideasPatronsMealPlans.name,
    freshideasPatronsGrow.name,
    freshideasPatronsLoyalty.name,
    freshideasSystem.name,
    lucovaResources.name,
    uiRouter.default,
    uiBootstrap,
    'ui.scroll',
    angularGrowl,
    uiTree,
    uiGrid,
    uiSelect,
    'ngGrid',
    colorpickerModule,
    ngStrap,
    freshideasNgGridTemplates.name,
    freshideasUiGridTemplates.name,
    freshideasAngularFieldDirective.name,
    'pasvaz.bindonce',
    angularnvd3,
    ngTouchstart.name,
    'ds.clock',
    angularTooltips,
    'hmTouchEvents',
    'NownPlatform',
    'KioskBoard',
]);

freshideas.constant('TRANSACTION_STATUS', {
    INITIAL: 'initial',
    PENDING: 'pending',
    COMPLETED: 'completed',
    RETRY: 'retry',
    CANCELLED: 'cancelled',
    FAILED: 'failed',
    SUMMARY: 'summary',
    DEAD: 'dead',
    CARD_TERMINAL_PENDING: 'card_terminal_pending',
    CARD_TERMINAL_MANUAL: 'card_terminal_manual',
    CARD_TERMINAL_CANCELLED: 'card_terminal_cancelled',
    CARD_TERMINAL_TIMED_OUT: 'card_terminal_timed_out',
    CARD_TERMINAL_INIT_FAILED: 'card_terminal_init_failed',
    ALPHAPAY_PENDING: 'alphapay_pending',
    ALPHAPAY_LONG_PENDING: 'alphapay_long_pending',
    ALPHAPAY_CANCELLED: 'alphapay_cancelled',
    ALPHAPAY_ERROR: 'alphapay_error',
    ALPHAPAY_TIMED_OUT: 'alphapay_timed_out',
    DEFAULT_TERMINAL_INTERFACE_TIMEOUT: 180000 // 3 minutes default timeout from NownPOS in milliseconds
});

freshideas.constant('SORT_RECEIPT_ITEMS_TYPE', {
    QUANTITY: 'quantity',
    ITEM: 'name',
    COST: 'price',
    TAX: 'taxAmount',
    TOTAL: 'total',
    NONE: ''
});

freshideas.constant('SORT_POS_TYPE', {
    NAME: 'name',
    COLOR: 'color',
    PRICE: 'price',
    NONE: ''
});

freshideas.constant('PrintReceiptType', {
    TRANSACTION: 'transaction',
    REFUND: 'refund',
    GIFT: 'gift'
});

freshideas.constant('PrintType', {
    MERCHANT: 'merchant',
    CUSTOMER: 'customer',
    ALL: 'all'
});

freshideas.constant('UI_TENDER_TYPE', AppConstants.uiTenderTypeConstants);


freshideas.constant('TRANSACTION_SOURCE', AppConstants.transactionSourceConstants);

freshideas.constant('LOCALES', AppConstants.locales);
freshideas.constant('REQUEST_STATES', AppConstants.requestStates);

freshideas.constant('MENU_ITEM_TYPE', AppConstants.menuItemTypeConstants);

// This is a enum for the role types of user whose
// permission is NOBODY. This information is present in the
// roleType of FreshIdeasUser. This is heavily coupled
// with the enum RoleType on the Backend.
freshideas.constant('USER_ROLE_TYPE', {
    MANAGER: 'MANAGER',
    CASHIER: 'CASHIER',
    STATION: 'STATION',
    KDS: 'KDS',
    KIOSK: 'KIOSK',
    ACCOUNTANT: 'ACCOUNTANT',
});

freshideas.constant('DaysConstant', {
    MONDAY: 'MONDAY',
    TUESDAY: 'TUESDAY',
    WEDNESDAY: 'WEDNESDAY',
    THURSDAY: 'THURSDAY',
    FRIDAY: 'FRIDAY',
    SATURDAY: 'SATURDAY',
    SUNDAY: 'SUNDAY'
});

freshideas.constant('DTO_WHITELIST', {
    CASHIER_SHIFT: [
        'cashierShiftId',
        'companyId',
        'locationId',
        'posStationId',
        'servicePeriodId',
        'employeeId',
        'closedByEmployeeId',
        'shiftStartTime',
        'shiftEndTime',
        'shiftStartBalance',
        'shiftEndBalance',
        'currentTime',
        'timeZoneString',
        'posStationDTO',
    ],
});

freshideas.constant('MEV_STATUS', {
    NON: 0,
    OK: 1,
    UNAVAILABLE: 2,
    BYPASS: 3
});

// Enum for which copies of the receipt the user wants to print
// items or modifiers on.
freshideas.constant('PRINTOUT_TYPE', {
    ALL: 'ALL',
    KITCHEN_SHEET: 'KITCHEN_SHEET',
    CUSTOMER_RECEIPT: 'CUSTOMER_RECEIPT',
    NONE: 'NONE'
});

// Enum for preorder statuses
freshideas.constant('PREORDER_STATUS', {
    // 'print_pending' statuses is regarded as
    // the equivalent to a new preorder.
    // In case a new status is added in the future, this would need to be updated
    PENDING: 'pending',
    PRINT_PENDING: 'print_pending',
    ACKNOWLEDGED: 'acknowledged',
    COMPLETED: 'completed',
    CANCELLED: 'cancelled'
});

// Enum for Gift Card Sources
freshideas.constant('GIFTCARD_SOURCE', {
    // physical: source = 0
    // digital: source = 2
    SOURCE_TRANSACTION: 0,
    SOURCE_EXCHANGE: 1,
    SOURCE_API: 2
});

// Enum for payment methods (currently used in pos.tender)
freshideas.constant('PAYMENT_METHODS', {
    CARD: 1,
    APP: 2
});

freshideas.config([
    '$provide',
    function ($provide) {
        $provide.decorator('$modalStack', [
            '$delegate',
            '$injector',
            '$log',
            function ($delegate, $injector, $log) {
                /* Keep a reference to the original methods */
                var originalOpen = $delegate.open.bind($delegate);
                var originalClose = $delegate.close.bind($delegate);
                var originalDismiss = $delegate.dismiss.bind($delegate);
                var broadcast = function (event) {
                    var rootScope = $injector.get('$rootScope');
                    $log.debug('Broadcasting: ' + event);
                    rootScope.$broadcast(event);
                };
                $delegate.open = function (modalInstance, modalOptions) {
                    originalOpen(modalInstance, modalOptions);
                    broadcast('freshideas_modal_open');
                };
                $delegate.close = function (modalInstance, result) {
                    originalClose(modalInstance, result);
                    broadcast('freshideas_modal_close');
                };
                $delegate.dismiss = function (modalInstance, result) {
                    originalDismiss(modalInstance, result);
                    broadcast('freshideas_modal_close');
                };
                /* Return the original (but "augmented") service */
                return $delegate;
            }
        ]);

        $provide.decorator('$locale', ['$delegate', function ($delegate) {
          if ($delegate.id == 'en-us') {
            $delegate.NUMBER_FORMATS.PATTERNS[1].negPre = '-\u00A4';
            $delegate.NUMBER_FORMATS.PATTERNS[1].negSuf = '';
          }
          return $delegate;
        }]);
    }
]);

freshideas.config(['$httpProvider',
    function ($httpProvider) {

        var cachedResponses = {};
        var TIMEOUT = 10000;

        var getTimeout = function (url) {
            if (url.indexOf('/freshideas/web/reports/') > -1) {
                return 60000;
            }
            return TIMEOUT;
        };
        var addCache = function (url, data) {
            if (!url || !data) {
                return;
            }
            data.cachedTimestamp = Date.now();
            cachedResponses[url] = data;
        };

        var getCache = function (url, callback) {
            if (!callback) {
                callback('Callback required');
                return;
            }
            if (!url) {
                callback('url not specified');
                return;
            }
            try {
                callback(undefined, cachedResponses[url]);
            } catch (err) {
                callback(err);
            }
        };

        var cacheBlacklist = [
            '/freshideas/web/users/me',
            '/freshideas/web/users/meSession',
            '/freshideas/web/cashier/availablev1',
            '/freshideas/web/patron/byPatronKey',
            '/freshideas/web/patron/byPatronEmailAddress',
            '/freshideas/web/cashier/lookupPatronMealPlans',
            '/freshideas/web/cashier/giftCard',
            '/freshideas/web/cashier/upcLookup',
            '/freshideas/web/reports/shiftReport',
            '/freshideas/web/location/menuItemsToCache'
        ];

        var canCache = function (response) {
            return (response.config.cache == false) ? false
                : (cacheBlacklist.indexOf(response.config.url) === -1
                    && response.config.url.indexOf('/freshideas/web/') > -1
                    && response.config.method === 'GET');
        };

        var isPingPong = function (config) {
            return (config.url.indexOf('/freshideas/web/pingPong') > -1);
        };

        var buildUrl = function (response) {
            if (!response || !response.config) {
                return;
            }

            // this will help cache properly incase same resource name have to used for GET & other methods
            let method = response.config.method;
            let url = response.config.url;
            let params = response.config.params ? angular.copy(response.config.params) : undefined;

            if (params) {
                // Bad things will happen if you cache a request with a timestamp!
                // Always delete timestamps!
                // Ok. Bye!
                Object.keys(params).forEach((key) => {
                    if (key === 'cacheTimestamp') {
                        delete params[key];
                    }
                });
                params = response.config.paramSerializer(params);
            }

            url = params ? (url + '?' + params) : url;
            return method + '-' + url;
        };

        var apiInterceptor = [
            '$q', '$timeout', 'PosStatusService', '$injector',
            function ($q, $timeout, PosStatusService, $injector) {

                var connectionCallback = function (wasOffline, isOffline) {
                    /**
                     * LucovaWebSocket has a circular dependency. We should
                     * eventually cleanup this.
                     * Sadiq. Jan/2019
                     */
                    var LucovaWebSocket = $injector.get('LucovaWebSocket');
                    // Came back online after being offline
                    if (wasOffline && !isOffline) {
                        // When offline it is very possible the
                        // WebSocket was not able to detect we went
                        // offline and it's status will still report an
                        // "OPEN". We know this due to our ping/pong
                        // mechanism, so let's force the websocket to
                        // disconnect. If a shift is still started it
                        // will automatically reconnect.
                        if (LucovaWebSocket.isConnected()) {
                            LucovaWebSocket.connect(true);
                        }
                    }
                };
                return {
                    'request': function (config) {
                        if (config.url.indexOf('/freshideas/web/') > -1
                            && config.url.indexOf('/freshideas/web/reports/') === -1) {
                            var canceler = $q.defer();
                            config.timeout = canceler.promise;

                            if (!PosStatusService.isOffline() || isPingPong(config)) {
                                /**
                                 * We attache a timeout to every request to make sure
                                 * that our offline mode is respected. If the network connection
                                 * is down upstream (i.e router to ISP) the POS is unable to
                                 * detect this offline status until up to 30 seconds at
                                 * which point offline mode will be triggered. This timeout
                                 * wrappper is our way of ensuring offline mode works.
                                 */
                                let requestTimeout = getTimeout(config.url);
                                config.timerPromise = $timeout(function () {
                                    canceler.resolve();
                                }, requestTimeout);
                            } else {
                                // reject request because we are offline
                                config.timerPromise = $timeout(function () {
                                    canceler.resolve();
                                }, 1);
                            }
                        }
                        return config;
                    },
                    'response': function (response) {
                        if (response.config && response.config.timerPromise) {
                            connectionCallback(PosStatusService.isOffline(), false);
                            PosStatusService.setOffline(false);
                            $timeout.cancel(response.config.timerPromise);
                        }
                        // cache the api
                        if (canCache(response)) {
                            addCache(buildUrl(response), response);
                        }
                        return response;
                    },
                    'responseError': function (rejection) {
                        if (rejection && rejection.config && rejection.config.timerPromise) {
                            $timeout.cancel(rejection.config.timerPromise);
                        }

                        // get the data from apiCache
                        var deferred = $q.defer();
                        getCache(buildUrl(rejection), function (err, response) {
                            if (err || !response) {
                                return deferred.reject(rejection);
                            }
                            deferred.resolve(response);
                        });

                        return deferred.promise;
                    }
                };
            }
        ];
        return $httpProvider.interceptors.push(apiInterceptor);
    }
]);

freshideas.config([
    '$stateProvider',
    '$urlRouterProvider',
    '$compileProvider',
    '$logProvider',
    '$translateProvider',
    '$translatePartialLoaderProvider',
    'EnvConfig',
    function (
        $stateProvider,
        $urlRouterProvider,
        $compileProvider,
        $logProvider,
        $translateProvider,
        $translatePartialLoaderProvider,
        EnvConfig) {

        $urlRouterProvider.otherwise('/home');
        $logProvider.debugEnabled(EnvConfig.env !== 'production');

        $compileProvider.debugInfoEnabled(false);
        $compileProvider.commentDirectivesEnabled(false);
        $compileProvider.cssClassDirectivesEnabled(false);

        // Root state for the entire app
        // When defining a new path consider that rootUrl should match the
        // url property of the main nav button related to it, you can find
        // the url value of each main nav button in nav-bar.ctrl.js file.
        $stateProvider.state('freshideas', {
            templateUrl: 'loggedIn.tpl.html',
            controller: 'LoggedInCtrl'
        }).state('freshideas.home', {
            rootUrl: '',
            url: '/home',
            redirectTo: function () {
                // TODO add some logic here to redirect only as needed
                return 'freshideas.smb-pos';
            }
        }).state('freshideas.setup', {
            rootUrl: 'setup',
            url: '/setup',
            templateUrl: 'setup/setup.tpl.html',
            controller: 'SetupCtrl',
        }).state('freshideas.setup.paymentProcessor', {
            rootUrl: 'setup',
            url: '/payment-processor', // `/setup/payment-processor`
            templateUrl: 'setup/setup.payment-processor.html',
            controller: 'PaymentProcessorSetupCtrl'
        }).state('freshideas.setup.merchant', {
            rootUrl: 'setup',
            url: '/merchant', // `/setup/merchant`
            templateUrl: 'setup/setup.merchant.html',
            controller: 'MerchantSetupCtrl'
        })
        // .state('freshideas.locations', {
        //     rootUrl: 'locations',
        //     url: '/locations',
        //     templateUrl: 'locations/locations.tpl.html',
        //     controller: 'LocationsCtrl'
        // })
        .state('freshideas.menuInventory', {
            rootUrl: 'menuInventory',
            url: '/menuInventory',
            templateUrl: 'locations/location.tpl.html',
            controller: 'LocationCtrl'
        }).state('freshideas.settings', {
            rootUrl: 'settings',
            url: '/settings',
            templateUrl: 'settings/settings.tpl.html',
            controller: 'SettingsCtrl'
        }).state('freshideas.users', {
            rootUrl: 'users',
            url: '/users',
            templateUrl: 'users/users.tpl.html',
            controller: 'UsersCtrl'
        }).state('freshideas.patrons', {
            rootUrl: 'customers',
            url: '/customers',
            templateUrl: 'patrons/patrons.tpl.html',
            controller: 'PatronsCtrl'
        }).state('freshideas.customersGrow', {
            rootUrl: 'customers',
            url: '/customers/grow',
            templateUrl: 'patrons/components/customers-grow/customers-grow.tpl.html',
            controller: 'CustomersGrowCtrl'
        }).state('freshideas.customersLoyalty', {
            rootUrl: 'customers',
            url: '/customers/loyalty',
            templateUrl: 'patrons/components/customers-loyalty/customers-loyalty.tpl.html',
            controller: 'CustomersLoyaltyCtrl'
        }).state('freshideas.reports', {
            rootUrl: 'reports',
            url: '/reports',
            templateUrl: 'reports/reports.tpl.html',
            controller: 'ReportsCtrl'
        }).state('freshideas.report', {
            rootUrl: 'reports',
            url: '/reports',
            abstract: true,
            template: '<ui-view/>',
            controller: 'ReportBaseCtrl'
        }).state('freshideas.report.transactions', {
            rootUrl: 'reports',
            url: '/transactions',
            templateUrl: 'reports/transactions.tpl.html',
            controller: 'TransactionReportsCtrl'
        }).state('freshideas.report.summaryReport', {
            rootUrl: 'reports',
            url: '/summaryReport',
            templateUrl: 'reports/summaryReport.tpl.html',
            controller: 'SummaryReportCtrl'
        }).state('freshideas.report.salesReport', {
            rootUrl: 'reports',
            url: '/salesReport',
            templateUrl: 'reports/salesReport.tpl.html',
            controller: 'SalesReportCtrl'
        }).state('freshideas.report.dailySalesMealsServed', {
            rootUrl: 'reports',
            url: '/dailySalesMealsServed',
            templateUrl: 'reports/dailySalesMealsServed.tpl.html',
            controller: 'DailySalesMealsServedReportCtrl',
            data: {viewName: 'Daily Sales & Meals Served'}
        }).state('freshideas.report.activePatrons', {
            rootUrl: 'reports',
            url: '/activePatrons',
            templateUrl: 'reports/activePatrons.tpl.html',
            controller: 'ActivePatronsReportCtrl',
            data: {viewName: 'Active Patrons'}
        }).state('freshideas.report.patronCounts', {
            rootUrl: 'reports',
            url: '/salesPerHour',
            templateUrl: 'reports/patronCounts.tpl.html',
            controller: 'PatronCountsReportCtrl',
            data: {viewName: 'Patron Counts'}
        }).state('freshideas.report.patronActivity', {
            rootUrl: 'reports',
            url: '/patronActivity',
            templateUrl: 'reports/patronActivity.tpl.html',
            controller: 'PatronActivityReportCtrl',
            data: {viewName: 'Patron Activity'}
        }).state('freshideas.report.endOfShift', {
            rootUrl: 'reports',
            url: '/endOfShift',
            templateUrl: 'reports/endOfShiftReport.tpl.html',
            controller: 'EndOfShiftReport',
            data: {viewName: 'End of Shift Report'}
        }).state('freshideas.report.depositRefund', {
            rootUrl: 'reports',
            url: '/depositRefundReport',
            templateUrl: 'reports/depositRefundReport.tpl.html',
            controller: 'DepositRefundReportCtrl',
            data: {viewName: 'Deposit & Refund Report'}
        }).state('freshideas.report.sessionAudit', {
            rootUrl: 'reports',
            url: '/sessionAuditReport',
            templateUrl: 'reports/sessionAuditReport.tpl.html',
            controller: 'SessionAuditReportCtrl',
            data: {viewName: 'Session Audit Report'}
        }).state('freshideas.report.locationRevenue', {
            rootUrl: 'reports',
            url: '/locationRevenue',
            templateUrl: 'reports/locationRevenueReport.tpl.html',
            controller: 'LocationRevenueReport',
            data: {viewName: 'Location Revenue Report'}
        }).state('freshideas.report.mobileTransactions', {
            rootUrl: 'reports',
            url: '/mobileTransactions',
            templateUrl: 'reports/mobileTransactionsReport.tpl.html',
            controller: 'MobileTransactionsReportCtrl',
            data: {viewName: 'Mobile Transactions Report'}
        }).state('freshideas.report.patronMealPlanHistory', {
            rootUrl: 'reports',
            url: '/patronMealPlanHistory',
            templateUrl: 'reports/patronMealPlanHistory.tpl.html',
            controller: 'PatronMealPlanHistoryReport',
            data: {viewName: 'Patron Meal Plan History Report'}
        }).state('freshideas.report.itemSales', {
            rootUrl: 'reports',
            url: '/itemSales',
            templateUrl: 'reports/itemSales.tpl.html',
            controller: 'ItemSalesReport',
            data: {viewName: 'Advanced Items Sales Report'}
        }).state('freshideas.report.advancedItemSales', {
            rootUrl: 'reports',
            url: '/advancedItemSales',
            templateUrl: 'reports/advancedItemSales.tpl.html',
            controller: 'AdvancedItemSalesReport',
            data: {viewName: 'Advanced Items Sales Report'}
        }).state('freshideas.report.patronSpend', {
            rootUrl: 'reports',
            url: '/patronSpend',
            templateUrl: 'reports/patronSpend.tpl.html',
            controller: 'PatronSpendReport',
            data: {viewName: 'Patron Spend Report'}
        }).state('freshideas.report.participationRate', {
            rootUrl: 'reports',
            url: '/participationRate',
            templateUrl: 'reports/participationRate.tpl.html',
            controller: 'ParticipationRateReport',
            data: {viewName: 'Participation Rate Report'}
        }).state('freshideas.report.mealPlanSales', {
            rootUrl: 'reports',
            url: '/mealPlanSales',
            templateUrl: 'reports/mealPlanSales.tpl.html',
            controller: 'MealPlanSalesReport',
            data: {viewName: 'Sales by Meal Plan Report'}
        }).state('freshideas.report.employeeShifts', {
            rootUrl: 'reports',
            url: '/employeeShifts',
            templateUrl: 'reports/employeeShifts.tpl.html',
            controller: 'EmployeeShiftsReport',
            data: {viewName: 'Employee Shifts Report'}
        }).state('freshideas.report.lossPrevention', {
            rootUrl: 'reports',
            url: '/lossPrevention',
            templateUrl: 'reports/lossPrevention.tpl.html',
            controller: 'LossPreventionReport',
            data: {viewName: 'Loss Prevention Report'}
        }).state('freshideas.report.discounts', {
            rootUrl: 'reports',
            url: '/discounts',
            templateUrl: 'reports/discounts.tpl.html',
            controller: 'DiscountsReports',
            data: {viewName: 'Discounts & Rewards'}
        }).state('freshideas.report.labour', {
            rootUrl: 'reports',
            url: '/labour',
            templateUrl: 'reports/labour.tpl.html',
            controller: 'LabourReport',
            data: {viewName: 'Labour Report'}
        }).state('freshideas.report.giftCard', {
            rootUrl: 'reports',
            url: '/giftCard',
            templateUrl: 'reports/giftCard.tpl.html',
            controller: 'GiftCardReport',
            data: {viewName: 'Gift Card Report'}
        }).state('freshideas.report.inventoryStock', {
            rootUrl: 'reports',
            url: '/inventoryStock',
            templateUrl: 'reports/inventoryStock.tpl.html',
            controller: 'InventoryStockReport',
            data: {viewName: 'Inventory Stock Report'}
        }).state('freshideas.kds', {
            rootUrl: 'kds',
            url: '/kds?{view:string}',
            templateUrl: 'kds/templates/kds.tpl.html',
            controller: 'KdsCtrl',
            params: {view: 'incomplete'}
        }).state('freshideas.inventoryV2', {
            rootUrl: 'inventory',
            url: '/inventory',
            templateUrl: 'inventory/v2/templates/inventory.tpl.html',
            controller: 'InventoryV2Ctrl'
        }).state('freshideas.refunds', {
            rootUrl: 'refunds',
            url: '/refunds',
            templateUrl: 'pos/refund/pos.transaction.refund.start.tpl.html',
            controller: 'PosTransactionRefundStartCtrl'
        }).state('freshideas.preorderDashboard', {
            rootUrl: 'preorderDashboard',
            url: '/preorderDashboard',
            templateUrl: 'pos/smb/templates/pos.preorder.dashboard.tpl.html',
            controller: 'PreorderDashboardCtrl',
            params: {prevPage: null}
        });

        $translateProvider.useLoader('$translatePartialLoader', {
            // inject time hash as query param for cache busting
            urlTemplate: `/{part}/i18n/{lang}.json?${new Date().getTime().toString(36)}`
        });
        $translateProvider.fallbackLanguage('en-US');
        $translateProvider.preferredLanguage('en-US');
        $translateProvider.useSanitizeValueStrategy('escape');
        $translatePartialLoaderProvider.addPart('common');
        $translateProvider.forceAsyncReload(true);
    }
]);

freshideas.run([
    '$rootScope',
    '$state',
    '$templateCache',
    '$timeout',
    '$translate',
    '$log',
    'LookupService',
    'DateService',
    'Security',
    'LucovaWebSocket',
    'Logging',
    'Pure',
    'EnvConfig',
    'MonitoringService',
    'Users',
    'PingPong',
    'CommonOfflineCache',
    'Lucova',
    'PosAlertService',
    'LucovaBluetooth',
    'SmbPosService',
    'KioskService',
    'USER_ROLE_TYPE',
    function (
        $rootScope,
        $state,
        $templateCache,
        $timeout,
        $translate,
        $log,
        LookupService,
        DateService,
        Security,
        LucovaWebSocket,
        Logging,
        Pure,
        EnvConfig,
        MonitoringService,
        Users,
        PingPong,
        CommonOfflineCache,
        Lucova,
        PosAlertService,
        LucovaBluetooth,
        SmbPosService,
        KioskService,
        USER_ROLE_TYPE) {

        PingPong.startHeartBeat();
        // Pre-requisite for initialization: We must have a user. This will initiate the login flow
        // and after login will set the Security.getUser(), which we watch down below.
        Security.loadUser();

        /* Needed to determine if 'Take a Break' prompts the user to select required Homebase options without making
         multiple requests to check the Homebase connection. */
        Security.loadIsHomebase();

        var honeybadgerConfig = EnvConfig.honeybadger || {};

        Honeybadger.configure({
            apiKey: honeybadgerConfig.apiKey,
            projectRoot: honeybadgerConfig.projectRoot,
            revision: honeybadgerConfig.revision,
            environment: honeybadgerConfig.env || EnvConfig.env,
            host: 'api.honeybadger.io',
        });

        window.addEventListener('unhandledrejection', function (promiseRejectionEvent) {
            $log.error(promiseRejectionEvent.reason);
        });


        if (localStorage.terminalId === undefined) {
            localStorage.terminalId = Pure.generateUuid();
        }

        Honeybadger.setContext({
            terminalId: localStorage.terminalId,
            username: localStorage.username
        });

        if (!Logging.LucovaWebSocket) {
            Logging.LucovaWebSocket = LucovaWebSocket;
        }

        var initialize = function () {
            var setDateServiceLocale = function () {
                DateService.setLocale(Security.getUser().locale);
            };
            var serverAttributesSuccess = function (response) {
                Security.serverAttributes = response;
            };
            var lookupServerAttributes = function () {
                return LookupService.getServerAttributes().then(function (serverAttributes) {
                    serverAttributesSuccess(serverAttributes);
                }, function (error) {
                });
            };
            var lookupRoleTypesSuccess = function (response) {
                Security.roleTypes = response;
            };
            var lookupRoleTypes = function () {
                return LookupService.getRoleTypes().then(function (roleTypes) {
                    lookupRoleTypesSuccess(roleTypes);
                }, function (error) {
                });
            };
            lookupRoleTypes().then(lookupServerAttributes).then(function () {
                setDateServiceLocale();
                $rootScope.initialized = true;
            });

            $translate('translate.testOnly').then(function (result) {
                console.log('Translation successful:', result);
            }, function (error) {
                console.log('Translation failed:', error);
                $translate.refresh();
            });
        };

        $templateCache.put('wizard.html',
          '<div>\n' +
          '    <div class="steps" ng-if="indicatorsPosition === \'bottom\'" ng-transclude></div>\n' +
          '    <ul class="steps-indicator steps-{{steps.length}}" ng-if="!hideIndicators">\n' +
          '      <li ng-class="{default: !step.completed && !step.selected, current: step.selected, done: step.completed && !step.selected }" ng-repeat="step in steps">\n' +
          '        <a ng-click="goTo(step)"><span class="step-number">{{$index + 1}}</span></a>\n' +
          '          <span class="title">{{step.title|| step.wzTitle}}</span>\n' +
          '      </li>\n' +
          '    </ul>\n' +
          '    <div class="steps" ng-if="indicatorsPosition === \'top\'" ng-transclude></div>\n' +
          '</div>\n' +
          '');

        // Watch the security user for changes and re-initialize if changed
        $rootScope.$watch(function () {
            return Security.getUser();
        }, function (newValue, oldValue) {
            if (!Security.getUser() || Security.getUser().company.isSetupComplete) {
                $rootScope.initialized = false;
            }
            // Initialize if there is a user
            if (angular.isDefined(Security.getUser())) {
                initialize();
                var currentUser = Security.getUser();
                /* Load shift as soon as user logs in. This prevents 'Home' from taking user to 'Start Shift' screen in
                offline mode when the shift is already started. */
                // TODO: We might not want to run loadShift if not in offline mode.

                if (currentUser.roleType == USER_ROLE_TYPE.KIOSK) {
                    KioskService.setIsKiosk(true);
                } else {
                    KioskService.setIsKiosk(false);
                }
                if (currentUser.permission === 'KDS') {
                    $state.go('freshideas.kds');
                } else if (currentUser.roleType === USER_ROLE_TYPE.ACCOUNTANT) {
                    $state.go('freshideas.reports');
                }
            }
        });
        // Uninitialize if login is required
        var loginReqListener = $rootScope.$on('auth-loginRequired', function (event) {
            $rootScope.initialized = false;
        });
        $rootScope.$on('auth-loginConfirmed', function (event, user) {
            var company = user.company;
            var companyType = company.companyType;
            var isProvider = company.isProvider;
            var isSetupComplete = company.isSetupComplete;

            if (companyType === 'SMB') {
                if (isProvider || !isSetupComplete) {
                    $timeout(function () {
                        $state.go('freshideas.setup');
                    }, 0);
                } else {
                    loginToLucova();
                }
            }
        });
        $rootScope.$on('$destroy', function () {
            loginReqListener();
        });
        // Debug ui-router errors
        $rootScope.$on('$stateChangeError', console.log.bind(console));

        MonitoringService.initialize({
            name: 'memory-usage',
            type: 'once',
            threshold: 65,
            action: function (data) {
            }
        });

        MonitoringService.initialize({
            name: 'quick-charge',
            type: 'duration',
            threshold: 0,
            action: function (data) {
                var duration = data.value;
                var endContext = data.context.end || {};

                Users.logUserActivity({}, {
                    userActivityType: 'POS__TENDER_QUICK_CHARGE_TRANSACTION',
                    userActivityDetails: [{
                        attribute: 'duration',
                        value: duration
                    }, {
                        attribute: 'cashierShiftId',
                        value: endContext.cashierShiftId
                    }]
                });
            }
        });

        var loginToLucova = function () {
            Lucova.reset();

            var locationId, companyId;
            if (Security.getUser()) {
                var company = Security.getUser().company || {};
                companyId = company.companyId;
                locationId = company.locationId;
            }
            var terminalId = localStorage.terminalId;

            if (!terminalId || !locationId) {
                return;
            }

            // Only connect to payment server if environment is not the testing or development environment
            if (EnvConfig.env !== 'test' && EnvConfig.env !== 'development') {
                Lucova.login(terminalId, locationId)
                    .then((response) => loginResponse(response))
                    .catch((error) => {
                        $log.error(error);
                        // retry login in 30s
                        $timeout(loginToLucova, 30000);
                    });

                let loginResponse = function (response) {
                    if (!response.success) {
                        throw response;
                    }

                    CommonOfflineCache.cacheAllLucovaUsers(companyId);

                    var socketioUrl = response.socketio_url;
                    var socketioChannel = response.socketio_channel;
                    var username = response.user_name;
                    var authToken = response.auth_token;
                    LucovaWebSocket.init(socketioUrl, username, authToken, terminalId, socketioChannel);
                    LucovaWebSocket.connect(false);
                    LucovaWebSocket.getUsers();

                    var bleId = response.ble_id;
                    var ibeacon = response.ibeacon;
                    initBluetooth(bleId, ibeacon);
                };
            }
        };

        var initBluetooth = function (bleId, ibeacon) {
            try {
                LucovaBluetooth.initBluetooth(bleId, ibeacon, (isConnected) => {
                    if (!isConnected) {
                        PosAlertService.showAlertByName('no-usb');
                    }
                });
            } catch (err) {
                setTimeout(function () {
                    initBluetooth(bleId, ibeacon);
                }, 1000);
            }
        };

        setTimeout(function () {
            $('.body-loading').remove();
            $('.body-inner').show();
        }, 0);
    }
]);

if (!Date.now) {
    Date.now = function () {
        return new Date().getTime();
    };
}

import {currentSessionService} from './common/services/current-session.service.js';
currentSessionService(freshideas);

import {loggingService} from './common/services/logging-service.js';
loggingService(freshideas, moment);

import {eventListenerService} from './common/services/event-listener.service.js';
eventListenerService(freshideas);

import {promiseRetryService} from './common/services/promise-retry.service.js';
promiseRetryService(freshideas);

import {errorUtilService} from './common/services/error-util.service.js';
errorUtilService(freshideas);

import {pingPongService} from './common/services/ping-pong.service.js';
pingPongService(freshideas);

import {websocketService} from './common/services/websocket.service.js';
websocketService(freshideas);

import {platformService} from './common/services/platform.service.js';
platformService(angular);

import {triposService} from './common/services/tripos-service.js';
triposService(freshideas);

import {generalFilters} from './common/filters/general.filters.js';
generalFilters(freshideas);

import {mobileOrderModal} from './common/controllers/mobile-order-modal.ctrl.js';
mobileOrderModal(freshideas);

import {breadCrum} from './common/controllers/breadcrum.ctrl.js';
breadCrum(freshideas);

import {focusMe} from './common/controllers/focus-me.ctrl.js';
focusMe(freshideas);

import {loggedIn} from './common/controllers/logged-in.ctrl.js';
loggedIn(freshideas);

import {pure} from './common/freshideas/pure.js';
pure(freshideas);

import {posStatusController} from './pos-status-controller.js';
posStatusController(freshideas);

import {posExpired} from './pos.expired.js';
posExpired(freshideas);

import {cardTerminalOpenEdge} from './electron/cardterminal/openedge.js';
cardTerminalOpenEdge(freshideas);

import {cardTerminalVantiv} from './electron/cardterminal/vantiv.js';
cardTerminalVantiv(freshideas);

import {cardTerminalCtPayments} from './electron/cardterminal/ctpayments.js';
cardTerminalCtPayments(freshideas);

import {cardTerminalMoneris} from './electron/cardterminal/moneris.js';
cardTerminalMoneris(freshideas);

import {cardTerminalMonerisCore} from './electron/cardterminal/moneriscore.js';
cardTerminalMonerisCore(freshideas);

import {cardTerminalGlobalPay} from './electron/cardterminal/globalpay.js';
cardTerminalGlobalPay(freshideas);

import {cardTerminalHeartland} from './electron/cardterminal/heartland.js';
cardTerminalHeartland(freshideas);

import {cardTerminalIngenico} from './electron/cardterminal/ingenico.js';
cardTerminalIngenico(freshideas);

import {cardTerminalClover} from './electron/cardterminal/clover.js';
cardTerminalClover(freshideas);

import {cardTerminalPax} from './electron/cardterminal/pax.js';
cardTerminalPax(freshideas);

import {cardTerminalEquinoxEPOS} from './electron/cardterminal/equinox.epos.js';
cardTerminalEquinoxEPOS(freshideas);

import {cardTerminal} from './electron/cardterminal.js';
cardTerminal(freshideas);

import {lucovaBluetooth} from './electron/lucovabluetooth.js';
lucovaBluetooth(freshideas);

import {bluetoothTest} from './electron/bluetoothtest.js';
bluetoothTest(freshideas);

import {systemLog} from './electron/systemlog.js';
systemLog(freshideas);

import {bluetoothHelper} from './electron/bluetoothhelper.js';
bluetoothHelper(freshideas);

import {electronUpdater} from './electron/electronupdater.js';
electronUpdater(freshideas);

import {secondaryDisplay} from './electron/secondarydisplay.js';
secondaryDisplay(freshideas);

import {ipScanner} from './electron/ipscanner.js';
ipScanner(freshideas);

import {print} from './electron/printer/print.js';
print(freshideas);

import {okCancelPopup} from './pos/smb/ok.cancel.popup.tpl.js';
okCancelPopup(freshideas);

const CartBuilderService = require('./external/cart-builder-service.js');

freshideas.service('CartBuilderService', function () {
    return CartBuilderService;
});

import {mevBoxService} from './electron/mevBoxService.js';
mevBoxService(freshideas);

import {nownKioskBoard} from './common/components/kioskboard/nownkioskboard.js';
nownKioskBoard(angular, freshideas);

'use strict';

const angular = require('angular');
const TerminalCommon = require('../../electron/cardterminal/fixtures/terminal.common');

const dependencyList = [
    '$scope',
    '$modal',
    '$http',
    'EnvConfig',
    'Pure',
    'notificationTranslationHelper',
    'CompanyAttributesService',
    'PosAlertService',
    'Lookup',
    'Locations',
    'Menu',
    'Users',
    'PRINTER_CONSTANTS',
    'location',
    'srmDevices',
    'selectedCompanyId',
    'isMevBoxCompany'
];
const WizardEditStationCtrl = function (
    $scope,
    $modal,
    $http,
    EnvConfig,
    Pure,
    notificationTranslationHelper,
    CompanyAttributesService,
    PosAlertService,
    Lookup,
    Locations,
    Menu,
    Users,
    PRINTER_CONSTANTS,
    location,
    srmDevices,
    selectedCompanyId,
    isMevBoxCompany
) {
    // ---- BEGIN: DI ----
    this.ngScope = $scope;
    this.ngModal = $modal;
    this.ngHttp = $http;
    this.EnvConfig = EnvConfig;
    this.Pure = Pure;
    this.PosAlertService = PosAlertService;
    this.CompanyAttributesService = CompanyAttributesService;
    this.Lookup = Lookup;
    this.Locations = Locations;
    this.Menu = Menu;
    this.Users = Users;
    this.notificationTranslationHelper = notificationTranslationHelper;
    // ---- END: DI ----

    // ---- BEGIN: Instance props & methods
    this.isMevBoxCompany = (isMevBoxCompany == true);
    this.srmDevices = srmDevices;
    this.location = location;
    this.selectedCompanyId = selectedCompanyId;
    this.PRINTER_CONSTANTS = PRINTER_CONSTANTS;
    this.connectionTypes = PRINTER_CONSTANTS.CONNECTION_TYPES;
    this.users = [];
    this.availableCloverTerminals = [];
    this.cardTerminalTypes = TerminalCommon.SUPPORTED_CARD_TERMINALS;
    this.isLoading = {
        users: true,
        printers: true
    };
    // ---- END: Instance props & methods

    if (this.location) {
        this.selectedPosStation = this.location.selectedPosStation;
    }

    // get default SrmDevice
    if (this.isMevBoxCompany) {
        if (this.selectedPosStation.isNew) {
            // set a default srm
            this.selectedSrmDevice = _.find(srmDevices, (srmDevice) => {
                return srmDevice.defaultDevice;
            });

            this.selectedPosStation.locationSrmId = (this.selectedSrmDevice) ? this.selectedSrmDevice.id : null;
        } else {
            // fetch the assigned srm for this station
            this.selectedSrmDevice = _.find(srmDevices, (srmDevice) => {
                return srmDevice.id === this.selectedPosStation.locationSrmId;
            });
        }
    }

    this.loadSelectedStation();
    this.loadUsers();
};

WizardEditStationCtrl.prototype.openNewTabOauth = function (selectedPosStation) {
    const _self = this;
    if (selectedPosStation.cardTerminalProperties.type == 'clover_cloud') {
        var host = _self.EnvConfig.env === 'production' ? 'www.clover.com' : 'sandbox.dev.clover.com';
        var nownCloverId = _self.EnvConfig.env === 'production' ? '06JDJAZBQ0N34' : '61FGVHGXG5BAG';

        var url = 'https://' + host + '/oauth/authorize?client_id=' + nownCloverId;

        // $scope.modal = 'autoSettle';
        var modifyModal = _self.ngModal.open({
            templateUrl: 'pos/smb/templates/ok.cancel.popup.tpl.html',
            controller: 'OkCancelPopupCtrl',
            windowClass: 'smb-pos__end-shift-auto-settle-modal',
            animation: false,
            backdrop: 'static',
            resolve: {
                message: function () {
                    return 'You will need to allow NownPOS semi-integrated on the Clover dashboard. Press OK to continue.';
                }
            }
        }, function (error) {});


        return modifyModal.result.then(function (response) {
            window.open(url, '_blank');
        }, function () {
        });
    }
};

WizardEditStationCtrl.prototype.scanForCardTerminals = function () {
    const _self = this;
    const modalInstance = _self.ngModal.open({
        templateUrl: 'common/modals/modalScanForCardTerminals.html',
        controller: 'ScanForCardTerminalsController',
        windowClass: 'smb-pos products2',
        animation: false,
        backdrop: 'static'
    });

    modalInstance.result.then(function (result) {
        if (result && result.ip) {
            _self.selectedPosStation.cardTerminalProperties.ip = result.ip;
            _self.selectedPosStation.cardTerminalProperties.port = result.port;
        }
    });
};

WizardEditStationCtrl.prototype.lookupCloverDevices = function (cardPropertiesObj) {
    const _self = this;

    return _self.Lookup.companyAttributeSecure({key: 'clover_oauth_access_token'}).$promise.then(function (response) {
        // curl https://sandbox.dev.clover.com/v3/merchants/CZAKV0HSPD901/devices\?access_token\=12bf280c-4480-e4ee-9685-22e5f548c4ff | pp_json
        var merchantId = response.attributeValue.merchantId;
        var accessToken = response.attributeValue.accessToken;

        var host = _self.EnvConfig.env === 'production' ? 'www.clover.com' : 'sandbox.dev.clover.com';
        var url = 'https://'+host+'/v3/merchants/'+merchantId+'/devices?access_token=' + accessToken;

        _self.selectedPosStation.cardTerminalProperties.accessToken = accessToken;
        _self.ngHttp.get(url).then(function (response) {
            if (response && response.data && response.data.elements) {
                _self.availableCloverTerminals = response.data.elements.map((elem) => {
                    return {
                        deviceId: elem.id,
                        merchantId: elem.merchant.id,
                        serial: elem.serial,
                    };
                });

                _self.selectedPosStation.cardTerminalProperties.cloverConfig = _self.availableCloverTerminals.find((elem) => {
                    return elem.deviceId == cardPropertiesObj.deviceId;
                });
            }

        }, function (error) {

        });
    }, function (error) {

    });
};

WizardEditStationCtrl.prototype.loadSelectedStation = function () {
    const _self = this;
    const _getManufacturerList = function (protocol) {
        return (protocol == 'tcp') ? _self.PRINTER_CONSTANTS.tcpIpManufacturers
            : ((protocol == 'usb') ? _self.PRINTER_CONSTANTS.usbManufacturers
                : _self.PRINTER_CONSTANTS.bluetoothManufacturers);
    };

    _self.isLoading.printers = true;
    _self.Locations.getStationGroupedPrinters().then(function (stationPrinterMap) {
        const transformedPrinterConfig = stationPrinterMap.get(_self.selectedPosStation.posStationId);
        if (transformedPrinterConfig && transformedPrinterConfig.length) {
            for (const printer of transformedPrinterConfig) {
                const manufacturerList = _getManufacturerList(printer.protocol);
                const selectedManufacturer = _.findWhere(manufacturerList, {value: printer.printerManufacturer});
                printer.selectedManufacturer = selectedManufacturer;
            }

            _self.selectedPosStation.transformedPrinterConfig = transformedPrinterConfig;
        }

        _self.isLoading.printers = false;
    });

    if (_self.selectedPosStation.cardTerminalProperties) {
        var tmpCardPropObj = _self.cardTerminalTypes.find(function (terminalTypeObj) {
            return terminalTypeObj.type == _self.selectedPosStation.cardTerminalProperties.type;
        });

        if (tmpCardPropObj) {
            _self.selectedPosStation.cardTerminalProperties.name = tmpCardPropObj.name;
            _self.selectedPosStation.cardTerminalProperties.type = tmpCardPropObj.type;
            _self.selectedPosStation.cardTerminalProperties.config = tmpCardPropObj.config;

            if (tmpCardPropObj.config == 'oauth') {
                _self.lookupCloverDevices(_self.selectedPosStation.cardTerminalProperties);
            }
        }
    }
};

WizardEditStationCtrl.prototype.loadUsers = function () {
    const _self = this;

    _self.isLoading.users = true;
    _self.Users.listUsers({companyId: _self.selectedCompanyId, limit: -1}, function (response) {
        _self.isLoading.users = false;
        _self.users = _.filter(response.entries, function (user) {
            return (
                (user.email != 'api@lucova.com' && user.email != 'lucova.logging@lucova.com' && (user.roleName == 'Station' || user.roleName == 'Kiosk'))
                || (user.firstname !== 'API USER' && user.lastname !== 'DONT DELETE' && (user.roleName == 'Station' || user.roleName == 'Kiosk'))
                || (!user.autoCreated && (user.roleName == 'Station' || user.roleName == 'Kiosk'))
            );
        });
    }, function (error) {
        _self.isLoading.users = false;
    });
};

WizardEditStationCtrl.prototype.cardTerminalChanged = function () {
    const _self = this;
    const tmpCardPropObj = _self.cardTerminalTypes.find(function (terminalTypeObj) {
        return terminalTypeObj.type == _self.selectedPosStation.cardTerminalProperties.type;
    });

    if (tmpCardPropObj) {
        _self.selectedPosStation.cardTerminalProperties.config = tmpCardPropObj.config;
        _self.selectedPosStation.cardTerminalProperties.name = tmpCardPropObj.name;
        // make request to get list of devices
        if (_self.selectedPosStation.cardTerminalProperties.config == 'oauth') {
            _self.lookupCloverDevices(_self.selectedPosStation.cardTerminalProperties);
        }
    }
};

WizardEditStationCtrl.prototype.saveSelectedPosStation = function () {
    const _self = this;
    let isError = false; // Set to true if there is an issue with any of the forms
    let unassignPrinterIds = [];

    // Reset/Initialize error status
    _self.selectedPosStation.errors = {};

    // Check if a station name exists
    if (!_self.selectedPosStation.posStationName) {
        _self.selectedPosStation.errors.posStationName = true;
        isError = true;
    }

    // Check card terminal fields for errors
    let terminalConfig = _self.selectedPosStation.cardTerminalProperties;
    if (terminalConfig && Object.keys(terminalConfig).length) {
        switch (terminalConfig.config) {
            case 'manual':
                // ip and port should be blank
                if (terminalConfig.ip) {
                    terminalConfig.ip = null;
                }

                if (terminalConfig.port) {
                    terminalConfig.port = null;
                }
            break;
            case 'oauth':
                if (terminalConfig.ip) {
                    delete terminalConfig.ip;
                }
                if (terminalConfig.port) {
                    delete terminalConfig.port;
                }

                if (terminalConfig.cloverConfig) {
                    var cloverConfig = terminalConfig.cloverConfig;
                    delete terminalConfig.cloverConfig;

                    terminalConfig.deviceId = cloverConfig.deviceId;
                    terminalConfig.merchantId = cloverConfig.merchantId;
                }
            break;
            case 'ipport':
                if (!_self.Pure.ipTest(terminalConfig.ip)) {
                    _self.selectedPosStation.errors.cardTerminalIp = true;
                    isError = true;
                }

                if (!terminalConfig.port || terminalConfig.port < 0 || terminalConfig.port > 65535) {
                    _self.selectedPosStation.errors.cardTerminalPort = true;
                    isError = true;
                }

                if (!terminalConfig.printErrorReceipt) {
                    terminalConfig.printErrorReceipt = false;
                }

                if (terminalConfig.merchantId) {
                    delete terminalConfig.merchantId;
                }

                if (terminalConfig.accessToken) {
                    delete terminalConfig.accessToken;
                }

                if (terminalConfig.deviceId) {
                    delete terminalConfig.deviceId;
                }
                delete terminalConfig.cloverConfig;
            break;
            case 'epos':
                // Invalid card terminal IP
                if (!_self.Pure.ipTest(terminalConfig.ip)) {
                    _self.selectedPosStation.errors.cardTerminalIp = true;
                    isError = true;
                }

                if (!terminalConfig.port) {
                    _self.selectedPosStation.errors.cardTerminalPort = true;
                    isError = true;
                }

                if (!terminalConfig.username) {
                    _self.selectedPosStation.errors.cardTerminalEposUsername = true;
                    isError = true;
                }

                if (!terminalConfig.password) {
                    _self.selectedPosStation.errors.cardTerminalEposPassword = true;
                    isError = true;
                }

                terminalConfig.reportPrintout = (terminalConfig.reportPrintout != false);
            break;
            case 'middleware':
                // Invalid card terminal IP
                if (!_self.Pure.ipTest(terminalConfig.ip)) {
                    _self.selectedPosStation.errors.cardTerminalIp = true;
                    isError = true;
                }

                if (!terminalConfig.port) {
                    _self.selectedPosStation.errors.cardTerminalPort = true;
                    isError = true;
                }

                if (!terminalConfig.deviceId) {
                    _self.selectedPosStation.errors.cardTerminalDeviceId = true;
                    isError = true;
                }

                if (!terminalConfig.merchantId) {
                    _self.selectedPosStation.errors.cardTerminalMerchantId = true;
                    isError = true;
                }

                terminalConfig.reportPrintout = (terminalConfig.reportPrintout != false);
            break;
            default:
        }

        _self.selectedPosStation.cardTerminalProperties = terminalConfig;
    }

    // Check mevbox errors
    if (_self.isMevBoxCompany) {
        // if there are no selected SRMs
        if (!_self.selectedPosStation.locationSrmId) {
            isError = true;
        }
    }

    // Stop here if an error occured
    if (isError) {
        return false;
    }

    // Copy and assign relevant fields for sending to backend
    if (_self.selectedPosStation.errors) {
        delete _self.selectedPosStation.errors;
    }
    var posStationCopy = angular.copy(_self.selectedPosStation);
    if (posStationCopy.transformedPrinterConfig) {
        posStationCopy.posStationPrinters = [];
        for (const printer of _self.selectedPosStation.transformedPrinterConfig) {
            posStationCopy.posStationPrinters.push({
                stationConfigId: printer.posPrinterId,
                locationPrinterId: printer.locationPrinterId,
                posStationId: _self.selectedPosStation.posStationId,
                tenderReceiptPrinter: printer.tenderReceiptPrinter,
                secondaryReceiptPrinter: printer.secondaryReceiptPrinter,
                defaultSecondaryReceiptPrinter: printer.defaultSecondaryReceiptPrinter,
                labelPrinter: printer.labelPrinter
            });
        }
    }

    const callBack = (response) => {
        if (_self.selectedPosStation) {
            _self.ngScope.$close(response);
        }
    };

    let posStationPromise;
    if (posStationCopy.isNew) {
        posStationPromise = _self.Locations.addPOSStation({}, posStationCopy).$promise;
    } else {
        posStationPromise = _self.Locations.updatePOSStation({}, posStationCopy).$promise;
    }

    posStationPromise.then((response) => {
        let unassignPrinterIdPromise;
        if (unassignPrinterIds && unassignPrinterIds.length) {
            if (_self.CompanyAttributesService.hasMenuV2Enabled()) {
                const payload = {
                    printerIds: unassignPrinterIds,
                    companyId: _self.selectedCompanyId
                };
                unassignPrinterIdPromise = _self.Menu.unassignPrintersFromAssociatedItems({}, payload).$promise;
            } else {
                let promisesArr = [];
                unassignPrinterIds.forEach((id) => {
                    promisesArr.push(_self.Locations.unassignPrinterFromItems({
                        printerId: null,
                        locationId: _self.ngScope.currentLocationId,
                        assignAll: false,
                        assignFrom: id
                    }).$promise);

                    unassignPrinterIdPromise = Promise.all(promisesArr);
                });
            }

            unassignPrinterIdPromise.then((unassignResponse) => {
                callBack(response);
            }).catch((unassignErr) => {
                _self.PosAlertService.showAlertByName('general-error', {
                    title: 'general.error.unassign.printer.ttl'
                });
            });
        } else {
            callBack(response);
        }
    }).catch((err) => {
        _self.PosAlertService.showAlertByName('general-error', {
            title: 'general.error.save.station.ttl'
        });
    });
};

WizardEditStationCtrl.prototype.toggleStationSettings = function (printer, key) {
    const _self = this;
    const isEnabled = printer[key];
    const defaultSecondaryPrinters = _self.selectedPosStation.transformedPrinterConfig.filter((p) => p.secondaryReceiptPrinter && p.defaultSecondaryReceiptPrinter);
    const toStop = (key != 'labelPrinter')
        ? printer.selectedManufacturer.receiptType == 'sticker'
        : printer.selectedManufacturer.receiptType != 'sticker';

    if (toStop) {
        return;
    }

    if (key == 'defaultSecondaryReceiptPrinter') {
        if (isEnabled) {
            return;
        }

        _self.selectedPosStation.transformedPrinterConfig.forEach((p) => p.defaultSecondaryReceiptPrinter = false);
        printer.secondaryReceiptPrinter = true;
    } else if (key == 'secondaryReceiptPrinter') {
        if (isEnabled) {
            printer.defaultSecondaryReceiptPrinter = false;
        } else if (!defaultSecondaryPrinters.length) {
            _self.selectedPosStation.transformedPrinterConfig.forEach((p) => p.defaultSecondaryReceiptPrinter = false);
            printer.defaultSecondaryReceiptPrinter = true;
            _self.PosAlertService.showAlertByName('general', {
                title: 'general.alert.default.kitchen.printer.ttl',
                message: 'general.alert.default.kitchen.printer.msg'
            });
        } else {
            _self.PosAlertService.showAlertByName('set-all-items-kitchen-printer', {
                title: 'general.confirmation.default.kitchen.printer.ttl',
                message: 'general.confirmation.default.kitchen.printer.msg',
                modalCallback: function () {
                    _self.selectedPosStation.transformedPrinterConfig.forEach((p) => p.defaultSecondaryReceiptPrinter = false);
                    printer.defaultSecondaryReceiptPrinter = true;
                }
            });
        }
    }

    printer[key] = !isEnabled;
};

WizardEditStationCtrl.$inject = dependencyList;
export default WizardEditStationCtrl;

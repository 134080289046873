'use strict';

const moment = require('moment');

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosStartStatusCtrl', [
        '$scope',
        '$modal',
        '$timeout',
        '$translate',
        '$filter',
        'SmbPosService',
        'PosAlertService',
        'Security',
        'PosStatusService',
        'CompanyAttributesService',
        'CommonOfflineCache',
        'TransactionService',
        'OfflineTransactionService',
        'GatewayFiit',
        function (
            $scope,
            $modal,
            $timeout,
            $translate,
            $filter,
            SmbPosService,
            PosAlertService,
            Security,
            PosStatusService,
            CompanyAttributesService,
            CommonOfflineCache,
            TransactionService,
            OfflineTransactionService,
            GatewayFiit) {

            $scope.hasRecentStatsReceiptCounter = CompanyAttributesService.hasRecentStatsReceiptCounter();

            $scope.isOffline = function () {
                return PosStatusService.isOffline();
            };

            $scope.mobileOrdersEnabled = Security.getUser().company.hasPreorder;

            $scope.offlineTransactionCount = function () {
                return PosStatusService.getOfflineTransactionCount();
            };

            $scope.retryOfflineAllowed = CompanyAttributesService.hasOfflineForceRetry();

            $scope.retryOfflineQueue = () => {
                OfflineTransactionService.retryAllTransactions();
            };

            $scope.clipboardOfflineQueue = () => {
                OfflineTransactionService.copyOfflineQueueToClipboard();
            };

            $scope.demoItem = {
              'transactionId': -1,
              'showDetail': false,
              'patron': null,
              'items': [
                {
                  'transactionItemId': 0,
                  'quantity': 1,
                  'name': 'Bagel',
                  'upc': null,
                  'taxAmount': 0,
                  'price': 2,
                  'total': 0
                }
              ],
              'amount': 2.26,
              'subTotal': 2.00,
              'tax': 0.26,
              'terminalResponse': null,
              'refunded': false
            };

            $scope.loadStatus = function () {
                return SmbPosService.loadStatus();
            };

            $scope.getPatronName = function (patron) {
                return patron.firstName + ' ' + patron.lastName;
            };

            $scope.toggleTransaction = function (transaction) {
                transaction.showDetail = !transaction.showDetail;

                // Prevents multiple network calls when toggling visibility state
                if (transaction.detail) {
                    return;
                }
                SmbPosService.getTransactionResult(transaction.transactionId)
                    .then((response) => {
                        transaction.detail = response;

                        checkIfTransactionVoidable(transaction);
                    });
            };

            $scope.reprintTransaction = function (transaction) {
                transaction.refundStatus = 'NONE';
                $modal.open({
                    templateUrl: 'pos/smb/templates/pos.print.options.tpl.html',
                    controller: 'SmbPosTransactionRePrintOptionsCtrl as reprintOptionsCtrl',
                    windowClass: 'smb-pos smb-pos-popup',
                    animation: false,
                    backdrop: true,
                    keyboard: false,
                    resolve: {
                        transaction: function () {
                            return transaction;
                        },
                        isFetchTransaction: function () {
                            return false;
                        },
                        preorderDetails: function () {
                            return {
                                isPreorder: false,
                                preorder: {}
                            };
                        }
                    }
                });
            };

            $scope.startVoidTransaction = function (transaction) {
                TransactionService.startVoidTransaction(transaction);
            };

            $scope.showPrintStatusMessage = function () {
                $modal.open({
                    templateUrl: 'common/modals/modalPrintStatus.tpl.html',
                    controller: 'PosPrinterStatusCtrl',
                    windowClass: 'modal-50 printer-status-modal',
                    animation: false,
                    backdrop: true,
                    resolve: {
                        locationId: function () {
                            return SmbPosService.shift.locationId;
                        }
                    }
                }, function (error) {
                });
            };

            var checkIfTransactionVoidable = function (transaction) {
                var isVoidable = true;

                _.each(transaction.detail.receipt, function (item) {
                    var ignore = ['giftcard.create', 'giftcard.reload', 'loyalty.reload', 'loyalty.create'];
                    var isItemVoidable = !(ignore.indexOf(item.subtype) >= 0);
                    isVoidable = isVoidable && isItemVoidable;
                });

                _.each(transaction.detail.tenders, function (tender) {
                    if (tender.transactionType === 'OTHER') {
                        if (tender.transactionTenderDetail && tender.transactionTenderDetail.otherType === 'alphapay') {
                            isVoidable = false;
                        }
                    }
                });

                var isSameDay = moment(transaction.transactionDateTime).isSame(moment(), 'day');
                isVoidable = isVoidable && isSameDay;

                transaction._isVoidable = isVoidable;
            };

            $scope.displayOfflineOrderDescription = function () {
                PosAlertService.showAlertByName('general', {
                    title: 'smb.pos.queued.offline.orders.title',
                    message: 'smb.pos.queued.offline.orders.description'
                });
            };

            var posMenuAlertTimeout;

            var menuSwitchModalCallback = function (newMenuPeriod) {
                isShowingMenuModal = false;
                SmbPosService.switchMenuPeriod(newMenuPeriod);

                if (newMenuPeriod && newMenuPeriod.servicePeriodId) {
                    SmbPosService.updateShiftFiitServicePeriod(newMenuPeriod.servicePeriodId)
                        .catch((error) => {
                            PosAlertService.showAlertByName('general', {
                                title: 'general.error',
                                message: 'general.fiitServicePeriod.saveError'});
                        });

                    if (GatewayFiit.isEnabled()) {
                        GatewayFiit.reloadLocation();
                    }
                }
            };

            var menuSwitchModalDismissCallback = function (oldMenuPeriod, newMenuPeriod) {
                isShowingMenuModal = false;
                oldMenuPeriod.menuPeriodsCancelled.push(newMenuPeriod.menuPeriodId);
                SmbPosService.switchMenuPeriod(oldMenuPeriod, false);
            };

            var isShowingMenuModal = false;
            var posMenuAlert = function () {
                var menuPeriodToSwitchTo;
                var cachedMenuToSwitchTo = CommonOfflineCache.getMenuPeriodToSwitchTo();
                if (cachedMenuToSwitchTo && cachedMenuToSwitchTo.menuPeriod) {
                    menuPeriodToSwitchTo = angular.copy(cachedMenuToSwitchTo.menuPeriod);
                    CommonOfflineCache.resetMenuPeriodToSwitchTo();
                }

                var currentMenuPeriod = CommonOfflineCache.getCurrentMenuPeriod();
                if (menuPeriodToSwitchTo && currentMenuPeriod
                    && menuPeriodToSwitchTo.menuPeriodId !== currentMenuPeriod.menuPeriodId
                    && !isShowingMenuModal
                    && currentMenuPeriod.menuPeriodsCancelled
                    && !currentMenuPeriod.menuPeriodsCancelled.includes(menuPeriodToSwitchTo.menuPeriodId)) {
                    isShowingMenuModal = true;
                    PosAlertService.showAlertByName('general-alert', {
                        title: $translate.instant('menuPeriod.switch.ttl', {
                            menuPeriodName: menuPeriodToSwitchTo.description
                        }),
                        message: $translate.instant('menuPeriod.switch.msg', {
                            menuPeriodName: menuPeriodToSwitchTo.description,
                            startTime: $filter('dateTimeFormatter')(menuPeriodToSwitchTo.startTimeStr, 'HH:mm', 'hh:mm a')
                        }),
                        modalCallback: function () {
                            menuSwitchModalCallback(menuPeriodToSwitchTo);
                        },
                        dismissModalCallback: function () {
                            menuSwitchModalDismissCallback(currentMenuPeriod, menuPeriodToSwitchTo);
                        }
                    });
                }

                posMenuAlertTimeout = $timeout(posMenuAlert, 5000); // reset the timer
            };

            $scope.$on('$destroy', function () {
                $timeout.cancel(posMenuAlertTimeout);
            });

            $scope.init = function () {
                $scope.status = SmbPosService.status;
                $scope.loadStatus();
                if (CompanyAttributesService.hasMenuPeriodsEnabled()) {
                    posMenuAlert();
                }
            };

            $scope.init();
        }
    ]);
};

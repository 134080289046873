'use strict';

const paramList = [
    '$scope',
    'CurrentSession',
    'CompanyAttributesService',
    'BridgedPromise',
    'Locations',
    'Menu'
];
const SetupMerchantPrinterReceiptCtrl = function (
    $scope,
    CurrentSession,
    CompanyAttributesService,
    BridgedPromise,
    Locations,
    Menu
) {
    // const _self = this;
    // ------ BEGIN: DI --------
    this.ngScope = $scope;
    this.CurrentSession = CurrentSession;
    this.CompanyAttributesService = CompanyAttributesService;
    this.BridgedPromise = BridgedPromise;
    this.Locations = Locations;
    this.Menu = Menu;
    // ------ END: DI -------
    // ------ BEGIN: instance Props & Methods --------
    this.sessionCompany = this.CurrentSession.getCompany();
    this.editAction = 'action__printer-add';
    this.selectedPrinter = {
        isNew: true
    };
    this.printers = {
        isLoading: false,
        entries: []
    };
    this.receiptSettings = {
        secondaryPrinting: {
            isLoading: false,
            isEnabled: this.CompanyAttributesService.isSecondaryPrintingEnabled(),
            isAvailable: true,
            options: [
                {
                    id: 'separateItemReceipt',
                    label: 'setupWizard.merchant.receipt.separateItemsOnSecondary.label',
                    description: 'setupWizard.merchant.receipt.separateItemsOnSecondary.description',
                    isEnabled: this.CompanyAttributesService.hasPrintItemsSeparatelyOnSecondary()
                },
                {
                    id: 'expediteItemReceipt',
                    label: 'setupWizard.merchant.receipt.ticketPrePrint.label',
                    description: 'setupWizard.merchant.receipt.ticketPrePrint.description',
                    isEnabled: this.CompanyAttributesService.hasTicketPrePrint()
                }
            ]
        }
    };
    // ------ END: instance Props & Methods ---------

    // localScope props & methods
    this.ngScope.currentScreen = 'landing__printer-screen';
    // INIT
    this.loadPrinters(this.CurrentSession.getCompany().locationId, true);

    const deregisterLoadPrintersEvnt = this.ngScope.$on('printers:load', this.loadPrinters.bind(this, this.CurrentSession.getCompany().locationId, true));
    this.ngScope.$on('$destroy', function () {
        deregisterLoadPrintersEvnt();
    });
};

SetupMerchantPrinterReceiptCtrl.prototype.carouselScroll = function (direction) {
    const CARD_WIDTH = 320;
    const SCROLL_BY = CARD_WIDTH / 2;
    const elem = document.getElementsByClassName('carousel-panel')[0];
    elem.scrollBy((direction != 'left') ? SCROLL_BY : (-1 * SCROLL_BY), 0);
};

SetupMerchantPrinterReceiptCtrl.prototype.goToScreen = function (screen = 'landing__printer-screen', loadPrinters = false) {
    const _self = this;

    if (loadPrinters) {
        this.loadPrinters(this.CurrentSession.getCompany().locationId, loadPrinters).finally(function () {
            _self.ngScope.currentScreen = screen;
            _self.ngScope.settingsUpdated();
        });

        return;
    }

    this.ngScope.currentScreen = screen;
};

SetupMerchantPrinterReceiptCtrl.prototype.toggleReceiptSettings = function (object, key, value) {
    object[key] = !value;
};

SetupMerchantPrinterReceiptCtrl.prototype.updatePrinterAndReceiptSettings = function () {
    const attributesPayload = {
        'receipt__secondary_printing_disabled': !this.receiptSettings.secondaryPrinting.isEnabled,
        'receipt__separate_items_on_secondary': this.receiptSettings.secondaryPrinting.options[0].isEnabled,
        'receipt__ticket_pre_print': this.receiptSettings.secondaryPrinting.options[1].isEnabled
    };

    if (this.ngScope.currentScreen != 'landing__printer-screen') {
        this.ngScope.$broadcast('printer-screen__edit:save');
    } else {
        this.ngScope.updateReceiptSettings(attributesPayload);
    }
};

SetupMerchantPrinterReceiptCtrl.prototype.updateSecondaryPrintingSettings = function (type, toEnable = false, attributesPayload) {
    const ngScope = this.ngScope;
    if (this.receiptSettings.secondaryPrinting.isLoading) {
        return;
    }

    this.receiptSettings.secondaryPrinting.isLoading = true;
    const _success = function (response) {
        ngScope.settingsUpdated();
        this.receiptSettings.secondaryPrinting.isLoading = false;
    };
    const boundedSuccess = _success.bind(this);
    const _error = function (error) {
        ngScope.settingsUpdated(false);
        this.receiptSettings.secondaryPrinting.isLoading = false;
    };
    const boundedError = _error.bind(this);

    switch (type) {
        case 'item-printer__unlink': // remove any assigned printer from all items
            if (this.CompanyAttributesService.hasMenuV2Enabled()) {
                this.Menu.unassignPrintersFromAssociatedItems({}, {
                    unassignAll: true,
                    companyId: ngScope.currentCompanyId
                }, boundedSuccess, boundedError);
            } else {
                this.Locations.unassignPrinterFromItems({
                    printerId: null,
                    locationId: ngScope.currentLocationId,
                    assignAll: true,
                }, boundedSuccess, boundedError);
            }
        break;
        case 'item-printer__toggle': // disable secondary printing on assigned item printer
            if (this.CompanyAttributesService.hasMenuV2Enabled()) {
                this.Menu.toggleKitchenPrintingForAllItems({}, {
                    companyId: ngScope.currentCompanyId,
                    enableKitchenPrintingForAllItems: toEnable
                }, boundedSuccess, boundedError);
            } else {
                this.Locations.toggleKitchenPrintingForAllItems({
                    locationId: ngScope.currentLocationId,
                    enableKitchenPrintingForAllItems: toEnable
                }, boundedSuccess, boundedError);
            }
        break;
        default:
            // do nothing
    }
};

SetupMerchantPrinterReceiptCtrl.prototype.loadPrinters = function (locationId, cacheForceUpdate) {
    const _self = this;
    _self.printers.isLoading = true;

    return new Promise(function (resolve, reject) {
        _self.Locations.getLocationPrinters({locationId: locationId}, cacheForceUpdate).then(function (response) {
            _self.printers.isLoading = false;
            _self.printers.entries = response;
            resolve(response);
        }).catch(function (error) {
            _self.printers.isLoading = false;
            reject(error);
        });
    });
};

SetupMerchantPrinterReceiptCtrl.prototype.editPrinter = function (action, printer) {
    const newPrinter = {
        printerName: '',
        bluetoothName: '',
        ipAddress: null,
        port: null,
        selectedManufacturer: {},
        selectedModel: {},
        protocol: 'tcp',
        isNew: true,
        locationId: this.sessionCompany.locationId,
        assignedStationSettings: []
    };

    this.selectedPrinter = (action != 'action__printer-add') ? printer : newPrinter;
    this.editAction = action;
    this.goToScreen('edit__printer-screen');
};

SetupMerchantPrinterReceiptCtrl.$inject = paramList;
export default SetupMerchantPrinterReceiptCtrl;

'use strict';

/* globals ngGridFlexibleHeightPlugin */

const angular = require('angular');
const freshideasResourcesLocations = require('../common/resources/location.js').default;

const freshideasLocations = angular.module('freshideas.locations', [freshideasResourcesLocations.name, 'mgo-angular-wizard'])
    .controller('LocationsCtrl',
    ['$scope',
    '$modal',
    '$q',
    '$timeout',
    '$filter',
    '$location',
    'Locations',
    'Company',
    '$translate',
    'notificationTranslationHelper',
    'ErrorUtil',
    'EnvConfig',
    'Utils',
    'NgGridSortService',
    'NgGridTemplateService',
        function (
            $scope,
            $modal,
            $q,
            $timeout,
            $filter,
            $location,
            Locations,
            Company,
            $translate,
            notificationTranslationHelper,
            ErrorUtil,
            EnvConfig,
            Utils,
            NgGridSortService,
            NgGridTemplateService) {
            $scope.selectedLocations = [];
            $scope.selectedCompanyId = undefined;

            $scope.locationsPagingOptions = {
                currentRowNumber: 0,
                currentPage: 1,
                pageSize: 10,
                startRecord: 0,
                endRecord: 0,
                pageSizeOptions: Utils.buildDefaultPageSizes(),
            };

            $scope.init = function () {
                $scope.loadCompanies();
            };

            $scope.getCompanyName = function (companyId) {
                var companyName = '';
                var found = _.find($scope.companies, function (entry) {
                    return entry.companyId === companyId;
                });
                if (angular.isDefined(found)) {
                    companyName = found.companyName;
                }
                return companyName;

            };

            $scope.loadCompanies = function () {
                Company.liteCompanies(function (response) {
                    $scope.companies = response.entries;
                    if (!angular.isDefined($scope.selectedCompanyId) && angular.isDefined($scope.companies)) {
                        $scope.selectedCompanyId = $scope.companies[0].companyId;
                    }
                    $scope.loadLocations();
                }, function (error) {
                    notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
                });
            };

            $scope.addNewLocation = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalEditLocation.tpl.html',
                    controller: 'EditLocationCtrl',
                    windowClass: 'modal-70',
                    resolve: {
                        location: function () {
                            return undefined;
                        },
                        selectedCompanyId: function () {
                            return $scope.selectedCompanyId;
                        },
                        companies: function () {
                            return $scope.companies;
                        },
                        isEdit: function () {
                            return false;
                        },
                    },
                    backdrop: 'static',
                });
                modalInstance.result.then(
                    function (location) {
                        notificationTranslationHelper.notifyMessage('location.addLocation.success');
                        $scope.loadLocations();
                    });
            };

            $scope.editLocation = function (location) {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalEditLocation.tpl.html',
                    controller: 'EditLocationCtrl',
                    windowClass: 'modal-70',
                    resolve: {
                        location: function () {
                            return location;
                        },
                        selectedCompanyId: function () {
                            return $scope.selectedCompanyId;
                        },
                        companies: function () {
                            return $scope.companies;
                        },
                        isEdit: function () {
                            return true;
                        },
                    },
                    backdrop: 'static',
                });
                modalInstance.result.then(
                    function (location) {
                        notificationTranslationHelper.notifyMessage('location.editLocation.success', {name: location.name});
                        $scope.loadLocations();
                    });
            };

            $scope.editPOSStations = function (location) {
                $modal.open({
                    templateUrl: 'locations/location.edit.posStations.tpl.html',
                    controller: 'SetupMerchantStationCtrl as setupMerchantStationCtrl',
                    windowClass: 'modal-fullscreen',
                    resolve: {
                        location: function () {
                            return location;
                        },
                        selectedCompanyId: function () {
                            return $scope.selectedCompanyId;
                        },
                    },
                    backdrop: 'static',
                });
            };

            $scope.getExportUrl = function (location) {
                return $location.protocol() + '://' + $location.host() + ':' + $location.port() + '/freshideas/web/location/export?locationId=' + location.locationId;
            };

            $scope.importMenuItems = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalPatronImport.tpl.html',
                    controller: 'ImportMenuCtrl',
                    windowClass: 'modal-50',
                    backdrop: 'static',
                });
                modalInstance.result.then(
                    function (user) {
                        $scope.loadLocations();
                    });
            };

            $scope.deleteLocation = function (location, isBatch) {
                return Locations.delete({locationId: location.locationId},
                    function (response) {
                        if (!isBatch) {
                            notificationTranslationHelper.notifyMessage('locations.locationDelete.success');
                            $scope.loadLocations();
                        }
                    }, function (error) {
                        notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
                    }).$promise;
            };

            $scope.deleteSelectedLocations = function () {
                var deletedPromises = [];
                _.each($scope.selectedLocations, function (entry) {
                    deletedPromises.push($scope.deleteLocation(entry, true));
                });

                $q.all(deletedPromises).then(function () {
                    notificationTranslationHelper.notifyMessage('locations.deleteSelected.success');
                    $scope.loadLocations();
                });
                $scope.selectedLocations.length = 0;
            };

            $scope.loadLocations = function () {
                Locations.getLocations(function (response) {
                    $scope.locations = response.entries;
                });
            };

            $scope.editPrinters = function (location) {
                $modal.open({
                    templateUrl: 'locations/location.edit.printers.tpl.html',
                    controller: 'EditLocationPrintersCtrl',
                    windowClass: 'modal-fullscreen',
                    resolve: {
                        location: function () {
                            return location;
                        },
                        selectedCompanyId: function () {
                            return $scope.selectedCompanyId;
                        },
                    },
                    backdrop: 'static',
                });
            };

            $scope.gridOptions = {
                columnDefs: [
                    {
                        field: 'locationId',
                        displayName: 'location.locationId',
                        width: '*',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    },
                    {
                        field: 'companyId',
                        displayName: 'general.organization',
                        width: '**',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text>{{getCompanyName(COL_FIELD)}}</span><div>',
                    },
                    {
                        field: 'locationName',
                        displayName: 'location.name',
                        width: '**',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    },
                    {
                        field: '',
                        displayName: 'general.actions',
                        width: '130',
                        headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                        cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text><div class="action-buttons">' +
                        '<a class="blue" ng-click="editLocation(row.entity)" has-perm resource=" locations:edit"><i class="fa fa-pencil bigger-130"></i></a>' +
                        '<a class="blue" ng-click="editPOSStations(row.entity)"  popover-trigger="mouseenter" popover-append-to-body="true" popover="Edit POS Stations" has-perm resource=" locations:edit_pos_locations"><i class="fa fa-calculator bigger-130"></i></a>' +
                        '<a class="blue" ng-href="{{getExportUrl(row.entity)}}" popover-trigger="mouseenter" popover-append-to-body="true" popover="Export Location POS Menu" has-perm resource="locations:export_location_pos_menu"><i class="fa fa-download bigger-130"></i></a>' +
                        '<a class="blue" ng-click="editPrinters(row.entity)"  popover-trigger="mouseenter" popover-append-to-body="true" popover="Edit Kitchen Printers" has-perm resource="locations:edit_printers"><i class="fa fa-print bigger-130"></i></a>' +
                        '<a class="red" confirm-modal confirm-message="{{\'location.confirmDelete\' | translate}}" confirm-title="{{\'location.confirmDelete.title\' | translate}}" on-confirm="deleteLocation(row.entity)" has-perm resource="locations:delete"><i class="fa fa-trash-o bigger-130"></i></a>' +
                        '</div></span></div>',
                    },
                ],
                plugins: [new ngGridFlexibleHeightPlugin({minHeight: 520})],
                showFooter: true,
                footerRowHeight: 30,
                multiSelect: true,
                showSelectionCheckbox: true,
                selectedItems: $scope.selectedLocations,
                enableSorting: true,
                selectWithCheckboxOnly: true,
                enableHighlighting: true,
                footerTemplate: '<div class="grid-footer"><div class="col-xs-4"></div><div class="col-xs-4"><pager align="false" ng-model="locationsPagingOptions.currentPage" ' +
                'total-items="locationsPagingOptions.totalItems" items-per-page="locationsPagingOptions.pageSize" ng-change="loadLocations();"></pager>' +
                '</div><div class="col-xs-4 align-right"><span translate="general.records"/> {{locationsPagingOptions.startRecord}} - {{locationsPagingOptions.endRecord}}</div></div>',
                data: 'locations',
            };
            $scope.init();
        }])
    .controller('EditLocationPOSMenuCtrl', ['$scope', '$modalInstance', '$modal', 'Inventory', 'Menu', 'Locations', 'location', 'selectedCompanyId', 'Settings', 'Lookup', 'Security', '$translate', 'notificationTranslationHelper', 'ErrorUtil', 'EnvConfig', 'Utils', 'NgGridSortService', 'NgGridTemplateService',
        function ($scope, $modalInstance, $modal, Inventory, Menu, Locations, location, selectedCompanyId, Settings, Lookup, Security, $translate, notificationTranslationHelper, ErrorUtil, EnvConfig, Utils, NgGridSortService, NgGridTemplateService) {
            $scope.location = location;
            $scope.init = function () {
                $scope.getServicePeriods();
                Inventory.inventoryByLocation({'locationId': location.locationId}, function (response) {
                    $scope.inventoryItems = response;
                });
            };

            $scope.loadServicePeriodMenu = function (tab) {
                Menu.getLocationServicePeriodMenuItems({
                    'locationId': $scope.location.locationId,
                    'servicePeriodId': tab,
                }, function (response) {
                    $scope.menuItems = response.entries;
                });
            };

            $scope.addNewMenuItem = function (tab, isInventoryItem) {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalAddPosMenuItem.tpl.html',
                    windowClass: 'modal-50',
                    controller: 'AddMenuItemCtrl',
                    resolve: {
                        location: function () {
                            return $scope.location;
                        },
                        servicePeriodId: function () {
                            return tab;
                        },
                        isInventoryItem: function () {
                            return isInventoryItem;
                        },
                        taxRates: function () {
                            return $scope.taxRates;
                        },
                        inventoryItems: function () {
                            return $scope.inventoryItems;
                        },
                    },
                    backdrop: 'static',
                });
                modalInstance.result.then(
                    function (location) {
                        notificationTranslationHelper.notifyMessage('location.editLocationPOSMenu.success');
                        $scope.loadLocations();
                    });
            };

// Get the set of service periods and pos menus that are configured for the
// location.
            $scope.getServicePeriods = function () {
                Settings.getServicePeriods({'companyId': $scope.location.companyId}, function (response) {
                    $scope.servicePeriods = response.entries;

                    // create a map of servicePeriodId and servicePeriodName
                    $scope.servicePeriodMap = {};
                    _.each($scope.servicePeriods, function (entry) {
                        $scope.servicePeriodMap[entry.servicePeriodId] = entry.code;
                    });

                }, function (error) {
                    notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
                });
            };

            $scope.init();
        }])
    .
    controller('EditLocationCtrl', ['$scope', '$modalInstance', '$modal', '$q', '$timeout', '$filter', 'Locations', 'location', 'companies', 'isEdit', 'selectedCompanyId', 'Lookup', 'Meals', 'Settings', 'Security', '$translate', 'notificationTranslationHelper', 'ErrorUtil', 'EnvConfig', 'Utils', 'NgGridSortService', 'NgGridTemplateService',
        function ($scope, $modalInstance, $modal, $q, $timeout, $filter, Locations, location, companies, isEdit, selectedCompanyId, Lookup, Meals, Settings, Security, $translate, notificationTranslationHelper, ErrorUtil, EnvConfig, Utils, NgGridSortService, NgGridTemplateService) {
            $scope.editMode = isEdit;
            $scope.companies = companies;

            $scope.addLocation = function () {
                Locations.add({}, $scope.location, function (response) {
                    $modalInstance.close(response);
                }, function (error) {
                    notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
                });
            };

            $scope.editLocation = function () {
                Locations.update({}, $scope.location, function (response) {
                    $modalInstance.close(response);
                }, function (error) {
                    notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
                });
            };

            $scope.locationChanged = function () {
                // Since the company has changed, that means that the meal
                // plans, tender types, or service periods are any good.
                $scope.location.tenderTypeIds = [];
                $scope.location.mealPlanIds = [];
                $scope.location.servicePeriodIds = [];
                $scope.getActiveMealPlans();
            };

            $scope.getActiveMealPlans = function () {

                Meals.getActiveMealPlans({'companyId': $scope.location.companyId}, function (response) {
                    $scope.meals = response.entries;
                    $scope.getServicePeriods();
                }, function (error) {
                    notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
                });
            };

            $scope.$watch('location.companyId', function (newValue, oldValue) {
                if (angular.isDefined(oldValue) && oldValue !== newValue) {
                    if (angular.isDefined($scope.location.companyId)) {
                        $scope.locationChanged();
                    }
                }
            }, true);

            $scope.getServicePeriods = function () {
                Settings.getServicePeriods({'companyId': $scope.location.companyId}, function (response) {
                    $scope.servicePeriods = response.entries;
                }, function (error) {
                    notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
                });
            };

            $scope.init = function () {
                if ($scope.editMode === false) {
                    Locations.getDefault(function (response) {
                        $scope.location = response;
                        $scope.location.companyId = selectedCompanyId;
                        $scope.getActiveMealPlans();
                    });
                } else {
                    $scope.location = angular.copy(location);
                    $scope.getActiveMealPlans();
                }

                Settings.getTenderTypes(function (response) {
                    $scope.tenderTypes = response.entries;
                }, function (error) {
                    notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
                });
            };

            $scope.init();

        }])
    .controller('AddMenuItemCtrl', ['$scope', '$modalInstance', '$modal', 'Menu', 'isInventoryItem', 'inventoryItems', 'location', 'servicePeriodId', 'taxRates', '$translate', 'notificationTranslationHelper', 'ErrorUtil', 'EnvConfig', 'Utils', 'NgGridSortService', 'NgGridTemplateService',
        function ($scope, $modalInstance, $modal, Menu, isInventoryItem, inventoryItems, location, servicePeriodId, taxRates, $translate, notificationTranslationHelper, ErrorUtil, EnvConfig, Utils, NgGridSortService, NgGridTemplateService) {
            $scope.taxRates = taxRates;
            $scope.inventoryItems = inventoryItems;
            $scope.location = location;
            $scope.isInventoryItem = isInventoryItem;
            $scope.servicePeriodId = servicePeriodId;

            $scope.newEntry = {
                menuOrderId: -1,
                itemName: undefined,
                buttonColor: undefined,
                inventoryId: -1,
                price: 0.00,
                taxRate: 0.00,
                mealPlanEligible: false,
            };

            $scope.addMenuItem = function (menuItem) {
                menuItem.locationId = $scope.location.locationId;

                // remove for now until there is a way to specify the service period to add to
                // menuItem.servicePeriodId = $scope.servicePeriodId;

                // This is old old, and the endpoint this is pointing to is getting
                // deprecated/removed. Commenting this out for now.
                /* Menu.addMenuItem({}, menuItem, function (response) {

                    menuItem.menuOrderId = -1;
                    menuItem.itemName = undefined;
                    menuItem.buttonColor = undefined;
                    menuItem.inventoryId = -1;
                    menuItem.price = 0.00;
                    menuItem.taxRate = 0.00;
                    menuItem.mealPlanEligible = false;
                    notificationTranslationHelper.notifyMessage('menu.successfully.add', null, false);
                }, function (error) {
                    notificationTranslationHelper.notifyError(error.data.error, null, false);
                }); */
            };
        }])
    .controller('ImportMenuCtrl', ['$scope', '$modalInstance', '$translate', 'FileUploader', 'notificationTranslationHelper', 'Utils', 'ErrorUtil',
        function ($scope, $modalInstance, $translate, FileUploader, notificationTranslationHelper, Utils, ErrorUtil) {
            var uploader = $scope.uploader = new FileUploader({
                url: '/freshideas/web/location/import',
                queueLimit: 1,
                queue: [],
                headers: {'Accept': 'application/json'}, // wont work unless we
                                                         // manually define this
                                                         // header
            });

            uploader.onWhenAddingFileFailed = function (item /* {File|FileLikeObject} */, filter, options) {
            };
            uploader.onAfterAddingFile = function (fileItem) {
            };
            uploader.onAfterAddingAll = function (addedFileItems) {
            };
            uploader.onBeforeUploadItem = function (item) {
            };
            uploader.onProgressItem = function (fileItem, progress) {
            };
            uploader.onProgressAll = function (progress) {
            };
            uploader.onSuccessItem = function (fileItem, response, status, headers) {
                notificationTranslationHelper.notifyMessage('menu.import.fileSuccessfullyUploaded');
            };
            uploader.onErrorItem = function (fileItem, response, status, headers) {
                notificationTranslationHelper.notifyError('menu.import.importFailed');
            };
            uploader.onCancelItem = function (fileItem, response, status, headers) {
            };
            uploader.onCompleteItem = function (fileItem, response, status, headers) {
            };
            uploader.onCompleteAll = function () {
                $modalInstance.close();
            };
        }]);

require('./location.edit.printers.js')(freshideasLocations);

export default freshideasLocations;

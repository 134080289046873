'use strict';

const FONT_SIZE = {
    SMALL: 1,
    MEDIUM: 2,
    LARGE: 3
};

const FEED_DIRECTION = {
    NORMAL: 0,
    INVERTED: 1
};

// List of all supported manufacturers along with their printers
const tcpIpManufacturers = [
    // Epson Printers
    {
        name: 'Epson',
        value: 'epson',
        printers: [
            {
                name: 'Any',
                value: 'any',
                emulation: 'escpos',
                portSettings: 'escpos',
                receiptSize: 3
            }
        ]
    },
    // Star Micronics Printers
    {
        name: 'Star Micronics',
        value: 'star',
        printers: [
            {
                name: 'mPOP',
                value: 'mpop',
                emulation: 'starprnt',
                portSettings: 'none',
                receiptSize: 2,
                enabled: true
            },
            {
                name: 'FVP10',
                value: 'fvp10',
                emulation: 'starline',
                portSettings: 'none',
                receiptSize: 2,
                enabled: true
            },
            {
                name: 'TSP100',
                value: 'tsp100',
                emulation: 'stargraphic',
                portSettings: 'none',
                receiptSize: 3,
                enabled: true
            },
            {
                name: 'TSP100IV',
                value: 'tsp100iv',
                emulation: 'star-prnt',
                portSettings: 'none',
                receiptSize: 3,
                enabled: true
            },
            {
                name: 'TSP650II',
                value: 'tsp650ii',
                emulation: 'starline',
                portSettings: 'none',
                receiptSize: 3,
                enabled: true
            },
            {
                name: 'TSP650II-WebPRNT',
                value: 'tsp650iiwp',
                emulation: 'webprint',
                portSettings: 'none',
                receiptSize: 3,
                // enabled: location.hostname == 'localhost'
                enabled: true
            },
            {
                name: 'TSP700II',
                value: 'tsp700ii',
                emulation: 'starline',
                portSettings: 'none',
                receiptSize: 2,
                enabled: true
            },
            {
                name: 'TSP800II',
                value: 'tsp800ii',
                emulation: 'starline',
                portSettings: 'none',
                receiptSize: 2,
                enabled: true
            },
            // Comment out printers with portSettings for now,
            // since we do not currently pass portSettings to native.
            // escpos printers have guaranteed portsettings 'escpos'
            // so supporting those is fine.
            // {
            //     name: 'SM-S210i',
            //     value: 'sm-s210i',
            //     emulation: 'escposmobile',
            //     portSettings: 'mini',
            //     receiptSize: 2
            // },
            // {
            //     name: 'SM-S220i',
            //     value: 'sm-s220i',
            //     emulation: 'escposmobile',
            //     portSettings: 'mini',
            //     receiptSize: 2
            // },
            // {
            //     name: 'SM-S230i',
            //     value: 'sm-s230i',
            //     emulation: 'escposmobile',
            //     portSettings: 'mini',
            //     receiptSize: 2
            // },
            // {
            //     name: 'SM-S300i',
            //     value: 'sm-s300i',
            //     emulation: 'escposmobile',
            //     portSettings: 'mini',
            //     receiptSize: 2
            // },
            // {
            //     name: 'SM-S400i',
            //     value: 'sm-s400i',
            //     emulation: 'escposmobile',
            //     portSettings: 'mini',
            //     receiptSize: 2
            // },
            {
                name: 'BSC10',
                value: 'bsc10',
                emulation: 'escpos',
                portSettings: 'escpos',
                receiptSize: 2,
                enabled: true
            },
            // {
            //     name: 'SM-L200',
            //     value: 'sm-l200',
            //     emulation: 'starprnt',
            //     portSettings: 'Portable',
            //     receiptSize: 2
            // },
            {
                name: 'SP700',
                value: 'sp700',
                emulation: 'stardotimpact',
                portSettings: 'none',
                receiptSize: 2,
                enabled: true
            }
        ]
    },
    {
        name: 'TSPL Label Printer',
        value: 'tsplprinter',
        receiptType: 'sticker',
        customAttributes: {
            tsplXOffset: 2,
            tsplYOffset: 2,
            tsplTextWrapChars: 9999, // 9999 -> pratically no wrap
            tsplFontSize: FONT_SIZE.SMALL,
            tsplDirection: FEED_DIRECTION.NORMAL,
        },
        printers: [
            {
                name: 'Any',
                value: 'any',
                emulation: 'tspl',
                portSettings: 'none',
                receiptSize: -1 // receiptSize does not apply for label printer!!
            }
        ]
    },
    {
        name: 'Tizen',
        value: 'tizen',
        printers: [
            {
                name: 'Iframe Proxy',
                value: 'iframeproxy',
                emulation: 'escpos-tizen',
                portSettings: 'none',
                receiptSize: 3
            },
            {
                name: 'ESCPOS Proxy',
                value: 'escpos',
                emulation: 'escpos-tizen-proxy',
                portSettings: 'none',
                receiptSize: 3
            }
        ]
    },
];

tcpIpManufacturers[1].printers = tcpIpManufacturers[1].printers.filter((e) => e.enabled);

const bluetoothManufacturers = [
    // Elo Printers
    {
        name: 'Elo',
        value: 'elo',
        printers: [
            // {
            //  name: 'Paypoint',
            //  value: 'paypoint',
            //  emulation: 'elo',
            //  portSettings: 'elo',
            //  receiptSize: 2
            // },
            {
                name: 'Paypoint Plus',
                value: 'paypointplus',
                emulation: 'stargraphic',
                portSettings: 'none',
                receiptSize: 2
            }
        ]
    },
    // Star Micronics Printers
    {
        name: 'Star Micronics',
        value: 'star',
        printers: [
            {
                name: 'mC-Print3',
                value: 'mcp31lb',
                emulation: 'starprnt',
                portSettings: 'none',
                receiptSize: 3
            },
            {
                name: 'mC-Print2',
                value: 'mcp21lb',
                emulation: 'starprnt',
                portSettings: 'none',
                receiptSize: 2
            },
            {
                name: 'mPOP',
                value: 'mpop',
                emulation: 'starprnt',
                portSettings: 'none',
                receiptSize: 2
            },
            {
                name: 'FVP10',
                value: 'fvp10',
                emulation: 'starline',
                portSettings: 'none',
                receiptSize: 2
            },
            {
                name: 'TSP100',
                value: 'tsp100',
                emulation: 'stargraphic',
                portSettings: 'none',
                receiptSize: 3
            },
            {
                name: 'TSP650II',
                value: 'tsp650ii',
                emulation: 'starline',
                portSettings: 'none',
                receiptSize: 3
            },
            {
                name: 'TSP700II',
                value: 'tsp700ii',
                emulation: 'starline',
                portSettings: 'none',
                receiptSize: 2
            },
            {
                name: 'TSP800II',
                value: 'tsp800ii',
                emulation: 'starline',
                portSettings: 'none',
                receiptSize: 2
            },
            // Comment out printers with portSettings for now,
            // since our current version attempts to connect without
            // portSettings on startup
            // {
            //  name: 'SM-S210i',
            //  value: 'sm-s210i',
            //  emulation: 'escposmobile',
            //  portSettings: 'mini',
            // },
            // {
            //  name: 'SM-S220i',
            //  value: 'sm-s220i',
            //  emulation: 'escposmobile',
            //  portSettings: 'mini',
            // },
            // {
            //  name: 'SM-S230i',
            //  value: 'sm-s230i',
            //  emulation: 'escposmobile',
            //  portSettings: 'mini',
            // },
            // {
            //  name: 'SM-S300i',
            //  value: 'sm-s300i',
            //  emulation: 'escposmobile',
            //  portSettings: 'mini',
            // },
            // {
            //  name: 'SM-S400i',
            //  value: 'sm-s400i',
            //  emulation: 'escposmobile',
            //  portSettings: 'mini',
            // },
            // {
            //  name: 'BSC10',
            //  value: 'bsc10',
            //  emulation: 'escpos',
            //  portSettings: 'escpos',
            // },
            // {
            //  name: 'SM-L200',
            //  value: 'sm-l200',
            //  emulation: 'starprnt',
            //  portSettings: 'Portable',
            // },
            {
                name: 'SP700',
                value: 'sp700',
                emulation: 'stardotimpact',
                portSettings: 'none',
                receiptSize: 2
            }
        ]
    }
];

const usbManufacturers = [
    // Gprinter
    {
        name: 'Gprinter',
        value: 'gprinter',
        printers: [
            {
                name: 'GP-L80180II',
                value: 'l80180ii',
                emulation: 'pc',
                portSettings: 'none',
                receiptSize: 3
            }
        ]
    },
    {
        name: 'HP',
        value: 'hpprinter',
        printers: [
            {
                name: 'H300-E8SD-HPN0',
                value: 'h300e8sdhpn0',
                emulation: 'pc',
                portSettings: 'none',
                receiptSize: 3
            }
        ]
    },
    // Epson Printers
    {
        name: 'Epson',
        value: 'epson',
        printers: [
            {
                name: 'TM-T88V',
                value: 'tmt88v',
                emulation: 'pc',
                portSettings: 'none',
                receiptSize: 3
            }
        ]
    },
    // Mint POS
    {
        name: 'POSBANK',
        value: 'posbank',
        printers: [
            {
                name: 'MINT',
                value: 'mint',
                emulation: 'pc',
                portSettings: 'none',
                receiptSize: 3,
                cashDrawerType: 'mint2'
            }
        ]
    },
];

const PDL_PORT = 9100; // well-know network printer port

const CONNECTION_TYPES = [
    {name: 'Bluetooth', value: 'bt'},
    {name: 'USB', value: 'usb'},
    {name: 'TCP/IP', value: 'tcp'},
];

module.exports = {
    PDL_PORT: PDL_PORT,
    FONT_SIZE: FONT_SIZE,
    FEED_DIRECTION: FEED_DIRECTION,
    CONNECTION_TYPES: CONNECTION_TYPES,
    tcpIpManufacturers: tcpIpManufacturers,
    bluetoothManufacturers: bluetoothManufacturers,
    usbManufacturers: usbManufacturers
};

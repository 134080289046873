'use strict';

/**
*** Commented By Akash Mehta on September 8 2020
*** This service is a pure javascript service, whose pupose is to hold all functions required to process a transaction and
*** which can be used by any client for without internally maintaining any states. We were dire need of such a service and
*** the quickCharge feature increased the need tenfold. This service now not only allows us to run transctions from any part of the POS
*** but also from other external services running transactions (like the nown calculation server) and also helps in maintaing a unified
*** and common tendering workflow.
*** NOTE: Please do not use this service directly on the Nown POS Frontend.  All requests should come through the angular service
***      `Transaction Service`.
***/

// Commented By Akash Mehta on September 8 2020
// Based on the above comments, we need to decide if the current name justifies the purpose of the said service.
// Based on the above comments, we need to decide if the current name justifies the purpose of the said service.
// Based on the above comments, we need to decide if the current name justifies the purpose of the said service.
// Based on the above comments, we need to decide if the current name justifies the purpose of the said service.
(function () {
    var TransactionTenderService = (function () {
        var TransactionTenderService = function () {};

        const SharedDataService = require('./pos.data-service.js');
        const FiitMealPlanCalculationService = require('./fiit.pos.meal-plan-calculations.js');
        const AppConstants = require('../common/freshideas/app-constants.js');
        const Decimal = require('decimal.js').default;
        const Pure = require('./pure.js');

        TransactionTenderService.prototype.AUTOCALCULATE = 'AUTOCALCULATE';
        TransactionTenderService.prototype.MODIFY = 'MODIFY';

        const UI_TENDER_TYPE = AppConstants.uiTenderTypeConstants;

        /** Commented By Akash Mehta on September 8 2020
        *** Context :
        *** Nown POS uses a concept of tender Settings/constants. These constants earlier were declared and maintained by the
        *** front-end controller. We have decoupled the settings from the frontend controller and introduced them in this service.
        ***
        *** Purpose:
        *** This function returns an object of tender settings used to display a list of available tenders on the UI. But introducing
        *** these settings in a service, allows us to use the same workflow for other services running transactions (like the nown calculation
        *** server). Based on the paramters passed in the function call, we set the configuration for the tender settings (especially
        *** the tender's availability).
        ***/
        var _getTenderSettings = function (appPayLabel, hasOtherTenderEnabled = false,
            hasGiftCardsEnabled = false, isOffline = false, isNownFiitIntegrationEnabled = false) {
            var tenderSettings = {
                appPayLabel: appPayLabel,
                tenderTypes: [{
                    value: UI_TENDER_TYPE.CASH,
                    name: 'smb.pos.tenderType.cash',
                    adjustment: 'cashAdjustment',
                    description: 'smb.pos.tenderType.cash',
                    isEnabled: true
                }, {
                    value: UI_TENDER_TYPE.OTHER,
                    name: 'smb.pos.tenderType.other',
                    adjustment: 'otherAdjustment',
                    description: 'smb.pos.tenderType.other',
                    isEnabled: hasOtherTenderEnabled
                }, {
                    value: UI_TENDER_TYPE.CARD,
                    name: 'smb.pos.tenderType.card',
                    adjustment: 'creditCardAdjustment', // TODO? what should this option point to?
                    description: 'smb.pos.tenderType.card',
                    isEnabled: true
                }, {
                    value: UI_TENDER_TYPE.GIFTCARD,
                    name: 'smb.pos.tenderType.giftCard',
                    adjustment: 'giftCardAdjustment',
                    description: 'smb.pos.tenderType.giftCard',
                    isEnabled: (hasGiftCardsEnabled && !isOffline)
                }, {
                    value: UI_TENDER_TYPE.MANUAL_MOBILE,
                    name: appPayLabel,
                    adjustment: 'otherAdjustment',
                    description: appPayLabel,
                    isEnabled: !isOffline
                }, {
                    value: UI_TENDER_TYPE.GATEWAY,
                    name: 'Meal Card', // 'smb.pos.tenderType.gateway',
                    adjustment: 'otherAdjustment',
                    description: 'Meal Card', // 'smb.pos.tenderType.gateway.description',
                    isEnabled: false
                }, {
                    value: UI_TENDER_TYPE.PAYATCOUNTER,
                    name: 'Pay At Counter',
                    adjustment: 'otherAdjustment',
                    description: 'Pay At Counter', // 'smb.pos.tenderType.gateway.description',
                    isEnabled: false
                }],
                mobileTenderTypes: [{
                    value: UI_TENDER_TYPE.MOBILE,
                    name: 'smb.pos.tenderType.mobile',
                    description: 'smb.pos.tenderType.mobile.description',
                }],
                adjustmentRounding: [
                    1, // exact change
                    5, // nearest nickel
                    100, // nearest loonie
                    500, // nearest five-dollar
                    1000, // nearest ten-dollar
                    2000, // nearest twenty-dollar
                    5000, // nearest fifty-dollar
                    10000 // nearest one-hundred-dollar
                ]
            };

            if (isNownFiitIntegrationEnabled) {
                tenderSettings.tenderTypes[5].isEnabled = true;
            }

            return tenderSettings;
        };

        /** Commented By Akash Mehta on September 8 2020
        *** Context :
        *** Nown POS uses a concept of rounding all the adjustments on the tendering screen. The logic requires the adjustment rounding
        *** constant declared in the tenderSettings above. We need to rewrite/refactor/remove this behaviour as I suspect this
        *** we now use some other workflow for rounding.
        ***
        *** Purpose:
        *** This function returns the adjustment roundings declared in the tender settings.
        ***/
        TransactionTenderService.prototype.getAdjustmentRounding = function () {
            var tenderSettings = _getTenderSettings();
            return tenderSettings.adjustmentRounding;
        };

        /** Commented By Akash Mehta on September 8 2020
        *** This function returns all the available tender types from the tenderSettings object declared above based on the input paramets.
        ***/
        TransactionTenderService.prototype.getAvailableTenderTypes = function (getMobilePaymentTenders = false, appPayLabel, hasOtherTenderEnabled,
            hasGiftCardsEnabled, isOffline, isNownFiitIntegrationEnabled) {

            var tenderSettings = _getTenderSettings(appPayLabel, hasOtherTenderEnabled, hasGiftCardsEnabled, isOffline,
                isNownFiitIntegrationEnabled);

            if (getMobilePaymentTenders) {
                return tenderSettings.mobileTenderTypes;
            } else {
                return tenderSettings.tenderTypes;
            }
        };

        /** Commented By Akash Mehta on September 8 2020
        *** This function returns the requested tender from a list of all the available tender types from the tenderSettings object declared
        *** above and generated based on the input paramets. Also, if the request tender needs to be selected (concept used by the Nown Pos Frontend)
        *** the we call the function  `selectTender` in this file.
        ***/
        TransactionTenderService.prototype.getTenderByUiTenderType = function (uiTenderType, getMobilePaymentTenders = false, toSelect = false) {
            if (uiTenderType) {
                var allAvailableTenders = this.getAvailableTenderTypes(getMobilePaymentTenders);
                var availableTender = allAvailableTenders.find((tender) => tender.value == uiTenderType);
                if (availableTender) {
                    if (toSelect) {
                        return this.selectTender(availableTender);
                    } else {
                        return availableTender;
                    }
                }
            }

            return null;
        };

        /** Commented By Akash Mehta on September 8 2020
        *** This function returns the requested tender from a list of all the available tender types from the tenderSettings object declared
        *** above and generated based on the input paramets. Also, if the request tender needs to be selected (concept used by the Nown Pos Frontend)
        *** the we call the function  `selectTender` in this file.
        ***/
        TransactionTenderService.prototype.selectTender = function (tenderObj) {
            if (!tenderObj || !tenderObj.value) {
                return null;
            }

            return {
                type: tenderObj.value,
                field: tenderObj.adjustment,
                amount: 0,
                uuid: Pure.generateUuid()
            };
        };

        /** Commented By Akash Mehta on September 8 2020
        *** This function generates a payload to be sent to the Nown Backend for submitting a transaction. We take in a lot of paramters
        *** some of which are explained below:
        *** 1. transactionObject - The transaction object returned by the NownCalculationService. It represents the overall state of the
        ***                        cart and is a partial respresentation of the actual payload to be sent.
        *** 2. allTenders - A list of all the selected Tender objects where each object defines its type and the amonut being paid against
        ***                 each tender.
        *** 5. optional object - An object consisting of all optional parameters stated below :
        *** a. nestCartItems - This is a nested array of items that were added to a cart. The root level items will be the actual
        ***                    items (no categories/modifiers) added to the cart. Each root item may contain children representing
        ***                    the modifiers selected. (This is used to generate item paths on the backend. We should rewrite this workflow).
        ***                    It is also used to determine the meal plans & dcbs used against this particular transaction (Please note that
        ***                    the said meal plans are different from Fiit meal plan system. These meal plans are loyalty plans used by merchants
        ***                    like Fahrenheit).
        *** b. guestLabel - A label consisting the name of the patron/guest. This may not be present always.
        *** c. originalTransactionId - This is used in the case of exchanges where we are issuing a new transaction against an old transaction
        ***                            and its refund. This may not be present always.
        *** d. ticketPreGeneratedReceiptCounter - An object indicating if the transaction was already pre printed and whether it has already been assigned with
        ***                     a receipt counter. This may not be present always
        *** e. buzzerCode - A field indicating the buzzer code associated with the said transaction. This may not be present always.
        *** f. fiitTransactionDetails - An object containing all the required details for a campus transaction. Details include the fiit
        ***                             patron id, the quick charge session id and the menu period id. This may not be present always.
        *** g. isSplitTransaction - A boolean value indicating whether the said transaction is a split transaction or not. Its set to false
        ***                         by default, unless mentioned otherwise.
        *** h. isTransactionAlreadyInitialized - A boolean value indicating whether the said transaction has already been intializard or not
        ***                                       This is useful in the case of a split transaction. Its set to false by default, unless mentioned otherwise.
        ***/
        TransactionTenderService.prototype.generateNownTransactionPayload = function (transactionObject, allTenders,
            {nestedCartItems, guestLabel, originalTransactionId, ticketPreGeneratedReceiptCounter, buzzerCode,
                fiitTransactionDetails, isSplitTransaction = false, isTransactionAlreadyInitialized = false,
                menuId, adjustments} = {}) {

            if (!transactionObject || !Array.isArray(allTenders)) {
                throw new Error('Invalid params received for generating Transaction Payload');
            }

            // Commented By Akash Mehta
            // Is this the right place to add this code ?
            // Or do we want everyone to populate the uuid
            // unique transaction ID for each transaction to ensure tenderTransaction API call
            // is idempotent
            if (!transactionObject.transactionUuid) {
                transactionObject.transactionUuid = Pure.generateUuid();
                transactionObject._transactionStartTime = new Date();
            }

            var transactionObjectCopy = JSON.parse(JSON.stringify(transactionObject));
            var transactionTender;
            var tendersToAdd = [];

            for (var tenderToAdd of allTenders) {
                if (tenderToAdd.isProcessed) {
                    continue;
                }

                var transactionType;
                switch (tenderToAdd.type) {
                    case 'cash':
                        transactionType = 'CASH';
                        break;
                    case 'card':
                        transactionType = 'CREDIT';
                        break;
                    case 'other':
                        transactionType = 'OTHER';
                        break;
                    case 'giftcard':
                        transactionType = 'GIFTCARD';
                        break;
                    case 'gateway':
                        transactionType = 'OTHER';
                        break;
                }

                var amountMeals = tenderToAdd.meal || 0;

                var decimalAmount = new Decimal(tenderToAdd.amount || 0);
                var decimalAmountCents = decimalAmount.times(new Decimal(100));

                // Important - this ensures the actual amount of cash that POS increases
                if (transactionType === 'CASH') {
                    var remainingBalance = transactionObjectCopy.remainingBalance;
                    if (remainingBalance < 0) { // has change
                        var decimalChangeCents = new Decimal(-remainingBalance).times(new Decimal(100));
                        decimalAmountCents = decimalAmountCents.minus(decimalChangeCents);
                    }
                }

                var amountCents = decimalAmountCents.toNearest(SharedDataService.baseCent).toNumber();

                var decimalTipAmount = new Decimal(tenderToAdd.tipAmount || 0);
                var decimalTipAmountCents = decimalTipAmount.times(new Decimal(100));
                var tipAmountCents = decimalTipAmountCents.toNearest(SharedDataService.baseCent).toNumber();

                transactionTender = {
                    transactionType: transactionType,
                    order: allTenders.indexOf(tenderToAdd),
                    amountCents: amountCents,
                    amountMeals: amountMeals,
                    tipAmountCents: tipAmountCents,
                    terminalResponse: tenderToAdd.terminalResponse,
                    creditCardName: tenderToAdd.creditCardName,
                    giftCardId: tenderToAdd.giftCardId,
                    externalGiftCardId: tenderToAdd.externalGiftCardId,
                    manuallyRecorded: tenderToAdd.manuallyRecorded
                };

                transactionTender.patronId = tenderToAdd.patronId;
                transactionTender.patronMealPlanId = tenderToAdd.patronMealPlanId;
                transactionTender.mealPlanId = tenderToAdd.mealPlanId;

                if (tenderToAdd.transactionTenderDetail) {
                    transactionTender.transactionTenderDetail = tenderToAdd.transactionTenderDetail;
                }

                if (tenderToAdd.fiitTenders && tenderToAdd.fiitTenders.length) {
                    transactionTender.fiitTenders = tenderToAdd.fiitTenders;
                }

                tendersToAdd.push(transactionTender);
            }

            if (nestedCartItems) {
                transactionObjectCopy.menuItems = nestedCartItems;
                /**
                 * Precalculated tenders are specifically created for items that
                 * have been paid for by either an item specific meal plan or DCB
                 */
                transactionObjectCopy.menuItems.forEach((item) => {
                    if (item.preCalculatedTender) {
                        item.preCalculatedTender.amountMeals = item.quantity;
                        tendersToAdd.push(item.preCalculatedTender);
                        // Delete preCalculatedTender so that we only deduct it once
                        // on the backend
                        delete item.preCalculatedTender;
                    }
                });
            }

            /** Commented By Akash Mehta on August 19 2020
            *** Cart items is an array. It should not have a key associated
            *** with it. JAVASCRIPT allows this but this is bad practice.
            *** This was added by my in 2018. Will try to remove this asap.
            ***/
            if (adjustments && adjustments.labelledDiscounts) {
                transactionObjectCopy.labelledDiscounts = adjustments.labelledDiscounts;
            }

            transactionObjectCopy.originalTransactionId = originalTransactionId;

            if (ticketPreGeneratedReceiptCounter && ticketPreGeneratedReceiptCounter.receiptCounter) {
                transactionObjectCopy.receiptCounter = ticketPreGeneratedReceiptCounter.receiptCounter;
            }

            transactionObjectCopy.buzzerCode = buzzerCode;

            /** Commented By Akash Mehta on August 19 2020
            *** Not the best place to do this. Please find a better place.
            ***/
            if (fiitTransactionDetails) {
                transactionObjectCopy.fiitPatronId = fiitTransactionDetails.fiitPatronId;
                transactionObjectCopy.quickChargeSessionId = fiitTransactionDetails.quickChargeSessionId;
                transactionObjectCopy.menuPeriodId = fiitTransactionDetails.menuPeriodId;

            }

            transactionObjectCopy.menuId = menuId;

            if (isSplitTransaction && isTransactionAlreadyInitialized) {
                // Commented By Akash Mehta on August 20 2020
                // This is not the best way to derive the latest tender
                // PLEASE FIND A BETTER APPROACH
                transactionObjectCopy.tender = transactionTender;
            } else {
                // Commented By Akash Mehta on AUg 20 2020
                // Not sure what does this old comment below means. Is it that someone forgot to remove it ?
                /** ********************************************************************************************/
                // TODO: Handle case where there is already more than 1 tender when transation is initialized
                /** ********************************************************************************************/
                transactionObjectCopy.tenders = tendersToAdd;
                transactionObjectCopy.guestLabel = guestLabel;
            }

            return transactionObjectCopy;
        };

        /** Commented By Akash Mehta on September 8 2020
        *** This function runs a mock FIIT transaction. We use this function to decide how much meal plan balances of a patron
        *** can be used for this transaction. We take in a lot of paramters some of which are explained below:
        *** 1. transactionObject - The transaction object returned by the NownCalculationService. It represents the overall state of the
        ***                        cart and is a partial respresentation of the actual payload to be sent.
        *** 2. patron - The fiit patron associated with the said transaction. This field may not exist as always.
        *** 3. useMealEquivalency - A boolean value indicating whether we can use meal equivalency or not.
        *** 4. adjustments - An adjustments object maintained by the controller/component calling this function
        *** 5. useFiitPriority - A boolean value indicating whether we should be using the fiit priority or not.
        ***
        *** The steps involded in the process are as follows :
        *** 1. We gather all the input transaction obj, adjustsments and all available meal plans.
        *** 2. We run the calculions using the function calculateBalances in the FiitMealPlanCalculationService.
        *** 3. We repackage the rawCalculationResponse in a format that was already being used before Nown - FIIT rewrite (We
        ***    can look into removing this step).
        *** 4. If there is an error in the repackaged response, we throw an error else we return a response.
        ***/
        TransactionTenderService.prototype.runFiitTransactionMock = function (transactionObj, patron, useMealEquivalency,
            adjustments, useFiitPriority) {
            var transactionObjCopy = JSON.parse(JSON.stringify(transactionObj));

            var fiitAdjustments = FiitMealPlanCalculationService.processAdjustments();
            fiitAdjustments.mealEquivalencyAdjustment = !!useMealEquivalency;
            fiitAdjustments.dollarDiscount = adjustments.dollarDiscount || 0;
            fiitAdjustments.giftCardAdjustment = adjustments.giftCardAdjustment || 0;
            fiitAdjustments.creditCardAdjustment = adjustments.creditCardAdjustment || 0;

            var availableMealPlans = FiitMealPlanCalculationService.filterFiitPatronMealPlans(patron.fiitMpsAccount.mealPlans, useFiitPriority);

            var fiitResult = FiitMealPlanCalculationService.calculateBalances(transactionObjCopy.receiptItems,
            availableMealPlans, fiitAdjustments, patron.fiitMpsAccount.fiitLocationId, patron.fiitMpsAccount.applicableMealEquivalency, useFiitPriority);

            var minimizedResponse = FiitMealPlanCalculationService.repackageRawCalculationResponse(fiitResult);

            if (!minimizedResponse.error) {
                return Promise.resolve(minimizedResponse);
            } else {
                return Promise.reject(minimizedResponse);
            }
        };

        /** Commented By Akash Mehta on September 8 2020
        *** This function proceseses the response returned by the mock FIIT transaction. We use this function to generatre a fiit
        *** transaction object and update the Nown selected tender (a concept from the Nown UI).
        ***/
        TransactionTenderService.prototype.generateFiitTransactionPayload = function (nownTransactionObj, optionalParams) {
            return FiitMealPlanCalculationService.generateFiitTransactionPayloadAndUpdateNownTender(nownTransactionObj, optionalParams);
        };

        /** Commented By Akash Mehta on September 8 2020
        *** This function updates the nown cart based on the response from Fiit Transaction Mock. We update the Nown card items
        *** based on the response returned from the mock transaction. This inturn affects the tax applied on the cart.
        *** We also populate the fiit meal plans used in the adjustments object. This adjustments object is later used to update the
        *** nown transaction object submitted to the nown backend.
        ***/
        TransactionTenderService.prototype.updateNownCartFromFiitTransactionMock = function (processedFlatCartItems, mockResponse, nownAdjustments) {
            FiitMealPlanCalculationService.updateNownItemReceiptFromFiitResponse(processedFlatCartItems, mockResponse);
            FiitMealPlanCalculationService.updateNownAdjustmentsAsPerFiitCalculationResponse(mockResponse, nownAdjustments);

            return;
        };

        /** Commented By Akash Mehta on September 8 2020
        *** We use this concept of posData which is and object containing info about the transaction like the current tender,
        *** all tenders selected for this transaction, if this transaction is a split transaction or not, transactionId (if the
        *** transaction is already initalized in the case of split transaction), etc.
        *** This function generates the required object so it can be called from any part of the POS.
        *** We need to rewrite/clearly define this concept of posData when rewriting transaction tender workflow.
        ***/
        TransactionTenderService.prototype.generateTenderData = function () {
            return {
                // whether the transaction will be a split-tender transaction
                isSplit: false,
                canToggleSplit: true,
                // credit card tender triggers split-tender by default to support partial approval - this flag tracks whether a split-tender
                // is turned on due to that, so that when the card payment fails, the split tender will be reverted correctly
                toRevertSplitByCard: false,
                currentTender: {},
                allTenders: [],
                transactionId: undefined, // to store any transaction that is already created
            };
        };

        /** Commented By Akash Mehta on September 8 2020
        *** We use this concept of posData which is and object containing info about the transaction like the current tender,
        *** all tenders selected for this transaction, if this transaction is a split transaction or not, transactionId (if the
        *** transaction is already initalized in the case of split transaction), etc.
        *** This function generates the required object so it can be called from any part of the POS.
        *** We need to rewrite/clearly define this concept of posData when rewriting transaction tender workflow.
        ***/
        TransactionTenderService.prototype.addSelectedTender = function (tenderData, selectedTender, adjustments) {
            var selectedTenderCopy = JSON.parse(JSON.stringify(selectedTender));

            tenderData.currentTender = selectedTenderCopy;
            tenderData.allTenders.push(selectedTenderCopy);

            var adjustmentField = tenderData.currentTender.field;
            if (adjustmentField) {
                var currentAdjustmentAmout = new Decimal(tenderData.currentTender.amount);
                var originalAdjustmentAmount = new Decimal(adjustments[adjustmentField]);
                var newAdjustmentAmount = originalAdjustmentAmount.plus(currentAdjustmentAmout);
                adjustments[adjustmentField] = newAdjustmentAmount.toNearest(SharedDataService.baseDollar).toNumber();
            }

            return;
        };

        return TransactionTenderService;
    })();

    if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
        module.exports = new TransactionTenderService();
    } else {
        window.TransactionTenderService = new TransactionTenderService();
    }
})();

'use strict';

const paramList = [
    '$scope',
    '$translate',
    'PrintType',
    'CurrentSession',
    'SmbPosService',
    'PrintReceiptType',
    'CompanyAttributesService',
    'CommonOfflineCache',
    'Platform',
    'PosAlertService',
    'transaction',
    'isFetchTransaction',
    'preorderDetails',
];
const SmbPosTransactionRePrintOptionsCtrl = function (
    $scope,
    $translate,
    PrintType,
    CurrentSession,
    SmbPosService,
    PrintReceiptType,
    CompanyAttributesService,
    CommonOfflineCache,
    Platform,
    PosAlertService,
    transaction,
    isFetchTransaction,
    preorderDetails,
) {
    // BEGIN: DI
    this.ngScope = $scope;
    this.ngTranslate = $translate;
    this.SmbPosService = SmbPosService;
    this.PrintReceiptType = PrintReceiptType;
    this.CompanyAttributesService = CompanyAttributesService;
    this.PosAlertService = PosAlertService;

    this.transaction = transaction;
    this.isFetchTransaction = isFetchTransaction;
    this.preorderDetails = preorderDetails;
    // END: DI

    // BEGIN: scope props
    this.ngScope.PrintType = PrintType;
    this.ngScope.canPrintGiftReceipt = CurrentSession.getCompany().attributes['receipt__has_gift_receipt'] === 'true';
    this.ngScope.isIosWebkit = Platform.isIosWebkit();
    this.ngScope.isElectron = Platform.isElectron();
    this.ngScope.patron = transaction.patron;
    this.ngScope.isPreorder = !!(this.preorderDetails && this.preorderDetails.isPreorder);
    // END: scope props

    // BEGIN: instance props & methods
    this.showKitchenReceipts = false;
    // END: instance props & methods

    // BEGIN: INIT
    // ** This is needed is to avoid angular watch constantly fetching printers causing memeory leak.
    // Always check for data binding and where data is being fetched from
    const stationPrintersCallback = function (stationPrintersMap) {
        const stationPrinters = stationPrintersMap.get(this.SmbPosService.shift.posStation.id);
        if (stationPrinters && stationPrinters.length) {
            const kitchenPrinters = _.find(stationPrinters, function (posPrinter) {
                return posPrinter.secondaryReceiptPrinter === true;
            });

            this.showKitchenReceipts = kitchenPrinters && !this.CompanyAttributesService.hasRetailMenu();
        }
    };
    CommonOfflineCache.getStationGroupedPrinters().then(stationPrintersCallback.bind(this)); // BEGIN: INIT
    // END: INIT
};

SmbPosTransactionRePrintOptionsCtrl.prototype.printReceipt = async function (printType) {
    const namedParams = {
        transaction: this.transaction.detail,
        tenderType: this.PrintReceiptType.TRANSACTION,
        isDuplicate: true,
        printType: printType
    };

    this.SmbPosService.reprintReceipt(namedParams, this.isFetchTransaction);
};

SmbPosTransactionRePrintOptionsCtrl.prototype.printGiftReceiptV2 = async function () {
    const namedParams = {
        transaction: this.transaction,
        isDuplicate: true
    };

    this.SmbPosService.reprintGiftReceipt(namedParams, this.isFetchTransaction);
};

SmbPosTransactionRePrintOptionsCtrl.prototype.printKitchenSheets = async function () {
    let transactionDetail = this.transaction.detail;
    if (this.isFetchTransaction) {
        transactionDetail = await this.SmbPosService.getTransactionResult(
            this.transaction.transactionId, true);
    }

    if (this.ngScope.isPreorder && Object.keys(this.preorderDetails.preorder).length) {
        this.SmbPosService.printPreorderKitchenReceipts(this.preorderDetails.preorder, {buzzerCode: null, isPreorder: true});
    } else {
        this.SmbPosService.printKitchenReceipts(
            transactionDetail.receipt,
            transactionDetail
        );
    }
};

SmbPosTransactionRePrintOptionsCtrl.prototype.emailReceipt = async function () {
    const _self = this;

    try {
        if (!_self.transaction.patron) {
            // linkPatron will update the transaction.patron object on success
            await _self.SmbPosService.linkPatron(_self.transaction);
        }

        _self.ngScope.sendingEmail = true;
        await _self.SmbPosService.emailReceipt({transactionUuid: _self.transaction.transactionUuid});
        _self.ngScope.sendingEmail = false;
        _self.ngScope.emailSent = true;
        _self.ngScope.$apply();
    } catch (error) {
        _self.ngScope.sendingEmail = false;
        if (_self.transaction.patron) {
            // alert only if patron is linked/exists, but email failed to send
            let message = _self.ngTranslate.instant('smb.pos.signupForm.error');
            if (error.data && error.data.error) {
                message = error.data.error;
            }

            _self.PosAlertService.showAlertByName('general-error', {
                message
            });
        }
        _self.ngScope.$apply();
    }
};

SmbPosTransactionRePrintOptionsCtrl.prototype.printPreorderReceipt = function () {
    if (this.ngScope.isPreorder && Object.keys(this.preorderDetails.preorder).length > 0) {
        this.SmbPosService.printPreorderReceipt(this.preorderDetails.preorder);
    }
};

SmbPosTransactionRePrintOptionsCtrl.$inject = paramList;
module.exports = SmbPosTransactionRePrintOptionsCtrl;

'use strict';

const angular = require('angular');

const offlineTransaction = require('../../common/resources/offline-transaction.js').default;
const freshideasResourcesLocations = require('../../common/resources/location.js').default;
const offlineCache = require('../../common/resources/offline-cache.js').default;
const lucovaResources = require('../../common/resources/lucova.js').default;
const gridster = require('angular-gridster');

let freshideasPosSmb = angular.module('freshideas.smb-pos', [
    lucovaResources.name,
    freshideasResourcesLocations.name,
    'mgo-angular-wizard',
    gridster.name,
    offlineCache.name,
    offlineTransaction.name,
    'ui.router',
    'pascalprecht.translate'
]);
freshideasPosSmb
    .config([
        '$stateProvider',
        function ($stateProvider) {
            $stateProvider.state('freshideas.smb-pos', {
                rootUrl: 'smb-pos',
                url: '/smb-pos',
                templateUrl: 'pos/smb/templates/pos.tpl.html',
                controller: 'SmbPosCtrl'
            });
        }
    ]);


require('./pos-service.js')(freshideasPosSmb);

require('./pos.js')(freshideasPosSmb);
require('./pos.start.js')(freshideasPosSmb);
require('./pos.start.patrons.js')(freshideasPosSmb);
require('./pos.start.messaging.js')(freshideasPosSmb);
require('./pos.start.mobile.order.js')(freshideasPosSmb);
require('./pos.start.status.js')(freshideasPosSmb);
require('../quickcharge.ctrl.js')(freshideasPosSmb);
require('./pos.event.attendees.js')(freshideasPosSmb);

require('./pos.loyalty.js')(freshideasPosSmb);
require('./pos.manual.checkin.js')(freshideasPosSmb);
require('./pos.upgrade.js')(freshideasPosSmb);
require('./pos.upc.lookup.js')(freshideasPosSmb);
require('./pos.end.shift.js')(freshideasPosSmb);
require('./pos.endshift.terminal.printout.js')(freshideasPosSmb);
require('./pos.ending.balance.modal.js')(freshideasPosSmb);
require('./pos.endshift.settle.transactions.js')(freshideasPosSmb);
require('./pos.endshift.tips.payout.js')(freshideasPosSmb);
require('../../common/controllers/settleCardTerminalModal.ctrl.js')(freshideasPosSmb);
require('../../common/controllers/mobileOrderPatronModal.ctrl.js')(freshideasPosSmb);
require('../../common/controllers/itemAvailabilityModal.ctrl.js')(freshideasPosSmb);
// require('../../common/controllers/driverPreviewModal.ctrl.js')(freshideasPosSmb);
require('../../common/controllers/preorderDashboardHelp.ctrl.js')(freshideasPosSmb);
require('../../common/controllers/idCheckModal.ctrl.js')(freshideasPosSmb);
require('./pos.time-tracking.js')(freshideasPosSmb);
require('../../common/controllers/genericScanModal.js')(freshideasPosSmb);

require('./pos.order.js')(freshideasPosSmb);
require('./pos.order.mirror.js')(freshideasPosSmb);
require('./pos.order.menu.js')(freshideasPosSmb);
require('./pos.order.receipt.js')(freshideasPosSmb);
require('./pos.order.discount.js')(freshideasPosSmb);
require('./pos.order.tax.popup.js')(freshideasPosSmb);

require('./pos.tender.js')(freshideasPosSmb);
require('./pos.tender.incomplete.js')(freshideasPosSmb);
require('./pos.tender.lucova.js')(freshideasPosSmb);
require('./pos.tender.alphapay.js')(freshideasPosSmb);
require('./pos.tender.terminal-recovery.js')(freshideasPosSmb);
require('./pos.receipt.js')(freshideasPosSmb);

require('./pos.giftcard.activation.js')(freshideasPosSmb);

require('./pos.cashier.options.js')(freshideasPosSmb);
require('./pos.save.mealcard.js')(freshideasPosSmb);

require('./pos.preorder.dashboard.ctrl.js')(freshideasPosSmb);
require('./kiosk.language.selection.js')(freshideasPosSmb);

require('../refund/pos.transaction.refund.start.js')(freshideasPosSmb);
require('../refund/pos.transaction.refund.long.js')(freshideasPosSmb);
require('../refund/pos.transaction.refund.processing.js')(freshideasPosSmb);
require('../refund/pos.transaction.exchange.start.js')(freshideasPosSmb);
require('../refund/pos.transaction.refund.card.retry.js')(freshideasPosSmb);

export default freshideasPosSmb;



'use strict';

export function eventListenerService (freshideas) {

    freshideas.factory('EventListener', [
        '$rootScope',
        '$log',
        'SmbPosService',
        function (
            $rootScope,
            $log,
            SmbPosService
        ) {
            var service = {};
            var removeMobileOrderPrintListener = undefined;

            service.listenForNewMobileOrder = function () {
                if (removeMobileOrderPrintListener) {
                    removeMobileOrderPrintListener();
                    removeMobileOrderPrintListener = undefined;
                }

                if (!removeMobileOrderPrintListener) {
                    // acknowledges order (if status = print_pending), prints receipt and sends to KDS.
                    removeMobileOrderPrintListener = $rootScope.$on('pos.mobileOrder.print.receipt', function (event, preorder, successCallback, errorCallback) {
                        if (preorder.status === 'print_pending') {
                            $log.info('New Mobile Order - #' + preorder.short_id + ' - received');
                        }

                        SmbPosService.printPreorderReceipt(preorder).then(function (receiptDetails) {
                            // successCallback could be undefined
                            if (successCallback) {
                                successCallback(receiptDetails);
                            }

                            SmbPosService.printPreorderKitchenReceipts(receiptDetails.receiptItems, receiptDetails.data, {buzzerCode: null, isPreorder: true});
                        }).catch(function (err) {
                            if (errorCallback) {
                                errorCallback(err);
                            }
                        });
                    });
                }
            };

            return service;
        }]);
}

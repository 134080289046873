'use strict';

const angular = require('angular');

const lucovaResources = require('../common/resources/lucova.js').default;
const freshideasResourcesLocations = require('../common/resources/location.js').default;
const gridster = require('angular-gridster');
const offlineCache = require('../common/resources/offline-cache.js').default;
const offlineTransaction = require('../common/resources/offline-transaction.js').default;
const SharedFunctionService = require('../external/pos.meal-plan-calculations.js');
const FiitMealPlanCalculationService = require('../external/fiit.pos.meal-plan-calculations.js');
const TransactionTenderService = require('../external/transaction-tender-service.js');
const DataService = require('../external/pos.data-service.js');
const Controller = new (require('../external/pos.controller.js'))();
const Decimal = require('decimal.js').default;

const SmbPosTransactionRePrintOptionsCtrl = require('./refund/pos.transaction.print.options.js');

const posModule = angular.module('freshideas.pos', [
    lucovaResources.name,
    freshideasResourcesLocations.name,
    'mgo-angular-wizard',
    gridster.name,
    offlineCache.name,
    offlineTransaction.name
]);

posModule.constant('SORT_RECEIPT_ITEMS_TYPE', {
    QUANTITY: 'quantity',
    ITEM: 'name',
    COST: 'price',
    TAX: 'taxAmount',
    TOTAL: 'total',
    NONE: ''
}).service('SharedFunctionService', function () {
    return SharedFunctionService;
}).service('FiitMealPlanCalculationService', function () {
    return FiitMealPlanCalculationService;
}).service('TransactionTenderService', function () {
    return TransactionTenderService;
}).service('SharedDataService', function () {
    return DataService;
}).controller('PosCtrl', [
    '$scope',
    '$state',
    '$modal',
    '$stateParams',
    'SharedFunctionService',
    'CashierShift',
    'Locations',
    'Settings',
    'Security',
    'EnvConfig',
    'SmbPosService',
    'PosAlertService',
    'SORT_RECEIPT_ITEMS_TYPE',
    'Pure',
    'CommonOfflineCache',
    'ErrorUtil',
    'PosBuilderService',
    'SecondaryDisplay',
    '$filter',
    '$translate',
    'CurrentSession',
    '$log',
    'PosStatusService',
    'SharedDataService',
    'KioskService',
    'GatewayFiit',
    '$q',
    '$rootScope',
    'GuestLabelService',
    'CartBuilderService',
    '$timeout',
    'SolinkService',
    function (
        $scope,
        $state,
        $modal,
        $stateParams,
        SharedFunctionService,
        CashierShift,
        Locations,
        Settings,
        Security,
        EnvConfig,
        SmbPosService,
        PosAlertService,
        SORT_RECEIPT_ITEMS_TYPE,
        Pure,
        CommonOfflineCache,
        ErrorUtil,
        PosBuilderService,
        SecondaryDisplay,
        $filter,
        $translate,
        CurrentSession,
        $log,
        PosStatusService,
        SharedDataService,
        KioskService,
        GatewayFiit,
        $q,
        $rootScope,
        GuestLabelService,
        CartBuilderService,
        $timeout,
        SolinkService
    ) {
        const isPGCDiscountable = Security.getUser().company.attributes.physical_giftcard__discountable === 'true';

        if (KioskService.isKiosk()) {
            $scope.isKiosk = true;
            $scope.selectedCategoryChanged = function (category) {
                $scope.$broadcast('kiosk::change-selected-category', category);
            };
        }
        $scope.suspendEnabled = Security.getUser().company.attributes.suspend_enabled === 'true';

        $scope.$watch(function () {
            return Security.getUser().company.attributes.kds_enabled;
        }, function (value) {
            $scope.suspendEnabled = Security.getUser().company.attributes.suspend_enabled === 'true';
        });

        $scope.SORT_RECEIPT_ITEMS_TYPE = SORT_RECEIPT_ITEMS_TYPE;
        $scope.patron = angular.isDefined($stateParams) ? $stateParams.studentId : undefined;
        $scope.verified = angular.isDefined($stateParams) ? $stateParams.verified : undefined;
        $scope.lucovaUser = angular.isDefined($stateParams) ? $stateParams.lucovaUser : null;
        var isOffline = angular.isDefined($stateParams) ? $stateParams.isOffline : false;
        $scope.shiftLocationId = angular.isDefined($stateParams) ? $stateParams.shiftLocationId : undefined;

        $scope.guestTransaction = false;
        $scope.sortReceiptItemsType = SORT_RECEIPT_ITEMS_TYPE.NONE;
        $scope.reverseReceiptItems = true;
        var envConfig = EnvConfig;
        $scope.buzzerCode = null;

        $scope.currentOrderBalance = {
            'totalMeals': 0,
            'subTotalAmount': 0,
            'totalAmount': 0,
            'taxAmount': 0,
            'totalDiscount': 0,
            'deliveryAmount': 0,
            'mealError': false
        };

        var campaignLargerDiscount = {};
        $scope.sortedPercentCampaignDiscounts = null;
        $scope.sortedDollarCampaignDiscounts = null;
        $scope.manualCouponDiscount = null;
        $scope.manualCouponCode = '';
        $scope.tmpCampaignDollarDiscountForView = null;
        $scope.tmpCampaignPercentDiscountForView = null;

        $scope.currentDiscountIndex = null;
        $scope.currentDiscountType = null;

        $scope.adjustments = {
            dcbAdjustment: 0,
            cashAdjustment: 0,
            creditCardAdjustment: 0,
            debitCardAdjustment: 0,
            otherAdjustment: 0,
            dollarDiscount: $scope.sortedDollarCampaignDiscounts && $scope.sortedDollarCampaignDiscounts[0] ? $scope.sortedDollarCampaignDiscounts[0].amount_cents / 100 : $scope.manualCouponDiscount,
            mealEquivalencyAdjustment: false,
            percentDiscount: campaignLargerDiscount.percent,
            labelledDiscounts: []
        };

        if ($stateParams && $stateParams.adjustments) {
            $scope.adjustments = angular.copy($stateParams.adjustments);
        }

        $scope.categories = [];

        $scope.currentMenuType = 'menuItem';


        /*                START NEW MENU                 */

        var searchString = '';
        var listItems = null;
        $scope.servicePeriodInfo = {};
        $scope.filteredList = null;

        $scope.crumbs = [];

        var filterSearch = function () {

            var val = searchString.toLowerCase();

            $scope.filteredList = listItems ? _.filter(listItems, function (child) {
                // explicit null check for empty grid squares
                if (child === null) return true;
                return child['name'].toLowerCase().indexOf(val.toLowerCase()) !== -1;
            }) : null;

            if (!val) {
                return;
            }

            $scope.filteredList = _.sortBy($scope.filteredList, function (o) {
                // explicit null check for empty grid squares, returning true will keep empty squares in place
                if (o === null) return true;
                return o.type;
            });
        };

        $scope.itemInStock = (item) => {
            const isItem = item && item.type === 'item';
            const noBackOrders = item.trackStockEnabled && !item.allowBackorders;
            const noStockRemaining = item.currentQuantity <= item.targetQuantity;
            return !(isItem && noBackOrders && noStockRemaining);
        };

        $scope.navigateTree = function (item, itemAddCallback, checkIfAvailable = true) {
            if (item) {
                if (!item.active || !$scope.itemInStock(item) || (checkIfAvailable && !item.available)) {
                    return;
                }

                var itemCopy = angular.copy(item);

                if (item.type === 'item') {
                    if ((item.children && item.children.length > 0) || KioskService.isKiosk() || item.loyaltyStepCost) {
                        var modifyPromise = modifyItem(itemCopy);
                        if (itemAddCallback) {
                            modifyPromise.then(itemAddCallback);
                        }
                    } else {
                        addMenuItem(itemCopy);

                        if (itemAddCallback) {
                            itemAddCallback();
                        }
                    }
                } else {
                    if (item.subtype !== 'page') {
                        $scope.crumbs.push(itemCopy);
                    }

                    navigatedTree(itemCopy);
                }
            }
        };

        var navigatedTree = function (item) {
            if (item) {
                listItems = item.children;
                filterSearch();
            }
        };

        $scope.showCategory = function (category) {
            // check count of inactive children
            let count = category.children.filter((child) => child && !(child.active)).length;

            // show category if all children are active
            return !(count === category.children.length);
        };

        $scope.setListItems = function (items) {
            listItems = items;
            filterSearch();
        };

        $scope.back = function () {
            if ($scope.crumbs.length <= 1) {
                // Cannot go back any further; skip code execution.
                return;
            }
            $scope.crumbs.pop();
            if (KioskService.isKiosk()) {
                $scope.selectedCategoryChanged();
            }

            var lastItem = $scope.getLastCrumb();
            navigatedTree(lastItem);
        };

        $scope.addMenuItem = function (item) {
            addMenuItem(angular.copy(item));
        };

        $scope.getLastCrumb = function () {
            if ($scope.crumbs.length > 0) {
                return $scope.crumbs[$scope.crumbs.length - 1];
            }

            return null;
        };

        var modifyItem = function (item) {
            var parent = $scope.getLastCrumb();
            item.quantity = item.quantity || 1;
            return showModifyModal(parent, item, false);
        };

        $scope.modifyExistingItem = function (item) {
            item = $scope.fullReceipt[item.index];
            item.quantity = item.quantity || 1;
            return showModifyModal(item.parent, item, true);
        };

        $scope.selectReceiptItem = function (receiptItem) {
            // In `receiptItem`, the field that means the "unit price" of an
            // item is `itemPrice`
            receiptItem.detail = receiptItem.detail || {};
            receiptItem.detail.quantity = receiptItem.quantity || 1;

            receiptItem.detail.discount = receiptItem.detail.discount || {};
            // $scope.selectedReceiptItem = receiptItem;

        };

        /**
        ** The case takes in consideration that the price in the details object is the new price being set
        ** and hence calculates the % off differently.
        ** For the new modal , a new case is introduced as price_discount, which considers that the price being passed is being
        ** given off (rather than it being the new price like the case above).
        **/
        var calculateDiscount = function (receiptItem, field) {
            switch (field) {
                case 'percentage':
                    receiptItem.detail.discount.price =
                        parseFloat(receiptItem.price * (1 - receiptItem.detail.discount.percentage))
                            .toPrecision(12);
                    break;
                case 'price':
                    receiptItem.detail.discount.percentage = (
                        parseFloat(1 - receiptItem.detail.discount.price / receiptItem.price))
                        .toPrecision(12);
                    break;
                case 'price_discount':
                    receiptItem.detail.discount.percentage = new Decimal(receiptItem.detail.discount.price)
                        .dividedBy(new Decimal(receiptItem.totalIndividualPrice))
                        .toDecimalPlaces(12)
                        .toNumber();
                    break;
            }
        };

        $scope.populateItemFromModalResponse = function (receiptItem, modalResponse) {
            receiptItem.children = modalResponse.item.children;
            receiptItem.detail.quantity = modalResponse.item.quantity;
            receiptItem.detail.price = modalResponse.item.price;
            receiptItem.price = modalResponse.item.price.toNumber(); // Added price to enable price Changes in the receipt
            delete receiptItem.detail.discount.type;
            receiptItem.detail.discount.percentage = modalResponse.item.discount.percentage;
            receiptItem.detail.discount.price = modalResponse.item.discount.price;
            receiptItem.detail.discount.labelledDiscount = modalResponse.item.discount.labelledDiscount;
            receiptItem.detail.discount.subtype = modalResponse.item.discount.subtype;
            receiptItem.totalIndividualPrice = modalResponse.item.totalIndividualPrice;
            receiptItem.visualDiscount = modalResponse.item.visualDiscount;
            receiptItem.notes = modalResponse.item.notes;
            receiptItem.visualPrice = modalResponse.item.visualPrice;
            receiptItem.visualTotal = modalResponse.item.visualTotal;

            receiptItem._timestamp = modalResponse.item._timestamp;

            receiptItem.discount = modalResponse.item.discount;

            var discountType = (modalResponse.item.discount.type == 'price') ? modalResponse.item.discount.type + '_discount' : modalResponse.item.discount.type;
            calculateDiscount(receiptItem, discountType);
            return receiptItem;
        };

        var showModifyModal = function (parent, item, isEdit) {
            var activeTemplateUrl;
            var activeWindowClass;
            if (KioskService.isKiosk()) {
                activeTemplateUrl = 'pos/pos-modify/kiosk-pos-modify.tpl.html';
                activeWindowClass = 'kiosk-modal';
            } else {
                activeTemplateUrl = 'pos/pos-modify/pos-modify.tpl.html';
                activeWindowClass = 'mods-modal';
            }
            // Since price is coming in actual prices and not cents
            $scope.modificationModalDismissed = false;
            item.price = new Decimal(item.price).toNearest(DataService.baseDollar);
            item.itemPrice = item.price;
            var modifyModal = $modal.open({
                templateUrl: activeTemplateUrl,
                controller: 'PosModify',
                windowClass: activeWindowClass,
                animation: false,
                resolve: {
                    parent: function () {
                        return parent;
                    },
                    item: function () {
                        return item;
                    },
                    isEdit: function () {
                        return isEdit;
                    },
                    applyReceiptItemChangeFunction: function () {
                        return $scope.applyReceiptItemChange;
                    },
                    adjustments: function () {
                        return $scope.adjustments;
                    },
                    createPOSDataFunction: function () {
                        return $scope.createPosData;
                    },
                    patron: function () {
                        return $scope.patron;
                    }
                },
                backdrop: 'static'
            }, function (error) {
            });
            return modifyModal.result.then(function (response) {

                try {
                    // The original item is not updated yet, so send the modified
                    // item to Solink and update the original
                    SolinkService.updateItem(response.item, $scope.adjustments.transactionUuid, $scope.fullReceipt, $scope.solinkCancelledItems, false);
                    // Deep copy these values to the original item to ensure
                    // that it stays up to date and is not modified randomely
                    item.solinkItemTimes = angular.copy(response.item.solinkItemTimes);
                } catch (err) {
                    $log.error('Failed to send event to Solink', err);
                }

                // We want to return to the main menu page after adding an item for kiosks.
                if (KioskService.isKiosk()) {
                    $scope.selectedCategoryChanged();
                    $scope.back();
                }
                item._timestamp = Date.now();

                if (!response.isEdit) {
                    $scope.checkIfDecimalQuantity(response.item, 'Raw Modal Response On Add');
                    $scope.selectReceiptItem(response.item);
                    $scope.populateItemFromModalResponse(response.item, response);
                    addMenuItem(response.item);
                    $scope.applyReceiptItemChange(angular.copy(response.item), $scope.adjustments, false, false, true, true);
                    $scope.checkIfDecimalQuantity(response.item, 'Parsed Modal Response On Add');
                } else {
                    // $scope.rebuildReceipt();
                    $scope.modifierModalResponse = response;
                    $scope.checkIfDecimalQuantity(response.item, 'Raw Modal Response On Edit');

                    // for when the item needs to be reprinted to kitchen
                    item.updatedSinceLastKitchenPrint = response.item.updatedSinceLastKitchenPrint;

                    // TODO: ideally we want to call $scope.rebuildReceipt (which is commented out), but this
                    // part of the code is way too messy so we are only calling `parseDisplayLineItems` to be
                    // safe
                    parseDisplayLineItems();
                }
            }, function () {
                $scope.modificationModalDismissed = true;
            });
        };

        $scope.checkIfDecimalQuantity = function (item, type) {
            try {
                _.each(item.children, function (catgory) {
                    _.each(catgory.children, function (modifier) {
                        var decimalRegex = RegExp('^[0-9]*[.][0-9]*$');
                        if (decimalRegex.test(modifier.quantity)) {
                            var errorObj = {
                                type: type,
                                item: item,
                                modifier: modifier
                            };
                            $log.error(errorObj);
                        }
                    });
                });
            } catch (ex) {
                $log.error(ex);
            }
        };


        /*                 END NEW MENU                 */

        getCampaignDiscountFromBackEnd();


        // try to store UUID here
        let transactionUuid = $scope.adjustments.transactionUuid;
        let transactionStartTime = $scope.adjustments.transactionStartTime;

        if (!transactionUuid || !transactionStartTime) {
            transactionUuid = Pure.generateUuid();
            transactionStartTime = Date.now();
            // This is where a transaction gets initialized
            try {
                SolinkService.sendTransactionStart(transactionUuid, transactionStartTime);
            } catch (err) {
                $log.error('Failed to send event to Solink', err);
            }
        }

        $scope.adjustments = {
            dcbAdjustment: 0,
            cashAdjustment: 0,
            creditCardAdjustment: 0,
            debitCardAdjustment: 0,
            otherAdjustment: 0,
            dollarDiscount: $scope.sortedDollarCampaignDiscounts[0] ? $scope.sortedDollarCampaignDiscounts[0].amount_cents / 100 : $scope.manualCouponDiscount,
            mealEquivalencyAdjustment: false,
            percentDiscount: campaignLargerDiscount.percent,
            transactionUuid: transactionUuid,
            transactionStartTime: transactionStartTime
        };
        $scope.categories = [];

        $scope.currentMenuType = 'menuItem';

        if (!angular.isDefined($scope.patron) || !angular.isDefined($scope.shiftLocationId)) {
            PosAlertService.showError('error', 'This is an invalid patron key');
            $state.go('freshideas.posStart');
        }
        $scope.upc = {
            upc: undefined,
            serviceLocation: $scope.shiftLocationId
        };

        // $scope.receipt/$scope.fullReceipt are being directly referenced
        // in Tender popup and it is essential to keep this reference in
        // order for any discount/loyalty transaction to work properly. This
        // is probably not the most ideal place to store such information
        // but will have to live with this until better solution is found.
        $scope.receipt = [];
        $scope.fullReceipt = [];
        // Keep track of cancelled items
        $scope.cancelledItemsCount = 0;
        $scope.cancelledItemsAmount = 0;
        $scope.cancelledItemsUserId = null;
        /*
        Commented by Nick Simone 2021/10/06
        Updated 2021/12/08 (removed solink item list for non-cancelled items)
        This data structure is used ONLY for the solink integration
        ($scope.solinkCancelledItems). The list stores only minimal
        cancelled item information that can be used by solink.
        */
        $scope.solinkCancelledItems = [];

        var resetReceipt = function () {
            $scope.receipt.length = 0;
            $scope.fullReceipt.length = 0;
        };

        function getCampaignDiscountFromBackEnd () {

            var campaignDiscounts = [];

            if ($scope.lucovaUser) {
                campaignDiscounts = $scope.lucovaUser.discounts;
            }

            var percentCampaignDiscounts = [];
            var dollarCampaignDiscounts = [];

            for (var i = 0; i < campaignDiscounts.length; i++) {

                if (campaignDiscounts[i].amount_cents === null
                    || campaignDiscounts[i].amount_cents === undefined) {
                    percentCampaignDiscounts.push(campaignDiscounts[i]);
                } else {
                    dollarCampaignDiscounts.push(campaignDiscounts[i]);
                }
            }

            percentCampaignDiscounts.sort(function (a, b) {

                return b.amount_percent - a.amount_percent;
            });

            dollarCampaignDiscounts.sort(function (a, b) {
                return b.amount_cents - a.amount_cents;
            });

            $scope.sortedDollarCampaignDiscounts = dollarCampaignDiscounts.sort(function (a, b) {
                return b.amount_cents - a.amount_cents;
            });

            $scope.sortedPercentCampaignDiscounts = percentCampaignDiscounts.sort(function (a, b) {
                return b.amount_percent - a.amount_percent;
            });

            if (typeof dollarCampaignDiscounts !== 'undefined' && dollarCampaignDiscounts.length > 0) {
                campaignLargerDiscount = dollarCampaignDiscounts[0];
            } else if (typeof percentCampaignDiscounts !== 'undefined' && percentCampaignDiscounts.length > 0) {
                campaignLargerDiscount = percentCampaignDiscounts[0];
            }

            if ($scope.sortedPercentCampaignDiscounts[0] !== undefined) {
                $scope.tmpCampaignPercentDiscountForView = $scope.sortedPercentCampaignDiscounts[0].amount_percent;

                $scope.currentDiscountType = 'percent';
                $scope.currentDiscountIndex = 0;
            }
            if ($scope.sortedDollarCampaignDiscounts[0] !== undefined) {
                $scope.tmpCampaignDollarDiscountForView = $scope.sortedDollarCampaignDiscounts[0].amount_cents / 100;

                $scope.currentDiscountType = 'absolute';
                $scope.currentDiscountIndex = 0;
            }
        }

        var currentLocation;
        $scope.init = async function () {
            // ensure this is executed immediately because it is critical to
            // set whether a transaction is a guest or not
            getPatronInfo();

            // This is mostly used to match current location's meal plans when "Lookup MealPlan". No other information is dependent
            //  on this, so for now it's fine to get this information in a separate call by itself;
            if (!$scope.shiftLocationId && EnvConfig.env !== 'test') {
                $log.error({
                    message: 'shiftLocationId value not set in function init of pos.js : ' + $scope.shiftLocationId,
                    context: {
                        user: JSON.stringify(CurrentSession.getUser())
                    }
                });
            }
            var currentLocation = await Locations.get({'locationId': $scope.shiftLocationId}).$promise.catch(console.error);
            CommonOfflineCache.saveCurrentLocation(currentLocation);
        };

        $scope.openAddGuestNameModal = function () {
            var modalInstance = $modal.open({
                templateUrl: 'pos/smb/templates/pos.add.guestName.tpl.html',
                animation: false,
                backdrop: 'static',
                controller: 'SmbPosAddGuestNameCtrl',
                windowClass: 'add-guest-name-modal'
            });

            modalInstance.result.then(function (resolved) {
                if (resolved) {
                    $scope.patron.fullName = resolved;
                } else {
                    $scope.patron.fullName = 'Guest';
                }
            }, function (dismissed) {
            });
        };

        var patronSelectionPromiseResolve = function (patronSelectionPromise, patron) {
            patronSelectionPromise.then(function (fiitPatron) {
                var patronObj = {};

                patronObj.fiitPatron = fiitPatron;
                if (patron.lucovaUser) {
                    patronObj.lucovaUser = angular.copy(patron.lucovaUser);

                    if (patron.lucovaUser.nownGiftCards
                        && patron.lucovaUser.nownGiftCards.length > 0) {
                        patronObj.lucovaUser.nownGiftCards = patron.lucovaUser.nownGiftCards;
                    }
                }

                if (patron.fiitMpsAccount) {
                    patronObj.fiitMpsAccount = patron.fiitMpsAccount;
                }

                patronObj.isNearby = patron.isNearby;

                patronObj = SmbPosService.transformPatron(patronObj);
                $scope.patron = patronObj;
                $scope.order.patron = patronObj;
                SecondaryDisplay.updateTransaction($scope.receipt, $scope.currentOrderBalance, $scope.tenderAmounts, $scope.order.patron);
                getPatronInfo(true);
                $rootScope.$broadcast('pos::open-customer-loyalty', $scope.adjustments);
            }).catch(function (error) {
                $scope.patron = patron;
                $scope.order.patron = patron;
                SecondaryDisplay.updateTransaction($scope.receipt, $scope.currentOrderBalance, $scope.tenderAmounts, $scope.order.patron);
                getPatronInfo(true);
                $rootScope.$broadcast('pos::open-customer-loyalty', $scope.adjustments);
            });
        };

        var filterPatronObject = function (patron) {
            var patronSelectionPromise;
            var deferred;
            if (patron.lucovaUser
                && patron.lucovaUser.nown
                && patron.lucovaUser.nown.nown_gift_nums
                && patron.lucovaUser.nown.nown_gift_nums.length > 0) {

                // Gift Cards
                // Ideally we should send one request to get all the gift cards but since we do not expect a
                // customer to have more than 2-3 giftcards it is acceptable here to send a request per gift card
                var giftCardPromises = [];
                for (var i = 0; i < patron.lucovaUser.nown.nown_gift_nums.length; i++) {
                    patron.lucovaUser.nownGiftCards = [];
                    giftCardPromises.push(
                        CashierShift.lookupGiftCard({
                            code: patron.lucovaUser.nown.nown_gift_nums[i],
                            currencyId: CurrentSession.getCompany().baseCurrencyId,
                            cashierShiftId: SmbPosService.shift.cashierShiftId
                        }).$promise
                    );
                }

                Promise.all(giftCardPromises).then(function (giftCards) {
                    for (var i = 0; i < giftCards.length; i++) {
                        if (giftCards[i].id) {
                            patron.lucovaUser.nownGiftCards.push(giftCards[i]);
                        }
                    }

                    // TODO: Rethinking this because all Lucova users should theoretically have
                    // fiitPatron info by now so the linking might not be necessary anymore
                    patronSelectionPromise = SmbPosService.linkFiitPatron(patron.lucovaUser);
                    patronSelectionPromiseResolve(patronSelectionPromise, patron);
                }, function (error) {
                    console.error(error);
                });
            } else if (patron.lucovaUser) {
                patronSelectionPromise = SmbPosService.linkFiitPatron(patron.lucovaUser);
                patronSelectionPromiseResolve(patronSelectionPromise, patron);
            } else if (patron.fiitPatron) {
                delete patron.lucovaUser;

                // Using `$q.defer` instead of `Promise` to trigger $scope.digest
                // for any changes outside of $scope cycle.
                deferred = $q.defer();
                patronSelectionPromise = deferred.promise;
                deferred.resolve(patron.fiitPatron);
                patronSelectionPromiseResolve(patronSelectionPromise, patron);
            } else {
                /**
                *** Commented By Akash Mehta on 27th Mar 2020
                *** NOTE : `fiitMpsAccount` field is used to store the meal Plan account details from FIIT.
                *** Just a note to the next developer !! We did not add a new check for fiitMpsAccount
                *** above as the code below does the exact behaviour required when we scan the physical meal card.
                *** When someone scans a physical FIIT meal card, the patron obj in this case does not need any transformation.
                *** Hence, we just proceed ahead by rejecting the promise, which inturns proceeds ahead with starting the order.
                *** If the above mentioned flow changes in this code block, please ensure that you create an explicit check for
                *** the field `fiitMpsAccount` with the currently existing logic.
                **/
                deferred = $q.defer();
                patronSelectionPromise = deferred.promise;
                deferred.reject({});
                patronSelectionPromiseResolve(patronSelectionPromise, patron);
            }
        };

        $scope.openAddPatronModal = function () {
            var modalInstance = $modal.open({
                templateUrl: 'pos/smb/templates/pos.manual.checkin.tpl.html',
                animation: false,
                backdrop: true,
                controller: 'SmbManualCheckinCtrl',
                windowClass: 'smb-pos__checkin',
                resolve: {
                    selectPatron: function () { },
                    isGatewayFiitEnabled: function () {
                        return GatewayFiit.isEnabled();
                    },
                    isExistingOrder: function () {
                        return true;
                    },
                    showError: function () {
                        return false;
                    },
                    isQuickChargeEnabled: function () {
                        return false;
                    },
                    autoCloseModalAfterScan: () => true,
                    dismissButtonText: () => {}
                },
                keyboard: false
            });

            modalInstance.result.then(function (patron) {
                if (patron) {
                    filterPatronObject(patron);
                }
            });
        };

        $scope.openAddPatronOrGuestLabelModal = function () {
            var modalInstance = $modal.open({
                templateUrl: 'pos/smb/templates/pos.add.patron.or.guestlabel.tpl.html',
                animation: false,
                backdrop: true,
                controller: [
                    '$scope',
                    '$modal',
                    function ($scope, $modal) {
                        $scope.chooseAddPatron = function () {
                            $scope.$close('patron');
                        };
                        $scope.chooseAddGuestLabel = function () {
                            $scope.$close('label');
                        };
                        $scope.cancel = function () {
                            $scope.$dismiss();
                        };
                    }
                ],
                windowClass: 'smb-pos__add-patron-or-guestlabel',
                keyboard: false
            });

            modalInstance.result.then(function (result) {
                if (result === 'patron') {
                    $scope.openAddPatronModal();
                } else if (result === 'label') {
                    $scope.openAddGuestNameModal();
                }
            });
        };

        var getPatronInfo = function (isExistingOrder = false) {
            if ($scope.patron) {
                if ($scope.patron.patronId) {
                    $scope.guestTransaction = false;
                    if (isOffline) {
                        PosBuilderService.getCachedBalances($scope.patron)
                            .then(function (balances) {
                                availableBalancesObtained(balances, isExistingOrder);
                            }).catch(function (error) {
                                PosAlertService.showError('error', error);
                                $state.go('freshideas.posStart', {errorMessage: error});
                            });
                    } else {
                        CashierShift.reconcileAvailableBalancesv1({
                            'patronId': $scope.patron.patronId,
                            'locationId': $scope.shiftLocationId
                        }, function (response) {
                            availableBalancesObtained(response, isExistingOrder);
                        }, function (error) {
                            PosAlertService.showError('error', error.data.error);
                            $state.go('freshideas.posStart', {errorMessage: error.data.error});
                        });
                    }
                } else {
                    $scope.patron = {
                        patronId: undefined,
                        fullName: 'Guest',
                        firstName: 'Guest',
                        lastName: '',
                    };
                    $scope.guestTransaction = true;

                    availableBalancesObtained([], isExistingOrder);
                }
            } else {
                $state.go('freshideas.posStart');
            }
        };

        var availableBalancesObtained = function (available, isExistingOrder = false) {
            if (!isExistingOrder) {
                resetReceipt();
            }
            if ($scope.suspendEnabled) {
                $scope.$broadcast('pos::suspend::receipt::reset');
            }

            $scope.available = available.filter((mp) => mp.name != 'Loyalty');
            $scope.totalAvailableUnits = SharedFunctionService.calculateServicePeriodAvailableBalances($scope.available);
        };

        $scope.isItemVisible = function (item) {

            return (($scope.lucovaUser || item.mobileUsersOnly === false) && item.mobileOrderOnly === false) || item.type !== 'item';
        };

        $scope.setCampaignDiscount = function (checker, value) {

            $scope.currentDiscountType = checker;
            $scope.currentDiscountIndex = value;

            if (checker === 'percent') {
                $scope.manualCouponDiscount = null;
                $scope.manualCouponCode = '';
                $scope.adjustments.dollarDiscount = null;
                $scope.adjustments.percentDiscount = $scope.sortedPercentCampaignDiscounts[value].amount_percent ? $scope.sortedPercentCampaignDiscounts[value].amount_percent : 0;
                campaignLargerDiscount = $scope.sortedPercentCampaignDiscounts[value];
                $scope.tmpCampaignPercentDiscountForView = $scope.sortedPercentCampaignDiscounts[value].amount_percent;
            } else if (checker === 'absolute') {
                $scope.manualCouponDiscount = null;
                $scope.manualCouponCode = '';
                $scope.adjustments.percentDiscount = null;
                $scope.adjustments.dollarDiscount = $scope.sortedDollarCampaignDiscounts[value].amount_cents ? $scope.sortedDollarCampaignDiscounts[value].amount_cents / 100 : 0;
                campaignLargerDiscount = $scope.sortedDollarCampaignDiscounts[value];
                $scope.tmpCampaignDollarDiscountForView = $scope.sortedDollarCampaignDiscounts[value].amount_cents / 100;
            } else if (checker === 'manual') {
                $scope.adjustments.percentDiscount = null;
                $scope.adjustments.dollarDiscount = $scope.manualCouponDiscount;
                campaignLargerDiscount = null;
            }

            var calculated = SharedFunctionService.calculateBalances($scope.receipt, $scope.available, $scope.adjustments, createPosData(), $scope.patron);
            Controller.copyCalculations(calculated.payments, $scope.currentOrderBalance);
        };

        $scope.calculatePriceAndTaxesForManualCouponKeyUp = function () {

            $scope.adjustments.dollarDiscount = $scope.manualCouponDiscount;

            var calculated = SharedFunctionService.calculateBalances($scope.receipt, $scope.available, $scope.adjustments, createPosData(), $scope.patron);
            Controller.copyCalculations(calculated.payments, $scope.currentOrderBalance);
        };

        $scope.resetDiscounts = function () {
            $scope.manualCouponDiscount = null;
            $scope.manualCouponCode = '';
            $scope.tmpCampaignPercentDiscountForView = null;
            $scope.tmpCampaignDollarDiscountForView = null;
            campaignLargerDiscount = null;
            $scope.currentDiscountIndex = null;
            $scope.currentDiscountType = null;
            $scope.adjustments.dollarDiscount = null;
            $scope.adjustments.percentDiscount = null;
            $scope.adjustments.labelledDiscounts = null;

            var calculated = SharedFunctionService.calculateBalances($scope.receipt, $scope.available, $scope.adjustments, createPosData(), $scope.patron);
            Controller.copyCalculations(calculated.payments, $scope.currentOrderBalance);
        };

        $scope.setCouponDiscountView = function (switchValue) {
        };


        $scope.lookupPatronMealPlans = function () {
            $modal.open({
                templateUrl: 'common/modals/modalPatronMealPlans.tpl.html',
                controller: 'PosMealPlansCtrl',
                windowClass: 'modal-90',
                animation: false,
                resolve: {
                    patron: function () {
                        return $scope.patron;
                    },
                    currentLocation: function () {
                        return currentLocation || {};
                    }
                },
                backdrop: 'static'
            }, function (error) {
                //
            });
        };
        $scope.openInventorySearch = function () {
            if ($scope.currentMenuType !== 'upc') {
                $scope.currentMenuType = 'upc';
            } else {
                $scope.showMenuItems();
            }
        };
        $scope.openDiscountView = function () {
            if ($scope.currentMenuType !== 'discount') {
                $scope.currentMenuType = 'discount';
            } else {
                $scope.showMenuItems();
            }
        };
        $scope.showMenuItems = function () {
            $scope.currentMenuType = 'menuItem';
        };
        $scope.openNumpad = function (menuItem, type, style, initial) {

            var misc = angular.copy(menuItem);
            var itemType = type;

            var filterType = 'number';
            if (type === 'menu') {
                filterType = 'currency';
            }

            var modalInstance = $modal.open({
                templateUrl: 'pos/pos.numpad.tpl.html',
                controller: 'PosNumpadCtrl',
                animation: false,
                windowClass: `modal-numpad modal-fullscreen-transparent ${style || 'modal-right'}`,
                resolve: {
                    initial: function () {
                        return initial || 0;
                    },
                    type: function () {
                        return filterType;
                    }
                },
                backdrop: true
            });

            return modalInstance.result.then(function (amount) {
                var amt = parseFloat(amount);

                var item = misc;

                if (SharedDataService.taxIncludedInPrice) {
                    var decimalPreTaxPrice = new Decimal(amt)
                        .dividedBy(1 + item.taxRate)
                        .toNearest(SharedDataService.baseDollar);

                    item.price = decimalPreTaxPrice.toNumber();
                } else {
                    item.price = amt;
                }

                if (itemType === 'menu') {
                    item.locationServicePeriodMenuId = -9999;

                    if (amt != 0) {
                        addMenuItem(item);
                    }
                } else {
                    item.upc = -9999;
                    item.total = item.price;
                    item.itemTotalPrice = item.price;
                    item.itemPrice = item.price;
                    item.originalPrice = item.originalPrice;

                    if (amt != 0) {
                        $scope.addInventoryItem(item);
                    }
                }
            });
        };

        $scope.addCashCardAdjustment = function (cashCard) {
            if (!$scope.adjustments.prepaidAmounts) {
                $scope.adjustments.prepaidAmounts = [cashCard];
            } else {
                let alreadyApplied = $scope.adjustments.prepaidAmounts.find((_cashCard) => _cashCard.id === cashCard.id);
                if (!alreadyApplied) {
                    $scope.adjustments.prepaidAmounts.push(cashCard);
                }
            }
            $scope.rebuildReceipt();
        };

        $scope.removeCashCardAdjustment = function ($event, cashCard) {
            if ($event) {
                $event.stopPropagation();
            }
            if (!cashCard || !$scope.adjustments.prepaidAmounts) {
                return;
            }
            $scope.adjustments.prepaidAmounts = $scope.adjustments.prepaidAmounts.filter((_cashCard) => _cashCard.id !== cashCard.id);
            $scope.rebuildReceipt();
        };

        $scope.addCustomInventoryItem = function (menuItem, amount) {
            var misc = angular.copy(menuItem);
            var amt = parseFloat(amount);

            var item = misc;
            item.price = amt;
            item.total = item.price;
            item.itemTotalPrice = item.price;
            item.itemPrice = item.price;
            item.originalPrice = item.originalPrice;
            item.quantity = 1;

            if (amt != 0) {
                $scope.addInventoryItem(item);
            }
        };

        /**
        ** Function to build the amount to display
        ** Next to the main item. This function is currently only being called by calculate Discount,
        ** where we are also calculating the visual amount to display.
        ** It also populates a map of modifiers (locationMenuId) and their respective price to show on
        ** the sidebar.
        ** Takes in a param of flat list of items returned by the function Controller.buildItem function
        **/
        var buildVisualAmount = function (items) {
            $scope.modifierPrices = {};
            var overallPrice = new Decimal(0.00);
            var overallTotal = new Decimal(0.00);
            for (var item of items) {

                if (!(item.subtype === 'discount' || item.subtype === 'loyalty')) {
                    overallPrice = overallPrice.plus(item.price);
                    overallTotal = overallTotal.plus(item.total);
                }
            }
            return {
                price: overallPrice,
                total: overallTotal
            };
        };

        /**
        ** Function to build the visual Discount to be displayed on the modal
        ** This function is called whenever an item is toggled or whenvever a new percentage is set,
        ** basically, any change relating to price happening and when its being calculated again
        ** Takes in a param of flat list of items returned by the function Controller.buildItem function
        **/
        function buildVisualDiscount (item, receiptItem) {
            var {price: decimalOriginalPrice, total: decimalOriginalTotal} = buildVisualAmount(receiptItem);

            var calculatedBalances = SharedFunctionService.calculateBalances(receiptItem, null, $scope.adjustments, $scope.createPosData(), $scope.patron);

            item.visualPrice = new Decimal(calculatedBalances.payments.subTotalAmount);
            item.visualTotal = new Decimal(calculatedBalances.payments.totalAmount);

            item.visualDiscount = item.visualDiscount || {};

            // leave 2 decimal places for percentage display (eg. 12.75%)
            var tempVisualDiscount = new Decimal(item.discount.percentage).times(100).toNearest(0.01);
            item.visualDiscount.price = -(decimalOriginalPrice.minus(item.visualPrice).toNearest(SharedDataService.baseDollar));
            item.visualDiscount.total = -(decimalOriginalTotal.minus(item.visualTotal).toNearest(SharedDataService.baseDollar));

            if (item.discount.subtype === 'promo') {
                item.visualDiscount.name = $translate.instant('smb.pos.receipt.item.discount.promo.label', {
                    itemName: item.name
                });
            } else if (item.discount.subtype === 'labelled') {
                item.visualDiscount.name = $translate.instant('smb.pos.receipt.item.discount.labelled.label', {
                    itemName: item.name,
                    labelledDiscount: item.discount.labelledDiscount
                });
            } else {
                item.visualDiscount.name = $translate.instant('smb.pos.receipt.item.discount.percentage.label', {
                    itemName: item.name,
                    discountPercentage: tempVisualDiscount.toFixed(2)
                });
            }
        }

        /**
        ** Function to build the amount on which discount is to be applied
        ** This function is called whenever an item is toggled or whenvever a new percentage is set,
        ** basically, any change relating to price happening and when its being calculated again
        ** Takes in a param of flat list of items returned by the function Controller.buildItem function
        **/
        var buildItemDiscountAmount = function (items) {
            var totalAmountToDiscountFrom = new Decimal(0.00);
            for (var item of items) {
                if (!(item.subtype === 'discount' || item.subtype === 'loyalty') && item.price > 0.00) {
                    totalAmountToDiscountFrom = totalAmountToDiscountFrom.plus(item.price);
                }
            }
            return totalAmountToDiscountFrom;
        };

        $scope.flatItemReceipt = [];

        var onEditDiscount = function (item, editedValue, isPromo, labelledDiscount) {
            if (item.discount.type == 'percentage') {
                item.discount.percentage = parseFloat(editedValue);
                item.discount.visualVal = new Decimal(editedValue * 100).toFixed(0);
            } else if (item.discount.type == 'price') {
                if (editedValue) {
                    item.discount.price = parseFloat(editedValue);
                    $scope.flatItemReceipt = Controller.buildItem(item, 0, 0);
                    var originalTotalAmt = buildItemDiscountAmount($scope.flatItemReceipt);
                    item.discount.percentage = new Decimal(item.discount.price).dividedBy(originalTotalAmt);
                }
                item.discount.visualVal = new Decimal(editedValue).toNearest(SharedDataService.baseDollar);
            }

            if (labelledDiscount) {
                item.discount.labelledDiscount = labelledDiscount;
                item.discount.subtype = 'labelled';
            } else if (isPromo) {
                delete item.discount.labelledDiscount;
                item.discount.subtype = 'promo';
            } else {
                delete item.discount.labelledDiscount;
                delete item.discount.subtype;
            }

            $scope.applyReceiptItemChange(item, $scope.adjustments, true, true);
            $scope.flatItemReceipt = Controller.buildItem(item, 0, 0);
            buildVisualDiscount(item, $scope.flatItemReceipt);
        };

        $scope.selectLabelledDiscount = function (item, labelledDiscount) {
            item.discount = item.discount || {};
            item.discount.percentage = item.discount.percentage || 0.00;
            item.discount.price = item.discount.price || 0.00;
            item.discount.visualVal = item.discount.visualVal || 0.00;
            item.totalIndividualPrice = item.totalIndividualPrice || 0.00;
            item.discount.type = 'percentage';
            onEditDiscount(item, labelledDiscount.discountPercentage, false, labelledDiscount);
        };

        // Pan receipt item (swipe to remove item)
        $scope.panReceiptItem = function (event, receiptItem) {
            if (receiptItem.isClosing || receiptItem.isClosingRight) {
                return false;
            }
            receiptItem.isMoving = true;
            receiptItem.deltaX = event.deltaX;
        };

        // Swipe removes item from cart
        $scope.removeReceiptItemFlyLeft = function (receiptItem) {
            if (receiptItem.isClosingLeft || receiptItem.isClosingRight) {
                return false;
            }

            receiptItem.isClosingLeft = true;
            setTimeout(function () {
                $scope.removeReceiptItem(receiptItem);
            }, 400);
        };
        $scope.removeReceiptItemFlyRight = function (receiptItem) {
            if (receiptItem.isClosingLeft || receiptItem.isClosingRight) {
                return false;
            }

            receiptItem.isClosingRight = true;
            setTimeout(function () {
                $scope.removeReceiptItem(receiptItem);
            }, 400);
        };

        function removeReceiptItem (receiptItem) {
            updateReceipt(receiptItem, false, false);
        }

        var addedToCartTimer;

        var addMenuItem = function (menuItem) {
            if ((menuItem.customPriceEnabled === true) && menuItem.locationServicePeriodMenuId !== -9999) {
                $scope.openNumpad(menuItem, 'menu');
                return;
            }
            updateReceipt(menuItem);
            $scope.addedToCart = true;
            if (addedToCartTimer) {
                $timeout.cancel(addedToCartTimer);
                addedToCartTimer = null;
            }
            addedToCartTimer = $timeout(function () {
                $scope.addedToCart = false;
                addedToCartTimer = null;
            }, 2000);
        };

        $scope.addInventoryItem = function (inventoryItem) {

            if ((inventoryItem.customPriceEnabled === true) && inventoryItem.upc !== -9999) {
                $scope.openNumpad(inventoryItem, 'inventory');
                return;
            }

            updateReceipt(inventoryItem, true);
        };

        $scope.removeReceiptItem = function (receiptItem) {
            var requestedPermission = 'pos:void_remove_items';
            var managerOverrideRequired = Security.isManagerOverrideRequired(requestedPermission);
            if (managerOverrideRequired && !KioskService.isKiosk()) {
                $scope.$emit('PincodeAuthentication:Required', function (callback, message, actionName) {
                    // Only get here if successful authentication
                    removeReceiptItem(receiptItem);
                }, requestedPermission);
            } else {
                removeReceiptItem(receiptItem);
            }
        };


        $scope.fullReceipt = [];
        // Keep track of cancelled items
        $scope.cancelledItemsCount = 0;
        $scope.cancelledItemsAmount = 0;
        $scope.cancelledItemsUserId = null;

        // For Kiosk
        $scope.modifyItemQuantity = function (item, modifier) {
            item = $scope.fullReceipt[item.index];
            $scope.modItem = Object.assign({}, item);
            $scope.transItemVisualDiscount = angular.copy($scope.modItem.visualDiscount) || {};
            $scope.transItemVisualDiscount.sideBarIndex = 2;
            if (modifier == 'plus') {
                item.quantity++;
                $scope.updateItemModifiersAsPerQuantityModifier(item, modifier);
                $scope.fullItem = Controller.buildItem($scope.modItem, 0, 0);
                $scope.modItem.totalIndividualPrice = buildItemDiscountAmount($scope.fullItem).toNumber();
                $scope.applyReceiptItemChange($scope.modItem, $scope.adjustments, true, true);
                $scope.fullItem = Controller.buildItem($scope.modItem, 0, 0);
                buildVisualDiscount($scope.fullItem);
                $scope.rebuildReceipt();
            } else if (modifier == 'minus') {
                if (item.quantity > 1) {
                    item.quantity--;
                    $scope.updateItemModifiersAsPerQuantityModifier(item, modifier);
                    $scope.fullItem = Controller.buildItem($scope.modItem, 0, 0);
                    $scope.modItem.totalIndividualPrice = buildItemDiscountAmount($scope.fullItem).toNumber();
                    $scope.applyReceiptItemChange($scope.modItem, $scope.adjustments, true, true);
                    $scope.fullItem = Controller.buildItem($scope.modItem, 0, 0);
                    buildVisualDiscount($scope.fullItem);
                    $scope.rebuildReceipt();
                }
            }
        };

        $scope.updateItemModifiersAsPerQuantityModifier = function (item, quantMod) {
            _.each(item.children, function (category) {
                _.each(category.children, function (modifier) {
                    if (modifier.selected) {
                        var individualItemModifierQuantity = 0;
                        if (quantMod == 'plus') {
                            individualItemModifierQuantity = modifier.quantity / (item.quantity - 1);
                        } else {
                            individualItemModifierQuantity = modifier.quantity / (item.quantity + 1);
                        }
                        modifier.quantity = individualItemModifierQuantity * item.quantity;
                    }
                });
            });
        };

        $scope.fullReceipt = [];
        // Keep track of cancelled items
        $scope.cancelledItemsCount = 0;
        $scope.cancelledItemsAmount = 0;
        $scope.cancelledItemsUserId = null;

        // Maybe make this configurable?
        var toMergeReceiptItems = true;

        var updateReceipt = function (item, upc = false, shouldAdd = true) {
            if (shouldAdd) {
                var existingItem = _.find($scope.fullReceipt, function (receiptItem) {
                    if (receiptItem.loyaltyStepCost) {
                        // a new item should not stack with an existing one
                        // if the existing one is being bought with points
                        return false;
                    }
                    if (angular.isDefined(receiptItem.upc) && upc) {
                        return Controller.areSameUPC(receiptItem, item);
                    } else if (angular.isDefined(receiptItem.locationServicePeriodMenuId) && angular.isDefined(item.locationServicePeriodMenuId)) {
                        return Controller.areSameMenuItems(receiptItem, item);
                    }
                    return false;
                });

                if (item.loyaltyStepCost) {
                    $scope.adjustments.loyaltyStepAdjustment = item.loyaltyStepCost;
                }

                if (existingItem && toMergeReceiptItems) {
                    existingItem.quantity += 1;
                    existingItem._timestamp = Date.now();
                    try {
                        SolinkService.updateItem(existingItem, $scope.adjustments.transactionUuid, $scope.fullReceipt, $scope.solinkCancelledItems, false);
                    } catch (err) {
                        $log.error('Failed to send event to Solink', err);
                    }
                } else {
                    // Here we need to mark the item as created since the last kitchen print
                    // so the printing workflow does not mark it as updated.
                    item.createdSinceLastKitchenPrint = true;
                    CartBuilderService.addItemToCart($scope.fullReceipt, item, upc);
                    try {
                        SolinkService.updateItem(item, $scope.adjustments.transactionUuid, $scope.fullReceipt, $scope.solinkCancelledItems, false);
                    } catch (err) {
                        $log.error('Failed to send event to Solink', err);
                    }
                }

                if (item.defaultDiscountMatrix) {
                    var itemToModify = existingItem || item;
                    $scope.selectLabelledDiscount(itemToModify, item.defaultDiscountMatrix.discountDTO);
                    $scope.selectReceiptItem(itemToModify);
                    var mockedModalResponse = {
                        item: itemToModify
                    };
                    mockedModalResponse.item.price = new Decimal(mockedModalResponse.item.price);
                    $scope.populateItemFromModalResponse(itemToModify, mockedModalResponse);
                    $scope.applyReceiptItemChange(angular.copy(itemToModify), $scope.adjustments); // -- angular.copy because rebuildReceiptItem affects Price
                } else {
                    $scope.rebuildReceipt();
                }
            } else {
                var pushToCancelledItems = function () {
                    // Update Solink's cancelled items before we remove this item from the receipt
                    try {
                        SolinkService.cancelItem(item, $scope.solinkCancelledItems);
                    } catch (err) {
                        $log.error('Failed to send event to Solink', err);
                    }

                    $scope.cancelledItemsCount += item.quantity;
                    $scope.cancelledItemsAmount += item.quantity * item.data.currentBundlePrice;

                    if (item && item.preCalculatedTender) {
                        let mealPlanIdUsed = item.preCalculatedTender.patronMealPlanId;
                        let patronMealPlans = $scope.patron && $scope.patron.mealPlans ? $scope.patron.mealPlans : [];
                        let mealPlan = patronMealPlans.find((mp) => mp.patronMealPlanId === mealPlanIdUsed);

                        if (mealPlan) {
                            mealPlan.currentMealPlanBalance = mealPlan.currentMealPlanBalance + item.quantity;
                        }
                    }
                };

                // Ask for a pincode input if pin on cancel orders is enabled
                // and no pin user ID is bound
                if (!$scope.cancelledItemsUserId && !PosStatusService.isOffline()) {
                    if (!KioskService.isKiosk()) {
                        SmbPosService.cancelItemsRequestPin($scope).then(function (pinObj) {
                            // Bind user PIN to cancelled order
                            $scope.cancelledItemsUserId = pinObj.user.userId;

                            // Update cancelled items quantity and price
                            pushToCancelledItems();

                            if (item.loyaltyStepCost) {
                                $scope.adjustments.loyaltyStepAdjustment = 0;
                            }

                            // Remove the item itself
                            $scope.fullReceipt.splice(item.index, 1);
                            $scope.rebuildReceipt();
                            try {
                                SolinkService.sendItemRemovedFromBasket($scope.adjustments.transactionUuid, $scope.fullReceipt, $scope.solinkCancelledItems);
                            } catch (err) {
                                $log.error('Failed to send event to Solink', err);
                            }
                        }, function (error) {
                            console.error(error);
                        });
                    } else {
                        $scope.cancelledItemsUserId = CurrentSession.getUser().userId;

                        // Update cancelled items quantity and price
                        pushToCancelledItems();

                        if (item.loyaltyStepCost) {
                            $scope.adjustments.loyaltyStepAdjustment = 0;
                        }

                        // Remove the item itself
                        $scope.fullReceipt.splice(item.index, 1);
                        $scope.rebuildReceipt();
                        try {
                            SolinkService.sendItemRemovedFromBasket($scope.adjustments.transactionUuid, $scope.fullReceipt, $scope.solinkCancelledItems);
                        } catch (err) {
                            $log.error('Failed to send event to Solink', err);
                        }
                    }
                } else {
                    // Update cancelled items quantity and price
                    pushToCancelledItems();

                    if (item.loyaltyStepCost) {
                        $scope.adjustments.loyaltyStepAdjustment = 0;
                    }

                    // Remove the item itself
                    $scope.fullReceipt.splice(item.index, 1);
                    $scope.rebuildReceipt();
                    try {
                        SolinkService.sendItemRemovedFromBasket($scope.adjustments.transactionUuid, $scope.fullReceipt, $scope.solinkCancelledItems);
                    } catch (err) {
                        $log.error('Failed to send event to Solink', err);
                    }
                }
            }
        };
        var trimMenuItem = function (menuItem) {
            if (menuItem.children) {
                var updatedChildren = [];
                for (var child of menuItem.children) {
                    var toKeep = trimMenuItem(child);

                    if (toKeep) {
                        updatedChildren.push(child);
                    }
                }

                menuItem.children = updatedChildren;
            }

            if (menuItem.subtype === 'modifier_option') {
                if (!menuItem.selected) {
                    return false;
                }
            }

            return true;
        };

        /**
        **  THe parameter modifyingItem is being added since we now manipulate price as well of the receiptItem.
        **  So when applyReceiptItemChange is called only for discount, the receiptItem price is changed as per the modifier rules
        **  So if it is only for discount, then making changes of item price is useless and modifies the receipt itself. Hence the
        **  new parameter.
        **/
        $scope.applyReceiptItemChange = function (receiptItem, adjustments, skipReceiptRebuild = false, buildForModal = false, modifyingItem = true, skipPush = false) {
            CartBuilderService.applyReceiptItemChange($scope.fullReceipt, receiptItem, adjustments, {
                skipReceiptRebuild: skipReceiptRebuild,
                buildForModal: buildForModal,
                modifyingItem: modifyingItem,
                rebuildReceiptFn: () => $scope.rebuildReceipt(false, skipPush),
                removeReceiptItemFn: $scope.removeReceiptItem,
                isPGCDiscountable: isPGCDiscountable
            });
        };

        $scope.applyTaxChanges = function (fullReceipt, adjustments) {
            _.each(fullReceipt, function (fullReceiptItem) {
                CartBuilderService.applyTaxToItem(fullReceiptItem, adjustments.selectedTaxRates);
            });
        };

        $scope.rebuildReceipt = function (showAlert = false, skipPush = false) {
            var manualCoupon = {
                manualCouponDiscount: $scope.manualCouponDiscount,
                manualCouponCode: $scope.manualCouponCode
            };
            let applyDiscount = false;

            // filter through all items and check if it contains only giftcards
            // then don't apply any basket level dollar discount
            _.each($scope.fullReceipt, function (receiptItem) {
                if (!(receiptItem.subtype && receiptItem.subtype.includes('giftcard'))
                    && !applyDiscount) {
                        applyDiscount = true;
                }
            });

            if ($scope.fullReceipt.length > 0 && !applyDiscount && !isPGCDiscountable) {
                $scope.resetDiscounts();
                if (showAlert) {
                    PosAlertService.showAlertByName('general-alert', {
                        'title': 'smb.pos.balance.discount.physical.giftcard.error.ttl',
                        'message': 'smb.pos.balance.discount.physical.giftcard.error.msg',
                        'buttonType': 'ok',
                    });
                }
            }

            $scope.tenderAmounts = CartBuilderService.processCartForPos($scope.fullReceipt, $scope.receipt, $scope.adjustments, $scope.available,
                manualCoupon, createPosData(), $scope.patron, $scope.currentOrderBalance);

            // Commented By Akash Mehta on September 9 2020
            // Ideally tenderAmounts should never be null
            // Hence adding an alert from preventing user to continue if it is null
            // We should also log a honeybadger here if possible
            if (!$scope.tenderAmounts) {
                $log.error({
                    message: '[FATAL] No tender amounts generated while rebuilding receipt',
                    context: {
                        user: (CurrentSession.getUser()) ? CurrentSession.getUser().username : '',
                        cart: JSON.stringify($scope.fullReceipt)
                    }
                });
                PosAlertService.showAlertByName('general-error');
                return;
            }

            if (!skipPush) {
                SecondaryDisplay.updateTransaction($scope.receipt, $scope.currentOrderBalance, $scope.tenderAmounts, $scope.order.patron);
            }

            $scope.itemCount = 0;
            $scope.receipt.forEach(function (item) {
                if (item.subtype == null) {
                    $scope.itemCount += item.quantity;
                }
            });

            parseDisplayLineItems();
            if (KioskService.isKiosk()) {
                $rootScope.$broadcast('kiosk::items-modified');
            }
        };


        $scope.displayLineItems = [];
        var parseDisplayLineItems = function () {
            $scope.displayLineItems.length = 0;

            $scope.mainItems = _.filter($scope.receipt, {level: 0});

            $scope.mainItemGroups = _.groupBy($scope.receipt, 'index');

            for (var mainItem of $scope.mainItems) {
                var mainItemGroup = $scope.mainItemGroups[mainItem.index];

                var elementIndex = 0;
                // display items/modifiers first
                for (var element of mainItemGroup) {
                    let lineItem;
                    if (element.level === 0) {
                        lineItem = {
                            type: 'main',
                            index: mainItem.index,
                            data: element,
                            uuid: mainItem._uuid + '---' + mainItem._timestamp
                        };
                    } else if (element.level === 1) {
                        if ((['discount', 'loyalty', 'attachment'].indexOf(element.subtype) === -1)
                            && !($scope.adjustments.percentDiscount
                                && mainItem.price < 0)) {

                            lineItem = {
                                type: 'modifier',
                                index: mainItem.index,
                                data: element,
                                uuid: mainItem._uuid + '---' + mainItem._timestamp + '---' + elementIndex,
                                parent: mainItem
                            };
                        }
                    }

                    if (lineItem) {
                        $scope.displayLineItems.push(lineItem);
                    }

                    elementIndex++;
                }

                // display item level discount (visualDiscount)
                var mainItemDiscountPrice = (mainItem.visualDiscount && mainItem.visualDiscount.price)
                    ? new Decimal(mainItem.visualDiscount.price)
                    : new Decimal(0);
                mainItemDiscountPrice = mainItemDiscountPrice.toNumber();

                if (mainItemDiscountPrice !== 0
                    && !$scope.adjustments.percentDiscount && !$scope.adjustments.dollarDiscount) {
                    var discountLineItem = {
                        type: 'discount',
                        index: mainItem.index,
                        data: mainItem, // TODO
                        uuid: mainItem._uuid + '---' + mainItem._timestamp + '---' + 'discount',
                        parent: mainItem
                    };
                    $scope.displayLineItems.push(discountLineItem);
                }

                // displayitem level
                if (mainItem.notes) {
                    var noteLineItem = {
                        type: 'note',
                        index: mainItem.index,
                        data: mainItem, // TODO
                        uuid: mainItem._uuid + '---' + mainItem._timestamp + '---' + 'notes',
                        parent: mainItem
                    };
                    $scope.displayLineItems.push(noteLineItem);
                }
            }
        };
        // ///////////////////////////////////////////////////////////////////////////
        // ///////////////////////////////////////////////////////////////////////////
        // //                                                                     ////
        // //                                                                     ////
        // //                                                                     ////
        // //                                                                     ////
        // //                                                                     ////
        // //                        Begin New Calculations                       ////
        // //                                                                     ////
        // //                                                                     ////
        // //                                                                     ////
        // //                                                                     ////
        // //                                                                     ////
        // ///////////////////////////////////////////////////////////////////////////
        // ///////////////////////////////////////////////////////////////////////////

        $scope.sortReceiptItems = function (sortType) {
            if ($scope.sortReceiptItemsType === sortType) {
                $scope.reverseReceiptItems = !$scope.reverseReceiptItems;
            }
            $scope.sortReceiptItemsType = sortType;
        };

        var createPosData = function () {
            return SharedFunctionService.createPosData($scope.receipt, $scope.order.patron, $scope.guestTransaction,
                $scope.shiftLocationId, $scope.currentOrderBalance, CurrentSession.getCompany().organization.settings,
                {lucovaUser: $scope.lucovaUser});
        };

        $scope.createPosData = createPosData;

        $scope.getUserPhoto = function (photoUrl) {
            return envConfig.lucovaHost + photoUrl;
        };
        $scope.shouldCancel = function () {
            if ($scope.receipt.length === 0) {
                $scope.cancel();
            } else {
                PosAlertService.showAlertByName('cancel-existing', {
                    'modalCallback': function () {
                        $scope.cancel();
                    }
                });
            }
        };
        function cancel () {
            resetReceipt();

            var calculated = SharedFunctionService.calculateBalances($scope.receipt, $scope.available, $scope.adjustments, $scope, $scope.patron);
            Controller.copyCalculations(calculated.payments, $scope.currentOrderBalance);
            $state.go('freshideas.posStart');
        }
        $scope.cancel = function () {

            var requestedPermission = 'pos:return_cancel_transactions';
            var managerOverrideRequired = Security.isManagerOverrideRequired(requestedPermission);
            if (managerOverrideRequired) {
                var params = {
                    callback: function (callback, message, actionName) {
                        // only get here if successful authentication
                        cancel();
                    },
                    requestedPermission: requestedPermission
                };

                $scope.$emit('PincodeAuthentication:Required', params);
            } else {
                cancel();
            }
        };

        $scope.setBuzzerCode = function (buzzerCode) {
            $scope.buzzerCode = buzzerCode;
        };

        $scope.clearBuzzerCode = function () {
            $scope.buzzerCode = null;
        };

        $scope.init();
    }
]).constant('PrintType', {
    MERCHANT: 'merchant',
    CUSTOMER: 'customer',
    ALL: 'all'
}).constant('PrintReceiptType', {
    TRANSACTION: 'transaction',
    REFUND: 'refund',
    GIFT: 'gift'
});

posModule.controller('SmbPosTransactionRePrintOptionsCtrl', SmbPosTransactionRePrintOptionsCtrl);

require('./pos.inventory.search.js')(posModule);
require('./pos.numpad.js')(posModule);
require('./pos.printer.status.js')(posModule);
require('./pos-modify/pos-modify.js')(posModule);
require('./pos-modify/pos-modify-price.js')(posModule);
require('./pos-category/pos-category.js')(posModule);
require('./refund/pos.transaction.refund.js')(posModule);
require('./refund/pos.transaction.exchange.js')(posModule);
require('./smb/pos.order.discount.js')(posModule);
require('./pos.tips.ctrl.js')(posModule);
require('./pos.suspend.ctrl.js')(posModule);
require('../common/controllers/scanForCardTerminals.ctrl.js')(posModule);
require('../common/controllers/addCardTerminal.ctrl.js')(posModule);
require('../common/controllers/giftCard.ctrl.js')(posModule);
require('../common/controllers/generic.list.js')(posModule);

require('./pos.cardterminal.summary.ctrl')(posModule);
require('./pos.tender.receipt.ctrl.js')(posModule);
require('./pos.tender.ctrl.js')(posModule);
require('./pos.meal.error.ctrl.js')(posModule);
require('./pos.meal.plan.ctrl.js')(posModule);
require('./pos.cashier.transaction.ctrl.js')(posModule);
require('./refund/pos.transaction.refund.tender.js')(posModule);
require('./pos.add.guestName.js')(posModule);
require('./pos.key.binding.js')(posModule);

export default posModule;

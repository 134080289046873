'use strict';


const angular = require('angular');
const Decimal = require('decimal.js').default;

module.exports = function (posModule) {

    posModule
        .controller('PosTransactionRefundCtrl', [
            '$scope',
            '$modal',
            '$log',
            '$modalInstance',
            '$translate',
            'patron',
            'isLucova',
            'transaction',
            'description',
            'mustRefundServicePeriod',
            'terminalResponses',
            'refundType',
            'CardTerminal',
            'CashierShift',
            'Locations',
            'Users',
            'Lucova',
            'PosAlertService',
            'SmbPosService',
            'PrintService',
            'PrintType',
            'Pure',
            'ReceiptBuilderService',
            'alteredTransactionItems',
            'CompanyAttributesService',
            'TerminalCommon',
            'GatewayFiit',
            'hasFiitMpsTender',
            'EnvConfig',
            'CurrentSession',
            'SolinkService',
            'SystemService',
            function (
                $scope,
                $modal,
                $log,
                $modalInstance,
                $translate,
                patron,
                isLucova,
                transaction,
                description,
                mustRefundServicePeriod,
                terminalResponse,
                refundType,
                CardTerminal,
                CashierShift,
                Locations,
                Users,
                Lucova,
                PosAlertService,
                SmbPosService,
                PrintService,
                PrintType,
                Pure,
                ReceiptBuilderService,
                alteredTransactionItems,
                CompanyAttributesService,
                TerminalCommon,
                GatewayFiit,
                hasFiitMpsTender,
                EnvConfig,
                CurrentSession,
                SolinkService,
                SystemService) {
                $scope.isRefundComplete = false;

                $scope.newRefundForm = {};

                $scope.refundType = refundType; // void/refund
                $scope.patron = patron;
                $scope.transaction = transaction;

                // Because this controller can be opened from different sources, need
                // to account for different cases of tenders
                $scope.refundTenders = [];
                for (var i = 0; i < $scope.transaction.tenders.length; i++) {
                    var tender = $scope.transaction.tenders[i];
                    if (tender.transactionTender) {
                        $scope.refundTenders.push(Object.assign({}, tender.transactionTender, tender));
                    } else {
                        $scope.refundTenders.push(tender);
                    }
                }

                $scope.mustRefundServicePeriod = mustRefundServicePeriod;
                $scope.origTerminalResponses = terminalResponse;
                $scope.mealPlanType = '';

                var cardTerminalResponsesArr = [];

                var TENDER_PROCESSING = $scope.TENDER_PROCESSING = {
                    ERROR: -1,
                    NOT_PROCESSED: 0,
                    PROCESSING: 1,
                    PROCESSED: 2
                };

                var refundTransactionUuid = Pure.generateUuid();

                $scope.isParentTransactionItem = function (item) {
                    return item.index === item.itemIndex;
                };

                $scope.isExchangeEnabled = function () {
                    return CompanyAttributesService.hasExchangeEnabled();
                };

                if ($scope.refundType === 'void') {
                    // if it's void, all items are altered transaction items
                    $scope.alteredTransactionItems = $scope.transaction.receipt;
                } else {
                    var updatedAlteredTransactionItems = [];
                    for (var itemToRefund of $scope.transaction.items) {
                        var foundItem = _.findWhere(alteredTransactionItems, {
                            receiptItemId: itemToRefund.transactionItemId
                        });

                        if (foundItem) {
                            foundItem.quantity = itemToRefund.quantity;
                            foundItem.price = new Decimal(foundItem.itemPrice).times(new Decimal(foundItem.quantity)).toDecimalPlaces(2).toNumber();
                            foundItem.taxAmount = new Decimal(foundItem.itemTaxAmount).times(new Decimal(foundItem.quantity)).toDecimalPlaces(2).toNumber();
                            foundItem.total = new Decimal(foundItem.itemTotalPrice).times(new Decimal(foundItem.quantity)).toDecimalPlaces(2).toNumber();

                            if (hasFiitMpsTender) {
                                foundItem.quantity = itemToRefund.quantity;
                                foundItem.price = new Decimal(itemToRefund.price).toNumber();
                                foundItem.taxAmount = new Decimal(itemToRefund.taxAmount).toNumber();
                                foundItem.total = new Decimal(itemToRefund.total).toNumber();
                            }


                            updatedAlteredTransactionItems.push(foundItem);
                        }
                    }
                    $scope.alteredTransactionItems = updatedAlteredTransactionItems;
                }

                var receiptMap = $scope.receiptMap = {};
                for (var receiptItem of $scope.transaction.receipt) {
                    receiptMap[receiptItem.locationServicePeriodMenuId] = receiptItem;
                }

                $scope.refund = {};
                $scope.initiateRefundObj = function () {
                    $scope.isRefundable = checkIfRefundable($scope.transaction);

                    var targets = findRefundTargets();

                    $scope.refund = {
                        transactionId: $scope.transaction.receiptId || $scope.transaction.transactionId,
                        cashierShiftId: $scope.transaction.cashierShiftId,
                        description: description,
                        toRefundServicePeriodBalance: true,
                        targets: targets
                    };

                    _.each(transaction.tenders, function (tender) {
                        var isAlreadyVoidedRefunded = tender.refundTransactionTenders && tender.refundTransactionTenders.length;

                        if (isAlreadyVoidedRefunded) {
                            tender.isProcessing = TENDER_PROCESSING.PROCESSED;
                        }

                        if (!tender.transactionTender && tender.transactionTenderId) {
                            tender.transactionTender = {
                                transactionTenderId: tender.transactionTenderId
                            };
                        }

                        if (!tender.patronMealPlan && tender.patronMealPlanId) {
                            tender.patronMealPlan = {
                                patronMealPlanId: tender.patronMealPlanId
                            };
                        }

                        if (!tender.patron && tender.patronId) {
                            tender.patron = {
                                patronId: tender.patronId
                            };
                        }

                        if (!tender.mealPlan && tender.mealPlanId) {
                            tender.mealPlan = {
                                mealPlanId: tender.mealPlanId
                            };
                        }

                        _parseTenderCreditCardNumber(tender);
                    });
                };

                var checkIfRefundable = function (transaction) {
                    var isRefundable = true;

                    var receiptItemMap = {};
                    _.each(transaction.receipt, function (receiptItem) {
                        var ignore = ['giftcard.create', 'giftcard.reload', 'loyalty.reload', 'loyalty.create'];
                        var isRefundable = !(ignore.indexOf(receiptItem.subtype) >= 0);
                        receiptItemMap[receiptItem.receiptItemId] = isRefundable;
                    });

                    _.each(transaction.items, function (itemToRefund) {
                        var transactionItemId = itemToRefund.transactionItemId;
                        if (!receiptItemMap[transactionItemId]) {
                            isRefundable = false;
                        }
                    });

                    _.each(transaction.tenders, function (tender) {
                        if (tender.transactionType === 'OTHER') {
                            if (tender.transactionTenderDetail && tender.transactionTenderDetail.otherType === 'alphapay') {
                                isRefundable = false;
                            }
                        }
                    });

                    return isRefundable;
                };

                var _parseTenderCreditCardNumber = function (tender) {
                    if (tender.transactionType !== 'CREDIT') {
                        return;
                    }

                    if (!tender.terminalResponse) {
                        tender._identifier = $translate.instant('smb.pos.tenderType.manualEntry');
                    } else {
                        // This assumes the card terminal type for each location doesn't change. If not,
                        // we will need to find a way to identify the card terminal type of each
                        // terminal response before it is is processed
                        return Locations.getLocationPrinters({'locationId': SmbPosService.shift.locationId})
                            .then(function (response) {
                                var terminalConfig = response.posStation.cardTerminalProperties;
                                var terminalType = terminalConfig.type;

                                TerminalCommon.init(terminalConfig);
                                var parsedResponse = CardTerminal.parseResponse(tender.terminalResponse, terminalType);
                                tender._parsedResponse = parsedResponse;
                                tender._identifier = parsedResponse.maskedCardNumber;
                            });
                    }
                };

                var findRefundTargets = function () {
                    var targets = [];

                    if ($scope.transaction.mealPlanIds && $scope.transaction.mealPlanIds.length) {
                        var mealPlanIds = $scope.transaction.mealPlanIds.split(',') || [];
                        // convert mealPlanIds from String array to int array
                        mealPlanIds = _.map(mealPlanIds, function (mealPlanId) {
                            return parseInt(mealPlanId);
                        });

                        var targetPatronMealPlan = _.find(patron.mealPlans, function (mealPlan) {
                            return mealPlanIds.indexOf(mealPlan.mealPlanId) > -1;
                        });

                        var amountRefund = $scope.transaction.mealPlansRedeemed || $scope.transaction.mealPlanCount;

                        var target = {
                            targetPatronMealPlan: targetPatronMealPlan,
                            amountRefund: amountRefund
                        };
                        targets.push(target);
                    }

                    if ($scope.transaction.dcbMealPlanIds && $scope.transaction.dcbMealPlanIds.length) {
                        var dcbMealPlanIds = $scope.transaction.dcbMealPlanIds.split(',');
                        var dcbMealPlanId = parseInt(dcbMealPlanIds[0]);

                        var targetPatronMealPlan2 = _.findWhere($scope.patron.mealPlans, {mealPlanId: dcbMealPlanId});
                        var amountRefund2 = $scope.transaction.dcbAmount;

                        var target2 = {
                            targetPatronMealPlan: targetPatronMealPlan2,
                            amountRefund: amountRefund2
                        };
                        targets.push(target2);
                    }

                    return targets;
                };

                var STAGES = $scope.STAGES = {
                    INITIAL: {
                        name: 'initial',
                        showProgress: false
                    },
                    LUCOVA: {
                        name: 'lucova',
                        showProgress: true
                    },
                    TERMINAL: {
                        name: 'terminal',
                        showProgress: true
                    },
                    POS: {
                        name: 'pos',
                        showProgress: true
                    },
                    SUCCESS: {
                        name: 'success',
                        showProgress: false
                    },
                    FAIL: {
                        name: 'fail',
                        showProgress: false
                    },
                };

                $scope.currentStage = {};
                $scope.setCurrentStage = function (stage, data) {
                    var currentStage = angular.copy(stage);

                    if (data) {
                        currentStage.data = data;
                    }
                    $scope.currentStage = currentStage;
                };

                var refundLucovaTransaction = function (transaction) {
                    return (isLucova) ?
                        executeLucovaRefund() :
                        Promise.resolve();
                };
                var executeLucovaRefund = function () {
                    // TODO: update parameters based on actual Lucova backend implementation
                    $scope.setCurrentStage(STAGES.LUCOVA);

                    return Lucova.manager().refundUserTransaction({
                        transaction_id: transaction.transactionId,
                    }, {}).$promise;
                };

                var refundTransactionTenders = function (transaction, pinObj) {
                    var transactionTenderPromises = Promise.resolve();

                    _.each(transaction.tenders, function (tender) {
                        var isCreditDebit = tender.transactionType === 'CREDIT' || tender.transactionType === 'DEBIT';
                        var hasTerminalResponse = tender.terminalResponse;
                        var isAlreadyVoidedRefunded = tender.refundTransactionTenders && tender.refundTransactionTenders.length;

                        if (isCreditDebit && hasTerminalResponse && !isAlreadyVoidedRefunded) {
                            transactionTenderPromises = transactionTenderPromises
                                .then(function () {
                                    return executeCreditDebitRefund(tender, transaction, pinObj);
                                });
                        } else {
                            transactionTenderPromises = transactionTenderPromises.then(function () {
                                // tender.isProcessing = TENDER_PROCESSING.PROCESSED;
                                return Promise.resolve({});
                            });
                        }
                    });

                    return transactionTenderPromises;
                };

                var executeCreditDebitRefund = function (tender, transaction, pinObj) {
                    $scope.$apply(function () {
                        $scope.setCurrentStage(STAGES.TERMINAL);
                    });

                    tender.isProcessing = TENDER_PROCESSING.PROCESSING;

                    return refundCreditCardOnTerminal(tender, transaction).then(function (terminalResponse) {
                        return markCreditCardTenderRefunded(tender, transaction, terminalResponse, pinObj);
                    }).catch(function (exception) {
                        return Promise.reject({});
                    });
                };

                var retryRefundOrVoidOnTerminal = function (retryDetails, tender, transaction) {
                    let modalInstance = $modal.open({
                        templateUrl: 'pos/refund/pos.transaction.refund.card.retry.tpl.html',
                        controller: 'PosTransactionRefundRetryCtrl',
                        windowClass: 'smb-pos smb-pos__refund',
                        animation: false,
                        backdrop: 'static',
                        resolve: {
                            tender: function () {
                                return tender;
                            },
                            retryDetails: function () {
                                return retryDetails;
                            },
                            transaction: function () {
                                return transaction;
                            }
                        },
                    });

                    return modalInstance.result.then(function (data) {
                        return data.response;
                    }, function (err) {
                        $log.error('error while retrying refund or void', err);
                        throw err;
                    });

                };

                var refundCreditCardOnTerminal = function (tender, transaction) {
                    var modalInstance = $modal.open({
                        templateUrl: 'pos/refund/pos.transaction.refund.card-processing.tpl.html',
                        controller: 'PosTransactionRefundProcessingCtrl',
                        windowClass: 'smb-pos smb-pos__refund',
                        animation: false,
                        backdrop: 'static',
                        resolve: {
                            tender: function () {
                                return tender;
                            },
                            refundType: function () {
                                return $scope.refundType;
                            }
                        }
                    });

                    return Locations.getLocationPrinters({'locationId': SmbPosService.shift.locationId})
                        .then(function (response) {
                            var terminalConfig = response.posStation.cardTerminalProperties;
                            var terminal = CardTerminal.init(terminalConfig);
                            var terminalResponse = terminal.parseResponse(
                                JSON.parse(tender.terminalResponse));
                            var transactionId = terminalResponse.transactionId;

                            var terminalAction;
                            if ($scope.refundType === 'void') {
                                terminalAction = terminal.creditVoid(transactionId, {tenderToVoid: tender, transaction: transaction});
                            } else {
                                var amountToRefund = tender.amountCents / 100; // parseFloat(terminalResponse.approvedAmount);
                                terminalAction = terminal.creditReturn(amountToRefund, {tenderToReturn: tender, transactionId: transactionId, transaction: transaction});
                            }

                            return terminalAction.then(function (voidResponse) {
                                modalInstance.close();
                                cardTerminalResponsesArr.push(voidResponse[0]);
                                return voidResponse;
                            }, function (exception) {
                                $log.error('[refundCreditCardOnTerminal] Error occured while voiding: ', exception);
                                modalInstance.close();
                                exception = exception || {};

                                let isNotEmpty = Object.keys(exception).length > 1;
                                // if not multi part transaction throw exception
                                // or else retry transaction and notify user
                                // with instructions to complete transaction
                                if (isNotEmpty && exception.multiPart) {
                                    exception.terminalConfig = terminalConfig;
                                    exception.refundType = $scope.refundType;
                                    cardTerminalResponsesArr.push(exception.data[0]);

                                    return retryRefundOrVoidOnTerminal(exception, tender, transaction).then(function (response) {
                                        cardTerminalResponsesArr.push(response[0]);
                                        return response;
                                    }).catch(function (err) {
                                        // tender.isProcessing = TENDER_PROCESSING.ERROR;
                                        // var failMessageName = ($scope.refundType === 'void') ? 'transaction-void-fail' : 'transaction-refund-fail';
                                        // var failMessage = ($scope.refundType === 'void') ? 'transactions.void.fail.terminal' : 'transactions.refund.fail.terminal';

                                        // PosAlertService.showAlertByName(failMessageName, {
                                        //     message: failMessage
                                        // });

                                        // $scope.setCurrentStage(STAGES.FAIL, exception);
                                        // throw err;
                                        $log.error(err);
                                        return Promise.reject(err);
                                    });
                                }

                                tender.isProcessing = TENDER_PROCESSING.ERROR;

                                var failMessageName = ($scope.refundType === 'void') ? 'transaction-void-fail' : 'transaction-refund-fail';
                                var failMessage = ($scope.refundType === 'void') ? 'transactions.void.fail.terminal' : 'transactions.refund.fail.terminal';
                                PosAlertService.showAlertByName(failMessageName, {
                                    message: failMessage
                                });

                                $scope.setCurrentStage(STAGES.FAIL, exception);
                                return Promise.reject(exception);
                            });
                        }).catch(function (error) {
                            modalInstance.close();
                            return Promise.reject(error);
                        });
                };
                var markCreditCardTenderRefunded = function (tender, transaction, voidResponse = [{}, {}], pinObj) {
                    var refundTender = {};
                    refundTender.transactionType = tender.transactionType;
                    refundTender.amountCents = tender.amountCents;
                    refundTender.amountMeals = tender.amountMeals;
                    refundTender.giftCardId = tender.amountCents;

                    var receiptFields = voidResponse[0];
                    var terminalResponses = voidResponse[1];

                    refundTender.terminalResponse = JSON.stringify(terminalResponses);
                    refundTender.creditCardName = receiptFields.cardName;

                    if (tender.transactionType === 'CREDIT' && tender.terminalResponse) {
                        var originalCardIdentifier = tender._identifier || '';
                        originalCardIdentifier = originalCardIdentifier.replace(/\*/g, '');

                        var refundCardIdentifier = receiptFields.maskedCardNumber || '';
                        refundCardIdentifier = refundCardIdentifier.replace(/\*/g, '');

                        if (originalCardIdentifier != refundCardIdentifier) {
                            refundTender.tenderMismatch = true;
                        } else {
                            refundTender.tenderMismatch = false;
                        }
                    }

                    var tenderCopy = angular.copy(tender);
                    delete tenderCopy.isProcessing;
                    refundTender.transactionTender = tenderCopy.transactionTender;

                    var refund = {
                        refundTransactionUuid: refundTransactionUuid,
                        cashierShiftId: $scope.transaction.cashierShiftId,
                        transactionId: $scope.refund.transactionId,
                        description: $scope.refund.description,
                        toRefundServicePeriodBalance: $scope.mustRefundServicePeriod || $scope.refund.toRefundServicePeriodBalance,
                        refundType: $scope.refundType.toUpperCase(),
                        totalRoundingCents: $scope.transaction.refundAmountRoundingCents,
                        tenders: [refundTender],
                        locationId: SmbPosService.shift.locationId,
                        menuPeriodId: $scope.transaction.menuPeriodId,
                        quickChargeSessionId: $scope.transaction.quickChargeSessionId,
                        menuId: $scope.transaction.menuId
                    };

                    if ($scope.refundType === 'refund' || $scope.refundType === 'refund_partial') {
                        refund.items = $scope.transaction.items;
                    }

                    if (pinObj && pinObj.user && pinObj.user.userId) {
                        refund.userId = pinObj.user.userId;
                    }

                    return CashierShift.refundTransactionByTender({}, refund, function (response) {
                        tender.isProcessing = TENDER_PROCESSING.PROCESSED;

                        // Return tenderMismatch for use with user activity logging purposes
                        return Object.assign(response, {
                            tenderMismatch: refundTender.tenderMismatch
                        });
                    }, function (exception) {
                        exception = exception || {};

                        tender.isProcessing = TENDER_PROCESSING.ERROR;
                        throw exception;
                    }).$promise;
                };

                var postFiitRefundTransaction = function (transaction, pinObj) {
                    var fiitLocation = GatewayFiit.getLocation();
                    if (!fiitLocation || !fiitLocation.locationId) {
                        // throw error
                    }

                    var tenders = transaction.tenders || [];
                    var hasFiitTender = !!(transaction.fiitDcbAmount > 0 || transaction.fiitMealDollarAmount > 0 || transaction.fiitMealPlanCount > 0);

                    var fiitMpsResponse;
                    var payments = [];
                    if (hasFiitTender) {
                        var fiitTender = tenders.find((nownTender) => nownTender.transactionTenderDetail
                            && nownTender.transactionTenderDetail.otherType == 'fiitmps');

                        if (fiitTender) {
                            var currentTenderDetail = fiitTender.transactionTenderDetail || {};
                            fiitMpsResponse = JSON.parse(currentTenderDetail.otherResponse);
                            payments = fiitMpsResponse.payments || [];
                        }
                    }


                    var params = {
                        description: $scope.refund.description,
                        toRefundServicePeriodBalance: true,
                        transactionUuid: transaction.transactionUuid,
                        fiitLocationId: fiitLocation.locationId,
                        paymentsToRefund: payments,
                        nownRefund: true // This flag is very necessary as we need to use a custom refund function on the fiit backend
                    };

                    var modalInstance = $modal.open({
                        templateUrl: 'pos/refund/pos.transaction.refund.fiitmps-processing.tpl.html',
                        controller: 'PosTransactionRefundProcessingCtrl',
                        windowClass: 'smb-pos smb-pos__refund',
                        animation: false,
                        backdrop: 'static',
                        resolve: {
                            tender: function () {
                                return tender;
                            },
                            refundType: function () {
                                return $scope.refundType;
                            }
                        }
                    });

                    var promise;
                    if ($scope.refundType === 'void') {
                        promise = GatewayFiit.void(params);
                    } else {
                        promise = GatewayFiit.refund(params);
                    }

                    return promise.then(function (response) {
                        modalInstance.close();
                    }, function (exception) {
                        modalInstance.close();
                        exception = exception || {};
                        var data = exception.data || {};
                        var failMessage = data.error || '';

                        if (!failMessage) {
                            failMessage = ($scope.refundType === 'void') ? 'transactions.void.fail.fiit' : 'transactions.refund.fail.fiit';
                        }

                        $log.error({
                            message: failMessage,
                            context: {
                                error: exception,
                                user: (CurrentSession.getUser()) ? CurrentSession.getUser().username : '',
                                isNownFiit: true
                            }
                        });
                    });
                };

                var finalizeRefundTransaction = function (originalResponse, pinObj) {
                    $scope.setCurrentStage(STAGES.POS);

                    var refund = {
                        refundTransactionUuid: refundTransactionUuid,
                        cashierShiftId: $scope.transaction.cashierShiftId,
                        transactionId: $scope.refund.transactionId,
                        description: $scope.refund.description,
                        toRefundServicePeriodBalance: $scope.mustRefundServicePeriod || $scope.refund.toRefundServicePeriodBalance,
                        refundType: $scope.refundType.toUpperCase(),
                        totalRoundingCents: $scope.transaction.refundAmountRoundingCents,
                        locationId: SmbPosService.shift.locationId,
                        shippingPriceCents: ($scope.transaction.deliveryFee || 0) * 100,
                        shippingTaxCents: ($scope.transaction.deliveryTax || 0) * 100,
                        quickChargeSessionId: $scope.transaction.quickChargeSessionId,
                        menuPeriodId: $scope.transaction.menuPeriodId,
                        menuId: $scope.transaction.menuId
                    };

                    if ($scope.refundType === 'refund' || $scope.refundType === 'refund_partial') {
                        refund.items = $scope.transaction.items;
                        refund.tenders = _.filter($scope.transaction.tenders, function (tender) {
                            return tender.isProcessing !== TENDER_PROCESSING.PROCESSED;
                        });
                    }

                    if ($scope.refundType === 'void') {
                        // refund.tenders = $scope.transaction.tenders;
                        refund.tenders = _.filter($scope.transaction.tenders, function (tender) {
                            return tender.isProcessing !== TENDER_PROCESSING.PROCESSED;
                        });
                    }

                    if (pinObj && pinObj.user && pinObj.user.userId) {
                        refund.userId = pinObj.user.userId;
                    }

                    return CashierShift.refundTransaction({}, refund,
                        function (response) {
                            // Determine user activity type and user activity details for logging
                            // User activity type
                            var userActivityType;
                            if ($scope.refundType === 'refund' || $scope.refundType === 'refund_partial') {
                                userActivityType = 'POS__REFUND_TRANSACTION';
                            } else if ($scope.refundType === 'void') {
                                userActivityType = 'POS__VOID_TRANSACTION';
                            }

                            // User activity details
                            var userActivityDetails = [];
                            if (response.cashAmount > 0) {
                                userActivityDetails.push({
                                    attribute: 'cashAmount',
                                    value: response.cashAmount
                                });
                            } else if (response.creditCardAmount > 0) {
                                userActivityDetails.push({
                                    attribute: 'creditCardAmount',
                                    value: response.creditCardAmount
                                });
                                userActivityDetails.push({
                                    attribute: 'tenderMismatch',
                                    value: originalResponse.tenderMismatch
                                });
                            }

                            // Log void transaction
                            Users.logUserActivity({}, {
                                locationId: SmbPosService.shift.locationId,
                                userId: pinObj.user.userId,
                                userActivityType: userActivityType,
                                userActivityDetails: userActivityDetails
                            });

                            $scope.setCurrentStage(STAGES.SUCCESS);
                            var transactionObj = angular.copy(transaction);
                            transactionObj.posPrinters = response.posStation.posPrinters || [];

                            transactionObj.transactionType = response.refundStatus;
                            transactionObj.userFullName = response.userFullName;
                            transactionObj.refundTransactions = response.refundTransactions;
                            var cardReceiptArr = [];
                            if ($scope.origTerminalResponses) {
                                cardReceiptArr = ReceiptBuilderService.buildCardReceipt($scope.origTerminalResponses);
                            }

                            cardReceiptArr = cardReceiptArr.concat(cardTerminalResponsesArr);

                            if (EnvConfig.env !== 'test') {
                                PrintService.printReceipt(
                                    transactionObj,
                                    transactionObj.receipt,
                                    cardReceiptArr,
                                    false,
                                    PrintType.ALL);
                            }

                            $scope.isRefundComplete = true;
                            // var successMessageName = ($scope.refundType === 'void')
                            //     ? 'transaction-void-success' : 'transaction-refund-success';
                            // PosAlertService.showAlertByName(successMessageName, {
                            //     modalCallback: function () {
                            //         $scope.$close();
                            //     }
                            // });

                            if (EnvConfig.env !== 'test') {
                                SmbPosService.openDrawer();
                            }

                            try {
                                $scope.alteredTransactionItems.forEach((item) => {
                                    item.solinkItemTimes = [Date.now() - SystemService.getSystemTimeOffsetMilliseconds()];
                                });
                                if ($scope.refundType === 'refund' || $scope.refundType === 'refund_partial') {
                                    sendRefundToSolink($scope.transaction);
                                } else if ($scope.refundType === 'void') {
                                    sendVoidToSolink($scope.transaction);
                                }
                            } catch (err) {
                                $log.error('Failed to send event to Solink', err);
                            }
                        }, function (error) {
                            var errorData = error.data || {};
                            var exception = errorData.exception || {};

                            $scope.setCurrentStage(STAGES.FAIL, exception);

                            var failMessageName = ($scope.refundType === 'void')
                                ? 'transaction-void-fail' : 'transaction-refund-fail';
                            PosAlertService.showAlertByName(failMessageName, {
                                message: exception.message
                            });
                        }).$promise;
                };

                const getSolinkTenders = (tenders) => {
                    let solinkTenders = [];
                    let amountDollars;
                    Array.prototype.forEach.call(tenders, (tender) => {
                        try {
                            amountDollars = tender.amountCents / 100;
                        } catch (e) {
                            amountDollars = 0.0;
                        }
                        solinkTenders.push({
                            type: 'payment',
                            time: Date.now() - SystemService.getSystemTimeOffsetMilliseconds(),
                            quantity: 1.0,
                            description: tender.transactionType,
                            unitPrice: amountDollars,
                        });
                    });
                    return solinkTenders;
                };

                const sendRefundToSolink = (transactionObj) => {
                    const solinkTenders = getSolinkTenders(transactionObj.tenders);
                    SolinkService.sendRefundTransaction(
                        Pure.generateUuid(), // Don't overwrite the original transaction
                        $scope.alteredTransactionItems,
                        {
                            receiptNumber: transactionObj.transactionId,
                            tenders: solinkTenders,
                            tax: {
                                amount: transactionObj.transactionTax,
                                timeApplied: Date.now() - SystemService.getSystemTimeOffsetMilliseconds(),
                            },
                            cashRounding: {
                                amount: transactionObj.cashRounding,
                                timeApplied: Date.now() - SystemService.getSystemTimeOffsetMilliseconds(),
                            },
                        });
                };

                const sendVoidToSolink = (transactionObj) => {
                    const solinkTenders = getSolinkTenders(transactionObj.tenders);
                    SolinkService.sendVoidTransaction(
                        Pure.generateUuid(), // Don't overwrite the original transaction
                        $scope.alteredTransactionItems,
                        {
                            receiptNumber: transactionObj.transactionId,
                            tenders: solinkTenders,
                            tax: {
                                amount: transactionObj.transactionTax,
                                timeApplied: Date.now() - SystemService.getSystemTimeOffsetMilliseconds(),
                            },
                            cashRounding: {
                                amount: transactionObj.cashRounding,
                                timeApplied: Date.now() - SystemService.getSystemTimeOffsetMilliseconds(),
                            },
                        });
                };

                $scope.openExchangesModal = function () {
                    var requestedPermission = 'pos:refunds';
                    var params = {
                        callback: function (pinObj) {
                            var modalInstance = $modal.open({
                                templateUrl: 'pos/refund/pos.transaction.exchange.tpl.html',
                                controller: 'PosTransactionExchangeCtrl',
                                windowClass: 'smb-pos smb-pos__void-modal smb-pos__exchanges-modal',
                                animation: false,
                                backdrop: 'static',
                                resolve: {
                                    transaction: function () {
                                        return $scope.transaction;
                                    },
                                    refundTransaction: function () {
                                        return generateRefundObjForExchange();
                                    },
                                    refundTenders: function () {
                                        return $scope.refundTenders;
                                    },
                                    alteredTransactionItems: function () {
                                        return $scope.alteredTransactionItems;
                                    },
                                    userObj: function () {
                                        return pinObj;
                                    }
                                }
                            });

                            modalInstance.result.then(function (resolved) {
                                sendRefundToSolink($scope.transaction);
                                $scope.$close();
                            }, function (dismissed) {
                                $scope.$close();
                            });
                        },
                        errorCallback: function (error) {
                            if (error) {
                                var data = error.data || {};
                                var exception = data.exception || {};

                                PosAlertService.showAlertByName('pincode-fail', {
                                    message: exception.message || ''
                                });
                            }
                        },
                        requestedPermission: requestedPermission
                    };

                    $scope.$emit('PincodeAuthentication:Required', params);
                };

                var generateRefundObjForExchange = function () {
                    var refund = {
                        refundTransactionUuid: refundTransactionUuid,
                        cashierShiftId: $scope.transaction.cashierShiftId,
                        transactionId: $scope.refund.transactionId,
                        description: $scope.refund.description,
                        toRefundServicePeriodBalance: $scope.mustRefundServicePeriod || $scope.refund.toRefundServicePeriodBalance,
                        refundType: $scope.refundType.toUpperCase(),
                        totalRoundingCents: $scope.transaction.refundAmountRoundingCents,
                        locationId: SmbPosService.shift.locationId,
                        menuPeriodId: $scope.transaction.menuPeriodId,
                        quickChargeSessionId: $scope.transaction.quickChargeSessionId,
                        menuId: $scope.transaction.menuId
                    };

                    if ($scope.refundType === 'refund' || $scope.refundType === 'refund_partial') {
                        refund.items = $scope.transaction.items;
                        refund.tenders = _.filter($scope.transaction.tenders, function (tender) {
                            return tender.isProcessing !== TENDER_PROCESSING.PROCESSED;
                        });
                    }

                    if ($scope.refundType === 'void') {
                        // refund.tenders = $scope.transaction.tenders;
                        refund.tenders = _.filter($scope.transaction.tenders, function (tender) {
                            return tender.isProcessing !== TENDER_PROCESSING.PROCESSED;
                        });
                    }

                    /* if (pinObj && pinObj.user && pinObj.user.userId) {
                        refund.userId = pinObj.user.userId;
                    } */

                    return refund;
                };

                $scope.refundTransaction = function () {
                    var requestedPermission = 'pos:refunds';
                    var params = {
                        callback: function (pinObj) {
                            refundLucovaTransaction()
                                .then(function () {
                                    return refundTransactionTenders(transaction, pinObj);
                                })
                                .then(function (response) {
                                    return finalizeRefundTransaction(response, pinObj);
                                })
                                .then(function (response) {
                                    if (GatewayFiit.isEnabled() && transaction.status === 'COMPLETE') {
                                        return postFiitRefundTransaction(transaction, pinObj);
                                    }
                                });
                        },
                        errorCallback: function (error) {
                            if (error) {
                                var data = error.data || {};
                                var exception = data.exception || {};

                                PosAlertService.showAlertByName('pincode-fail', {
                                    message: exception.message || ''
                                });
                            }
                        },
                        requestedPermission: requestedPermission,
                    };

                    $scope.$emit('PincodeAuthentication:Required', params);

                };

                $scope.initiateRefundObj();
            }
        ])
        .value('description', '')
        .value('terminalResponses', undefined);
};

'use strict';

module.exports = function (freshideasProducts) {
    freshideasProducts.factory('MenuService', [
        '$q',
        'CurrentSession',
        'Menu',
        'Locations',
        '$translate',
        'MENU_ITEM_TYPE',
        'Pure',
        function (
            $q,
            CurrentSession,
            Menu,
            Locations,
            $translate,
            MENU_ITEM_TYPE,
            Pure) {
            const Decimal = require('decimal.js').default;

            const organizationLanguages = [];
            let locationPrintersByReceiptType = {
                tenderReceiptPrinterList: [],
                secondaryReceiptPrinterList: [],
                labelReceiptPrinterList: []
            };
            const isRootCompany = CurrentSession.isRootCompany();

            let requestQueue = [];

            // Sanitize items to ensure we are passing correct MenuV2 structure to backend
            // and does not contain unnecessary and/or unexpected fields
            const sanitizeItem = (item) => {
                const sanitizedItem = {
                    menuItemId: item.menuItemId || null,
                    itemDetails: item.itemDetails || {},
                    organizationId: item.organizationId,
                    integrations: item.integrations || [],
                    companyVariations: item.companyVariations || [],
                    menuVariations: item.menuVariations || []
                };

                return sanitizedItem;
            };

            const loadAvailableMenusByOrganization = () => {
                const organizationId = CurrentSession.getCompany().organizationId;
                return Locations.getMenusByOrganization({'organizationId': organizationId}).$promise;
            };

            const loadAvailableMenusByCompany = (companyId) => {
                return Menu.activeMenusForCompany({companyId: companyId, inActive: isRootCompany}).$promise;
            };

            // fetch the menu name
            // item.menuVariations array does not contain a menu name, so this is used to
            // fetch it from the organization's available menus.
            const getMenuName = (menuId, menus) => {
                const foundMenu = menus.find((menu) => {
                    return menu.menuId === menuId;
                });

                return foundMenu ? foundMenu.menuName : 'Menu';
            };

            const addAssociations = (params) => {
                // params object includes = {
                //     itemToSave, menuId, parentMap (optional), childMap (optional), parentItemId, childIds
                // }
                // parentMap and childMap can be used to directly set the orderBy's
                // e.g. in the case of modifier removals, we'll need to set orderBy as -1
                let listOfItemsToSave = [];

                params.itemToSave.forEach(function (item) {
                    let parentItemsAssociationMap = {};
                    if (!item.parentMap) {
                        if (item.parentItemId) {
                            parentItemsAssociationMap[item.parentItemId] = null;
                        }
                    } else {
                        parentItemsAssociationMap = item.parentMap;
                    }

                    const childItemsAssociationMap = {};
                    if (!item.childMap) {
                        if (item.childIds && item.childIds.length) {
                            item.childIds.forEach((childId) => {
                                childItemsAssociationMap[childId] = null;
                            });
                        }
                    }

                    const param = {
                        itemId: item.menuItemId || item.locationServicePeriodMenuId,
                        menuId: params.menuId || item.menuId,
                        parentItemsAssociationMap: item.parentMap || parentItemsAssociationMap,
                        childItemsAssociationMap: item.childMap || childItemsAssociationMap
                    };

                    listOfItemsToSave.push(param);
                });

                return Menu.associateItems({
                    idempotencyUuid: Pure.generateUuid(),
                    listOfItemsToSave: listOfItemsToSave,
                }).$promise;
            };

            const setMenuVariations = (item, menus) => {
                // only applies for new items
                // as only new items can set multiple variations for 'active' field.
                if (item.locationServicePeriodMenuId) {
                    return;
                }

                item.menuVariations = menus.all.map((menu) => {
                    return {
                        menuId: menu.menuId,
                        active: item.active || true,
                        posEnabled: item.posEnabled || true,
                        mobileEnabled: item.mobileEnabled || true,
                        associatedCompanyIds: menu.associatedCompanyIds || []
                    };
                });
            };

            const setTranslations = (item, organizationLanguages) => {
                item.itemDetails = {
                    translations: []
                };

                // map all available languages to itemDetails.translations array.
                item.itemDetails.translations = organizationLanguages.map((language) => {
                    let translation = {
                        languageId: language.languageId,
                        itemName: '',
                        description: '',
                        displayName: ''
                    };

                    // if this is an existing item and the item.translations hashmap already has
                    // a translation available for the current language
                    if (item.locationServicePeriodMenuId && item.translations.hasOwnProperty(language.languageId)) {
                        return item.translations[language.languageId];
                    } else {
                        return translation;
                    }
                });
            };

            const setLanguageImageMap = (item, organizationLanguages) => {
                // create a languageImageMap if item doesn't already have one.
                if (!item.languageImageMap) {
                    item.languageImageMap = {};
                }

                organizationLanguages.forEach((language) => {
                    // if the language doesn't already exist in the map,
                    // we need to add some properties.
                    if (!item.languageImageMap.hasOwnProperty(language.languageId)) {
                        let imageObj = {
                            compressedImage: null,
                            isImageCompressing: false,
                            queueIndex: null
                        };

                        item.languageImageMap[language.languageId] = imageObj;
                    }
                });
            };

            const setAssociatedMenuNames = (item) => {
                if (item.associatedMenus) {
                    item.associatedMenuNames = Object.keys(item.associatedMenus).map((key) => {
                        return item.associatedMenus[key];
                    });
                }
            };

            const setMenuAssociations = (menus) => {
                return menus.all.map((menu) => {
                    const liteMenu = {
                        menuId: menu.menuId,
                        menuName: menu.menuName,
                        priceCents: 0,
                        associated: true,
                        associatedCompanyIds: menu.associatedCompanyIds || []
                    };
                    return liteMenu;
                });
            };

            // used to assign a printer to an item
            const assignPrinterToItem = (item, menus) => {
                // creating a new item from the transformed item as we only need to send in the company variations object.
                // ideally updateMenuItem should be comparing original item vs. new item so this isn't necessary.
                let menuIds = [menus.selected.menuId];

                let companyIdsObj = {};
                let associatedCompanyIds = menus.selected.associatedCompanyIds || [];
                for (let companyId of associatedCompanyIds) {
                    companyIdsObj[companyId] = 1;
                }

                let companyIds = Object.keys(companyIdsObj);

                const transformedItem = transformItem(item, menuIds, companyIds);
                const payload = {
                    menuItemId: transformedItem.menuItemId,
                    organizationId: transformedItem.organizationId,
                    companyVariations: transformedItem.companyVariations
                };
                return Menu.updateMenuItem(payload).$promise;
            };

            const _transformPrinters = function (printerList) {
                for (const printer of printerList) {
                    const printerName = (printer.printerName || '').trim();
                    const modelName = (printer.bluetoothName)
                        ? printer.bluetoothName
                        : printer.printerModel || '';
                    let displayName;
                    if (printerName + modelName === '') {
                        displayName = printer.ipAddress + ':' + printer.port;
                    } else {
                        let displayNameArray = [printerName, modelName].filter((name) => {
                            return name !== '';
                        });
                        displayName = displayNameArray.join(' - ');
                    }
                    printer._displayName = displayName;
                }

                return printerList;
            };

            const loadLocationPrintersByReceiptType = function (type) {
                return Locations.getPrintersMapByReceiptType({'locationId': CurrentSession.getCompany().locationId}).then((printersMap) => {
                    const tenderReceiptPrinterList = _transformPrinters(printersMap.get('receipt-type__tender'));
                    const secondaryReceiptPrinterList = _transformPrinters(printersMap.get('receipt-type__secondary'));
                    const labelReceiptPrinterList = _transformPrinters(printersMap.get('receipt-type__tender'));

                    locationPrintersByReceiptType.tenderReceiptPrinterList = tenderReceiptPrinterList;
                    locationPrintersByReceiptType.secondaryReceiptPrinterList = secondaryReceiptPrinterList;
                    locationPrintersByReceiptType.labelReceiptPrinterList = labelReceiptPrinterList;
                });
            };

            const getLocationPrintersByReceiptType = function (type) {
                let printersByType = [];

                switch (type) {
                    case 'tender':
                        printersByType = locationPrintersByReceiptType.tenderReceiptPrinterList;
                    break;
                    case 'secondary':
                        printersByType = locationPrintersByReceiptType.secondaryReceiptPrinterList;
                    break;
                    case 'label':
                        printersByType = locationPrintersByReceiptType.labelReceiptPrinterList;
                    break;
                    default:
                }

                return printersByType;
            };

            // used to transform the item to menu v2 structure before sending to the backend.
            const transformItem = (item, menuIds, companyIds) => {
                let transformedItem = {
                    menuItemId: item.locationServicePeriodMenuId || '',
                    organizationId: CurrentSession.getCompany().organizationId,
                    integrations: [],
                    companyVariations: [],
                    menuVariations: []
                };

                let priceReplacedByItemId = null;
                if (item.priceReplacedBy) {
                    priceReplacedByItemId = item.priceReplacedBy.locationServicePeriodMenuId;
                }

                transformedItem.itemDetails = {
                    subtype: item.subtype,
                    tags: item.tagNames || [],
                    tagsToRemove: item.itemTagsToRemove || [],
                    totalTime: item.totalTime,
                    trackStockEnabled: item.trackStockEnabled,
                    translations: item.itemDetails.translations,
                    type: item.type,
                    upc: item.upc,
                    taxRules: item.taxRules || {},
                    calories: item.calories
                };

                // Commented By Akash Mehta on Oct 13 2021
                // For now We don't need location level settings for category and modifier groups
                // Location level settings include
                if (item.type !== MENU_ITEM_TYPE.CATEGORY && item.type !== MENU_ITEM_TYPE.MODIFIER) {
                    companyIds.forEach((companyId) => {
                        if (companyId) {
                            let companyVariation = {
                                // variationId: '',
                                companyId: companyId,
                                itemId: item.locationServicePeriodMenuId,
                                printOnCustomerReceipt: !!item.printOnCustomerReceipt,
                                printOnKitchenSheet: !!item.printOnKitchenSheet,
                                noModifierNoKitchenPrint: !!item.noModifierNoKitchenPrint,
                                unitCost: item.unitCost,
                                currentQuantity: item.currentQuantity,
                                targetQuantity: item.targetQuantity,
                                available: item.available,
                                allowBackorders: item.allowBackorders
                            };

                            if (companyId == CurrentSession.getCompany().companyId) {
                                companyVariation.printerId = item.printerId;
                            }

                            transformedItem.companyVariations.push(companyVariation);
                        }
                    });
                }

                // new vs. existing items handle menu variations slightly differently.
                menuIds.forEach((menuId) => {
                    if (menuId) {
                        let menuVariations = {
                            menuId: menuId,
                            itemId: item.locationServicePeriodMenuId,
                            active: item.active,
                            color: item.color,
                            mobileEnabled: item.mobileEnabled,
                            mobileUsersOnly: !!item.mobileUsersOnly,
                            posEnabled: item.posEnabled,
                            thirdPartyPriceCents: ((item.third_party_price || 0) * 100),
                            loyaltyEnabled: item.loyaltyEnabled,
                            mealPlanEligible: item.mealPlanEligible,
                            mealEquivalencyEnabled: item.mealEquivalencyEnabled,
                            itemPriceEditable: item.itemPriceEditable,
                            kdsEnabled: item.kdsEnabled,
                            priceCents: new Decimal(item.price || 0).times(100),
                            allowMultipleSelection: item.allowMultipleSelection,
                            minSelection: (item.minSelection === undefined) ? -1 : item.minSelection,
                            maxSelection: (item.maxSelection === undefined) ? -1 : item.maxSelection,
                            includes: (item.includes === undefined) ? -1 : item.includes,
                            nameAttachedToParent: item.nameAttachedToParent,
                            taxRateFid: item.taxRateFid,
                            priceReplacedByItemId: priceReplacedByItemId,
                            maxSelectionOverride: (item.maxSelectionOverride === undefined) ? -1 : item.maxSelectionOverride,
                            selectionOverrideEnabled: item.selectionOverrideEnabled,
                            loyaltyStepId: item.loyaltyStepId,
                            expoEnabled: item.expoEnabled
                        };

                        transformedItem.menuVariations.push(menuVariations);
                    }

                });

                sanitizeItem(transformedItem);
                return transformedItem;
            };

            const addMenuItemInQueue = async function (item) {
                addMenuItem(item);
                const resultList = await _executeSequentially();

                return Promise.resolve(resultList[0]);
            };

            const addMenuItem = (item) => {
                let menuIds = item.menuVariations.map((menuVariation) => {
                    return menuVariation.menuId;
                });

                let companyIdsObj = {};
                item.menuVariations.forEach((menuVariation) => {
                    let associatedCompanyIds = menuVariation.associatedCompanyIds || [];
                    for (let companyId of associatedCompanyIds) {
                        companyIdsObj[companyId] = 1;
                    }
                });

                let companyIds = Object.keys(companyIdsObj);

                let transformedItem = transformItem(item, menuIds, companyIds);
                requestQueue.push({type: 'add', payload: transformedItem});
                // return Menu.addMenuItem(transformedItem).$promise;
            };

            const updateMenuItemInQueue = async function (item, menu) {
                updateMenuItem(item, menu);
                const resultList = await _executeSequentially();

                return Promise.resolve(resultList[0]);
            };

            const updateMenuItem = (item, menus) => {
                // this is inconsitent with the way we are handling adding a menu item or
                // modifier option. Saving modifier options is slightly different than saving items
                // due to the lack of need to have menu variations.
                let menuIds = [menus.selected.menuId];

                let companyIdsObj = {};
                let associatedCompanyIds = menus.selected.associatedCompanyIds || [];
                for (let companyId of associatedCompanyIds) {
                    companyIdsObj[companyId] = 1;
                }

                let companyIds = Object.keys(companyIdsObj);

                let transformedItem = transformItem(item, menuIds, companyIds);
                requestQueue.push({type: 'update', payload: transformedItem});
                // return Menu.updateMenuItem(transformedItem);
            };

            const _executeSequentially = function (responseList = []) {
                return new Promise(function (resolve) {
                    if (!requestQueue.length) {
                        resolve(responseList);
                        return responseList;
                    }

                    let request = requestQueue[0];

                    if (request.type == 'add') {
                        Menu.addMenuItem(request.payload).$promise.then(function (response) {
                            responseList.push(response);
                        }).finally(async function () {
                            requestQueue.splice(0, 1);
                            resolve(await _executeSequentially(responseList));
                        });
                    } else {
                        Menu.updateMenuItem(request.payload).$promise.then(function (response) {
                            responseList.push(response);
                        }).finally(async function () {
                            requestQueue.splice(0, 1);
                            resolve(await _executeSequentially(responseList));
                        });
                    }
                });
            };

            const addModifierOptions = (modifierOptions, menus) => {
                // save every modifier option first.
                // let modifierOptionPromiseArr = [];
                modifierOptions.forEach((modifierOption) => {
                    modifierOption.companyId = CurrentSession.getCompany().companyId;
                    modifierOption.mealEquivalencyEligible = true;

                    modifierOption.type = 'item';
                    modifierOption.subtype = 'modifier_option';

                    // this is unfinished and currently a placeholder
                    // saving modifier options is slightly different than saving items
                    // due to the lack of need to have menu variations.
                    if (modifierOption.locationServicePeriodMenuId) {
                        updateMenuItem(modifierOption, menus);
                        // modifierOptionPromiseArr.push(updateMenuItem(modifierOption, menus));
                    } else {
                        addMenuItem(modifierOption);
                        // modifierOptionPromiseArr.push(addMenuItem(modifierOption));
                    }
                });

                // return Promise.all(modifierOptionPromiseArr);
                return _executeSequentially();
            };

            const addModifier = async (modifier, parentItem, menus) => {
                modifier.active = true;
                modifier.type = 'modifier';

                let savedModifier;
                if (modifier.locationServicePeriodMenuId) {
                    // savedModifier = updateMenuItem(modifier, menus);
                    updateMenuItem(modifier, menus);
                } else {
                    // savedModifier = addMenuItem(modifier);
                    addMenuItem(modifier);
                }

                savedModifier = await _executeSequentially();
                return Promise.resolve(savedModifier[0]);
            };

            const deleteMenuItemImages = (menuItem, toDelete) => {
                if (!menuItem.locationServicePeriodMenuId) {
                    return $q.reject();
                }

                let promises = [];
                toDelete.forEach((id) => {
                    const param = {
                        menuImageId: id
                    };
                    promises.push(Menu.deleteMenuItemImage(param));
                });

                return Promise.all(promises);
            };

            const updateMobileMenu = (locationId) => {
                if (!locationId) {
                    return $q.reject();
                }

                return Menu.updateMobileMenu({
                    locationId: locationId
                });
            };

            // items on master product list should be somewhat already following the menu v2 structure.
            const saveItemOnMasterProductList = (item) => {
                // itemDetails.translations is an array of objects.
                const translationArray = Object.keys(item.translations).map((key) => {
                    return item.translations[key];
                });

                let transformedItem = {
                    menuItemId: item.locationServicePeriodMenuId,
                    organizationId: CurrentSession.getCompany().organizationId,
                    itemDetails: {
                        printoutType: item.printoutType,
                        subtype: item.subtype,
                        tags: item.tagNames || [],
                        totalTime: item.totalTime,
                        trackStockEnabled: item.trackStockEnabled,
                        translations: translationArray,
                        type: item.type,
                        upc: item.upc
                    }
                };
                return Menu.updateMenuItem(transformedItem).$promise;
            };

            // copy the item from specified menus to new menus.
            const copyItemFromMenus = (itemId, menuAssociations) => {
                // filter & transform only those with .menuToCopyFrom property
                const filteredMenus = menuAssociations
                    .filter((menu) => menu.associated && menu.hasOwnProperty('menuToCopyFrom'))
                    .map((menu) => {
                        return {
                            menuId: menu.menuId,
                            menuTemplateId: menu.menuToCopyFrom.menuId
                        };
                    });

                const params = {
                    menuItemId: itemId,
                    organizationId: CurrentSession.getCompany().organizationId,
                    menuVariations: filteredMenus
                };

                return Menu.copyItemFromMenu(params).$promise;
            };

            const deleteItemFromCategory = (itemId, menuIds) => {
                const menuVariationsToDelete = menuIds.map((menuId) => {
                    return {
                        menuId: menuId
                    };
                });

                const params = {
                    menuItemId: itemId,
                    organizationId: CurrentSession.getCompany().organizationId,
                    menuVariations: menuVariationsToDelete
                };
                return Menu.deleteItemFromCategory({}, params).$promise;
            };

            // delete the item from specified menus
            const deleteItemFromMenus = (itemId, menuIds, children, isClearUpc = false) => {
                const menuVariationsToDelete = menuIds.map((menuId) => {
                    return {
                        menuId: menuId
                    };
                });

                const params = {
                    menuItemId: itemId,
                    organizationId: CurrentSession.getCompany().organizationId,
                    menuVariations: menuVariationsToDelete
                };

                const values = {
                    processMenuItem: params,
                    modifiers: children,
                    isClearUpc: isClearUpc
                };
                return Menu.deleteItemFromMenu({}, values).$promise;
            };

            // validation to ensure there is at least 1 menu association before proceeding.
            const validateMenuAssociations = (menuAssociations) => {
                let isValid = false;
                if (menuAssociations) {
                    // as long as 1 menu is associated, this can pass.
                    const associatedMenu = menuAssociations.find((menu) => {
                        return menu.associated;
                    });

                    // return the boolean value based on truthy/falsy.
                    isValid = !!associatedMenu;
                }

                return isValid;
            };

            const createNewMenu = (newMenu) => {
                return Menu.createNewMenu(newMenu).$promise;
            };

            const editMenu = (menu) => {
                return Menu.updateMenu(menu).$promise;
            };

            const updatePageName = (menuId, pageIndex, pageName) => {
                return Menu.updatePageName({menuId, pageIndex, pageName}).$promise;
            };

            return {
                loadLocationPrintersByReceiptType: loadLocationPrintersByReceiptType,
                getLocationPrintersByReceiptType: getLocationPrintersByReceiptType,
                loadAvailableMenusByOrganization: loadAvailableMenusByOrganization,
                loadAvailableMenusByCompany: loadAvailableMenusByCompany,
                getMenuName: getMenuName,
                organizationLanguages: organizationLanguages,
                sanitizeItem: sanitizeItem,
                assignPrinterToItem: assignPrinterToItem,
                addMenuItem: addMenuItem,
                updateMenuItem: updateMenuItem,
                addAssociations: addAssociations,
                addModifier: addModifier,
                addModifierOptions: addModifierOptions,
                setMenuVariations: setMenuVariations,
                setTranslations: setTranslations,
                setLanguageImageMap: setLanguageImageMap,
                setAssociatedMenuNames: setAssociatedMenuNames,
                setMenuAssociations: setMenuAssociations,
                deleteMenuItemImages: deleteMenuItemImages,
                updateMobileMenu: updateMobileMenu,
                saveItemOnMasterProductList: saveItemOnMasterProductList,
                copyItemFromMenus: copyItemFromMenus,
                deleteItemFromMenus: deleteItemFromMenus,
                deleteItemFromCategory: deleteItemFromCategory,
                validateMenuAssociations: validateMenuAssociations,
                createNewMenu: createNewMenu,
                editMenu: editMenu,
                updatePageName: updatePageName,
                addMenuItemInQueue: addMenuItemInQueue,
                updateMenuItemInQueue: updateMenuItemInQueue
            };
        }]);
};


'use strict';


const angular = require('angular');
const ngResource = require('angular-resource');

export default angular.module('freshideas.resources.cashier', [ngResource])
    .service('CashierShift', ['$resource', 'EnvConfig', 'SharedDataService', function ($resource, EnvConfig, SharedDataService) {
        var CashierShift = $resource(EnvConfig.host + '/freshideas/web/cashier', {}, {
            'startShift': {method: 'POST', cache: false, url: EnvConfig.host + '/freshideas/web/cashier/startShift'},
            'changeServicePeriod': {method: 'POST', url: EnvConfig.host + '/freshideas/web/cashier/changeServicePeriod'},
            'endShift': {method: 'POST', url: EnvConfig.host + '/freshideas/web/cashier/endShift'},
            'shiftReport': {method: 'GET', url: EnvConfig.host + '/freshideas/web/reports/shiftReport'},
            'shiftReportLight': {method: 'GET', url: EnvConfig.host + '/freshideas/web/reports/shiftReportLight'},
            'shiftStatus': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/shiftStatus'},
            'reconcileAvailableBalances': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/available'},
            'reconcileAvailableBalancesv1': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/availablev1', isArray: true},
            'initializeTransaction': {method: 'POST', url: EnvConfig.host + '/freshideas/web/cashier/initializeTransaction'},
            'addTenderToTransaction': {method: 'POST', url: EnvConfig.host + '/freshideas/web/cashier/addTenderToTransaction'},
            'completeTransaction': {method: 'POST', url: EnvConfig.host + '/freshideas/web/cashier/completeTransaction'},
            'tenderTransaction': {method: 'POST', url: EnvConfig.host + '/freshideas/web/cashier/tenderTransaction'},
            'lookupPatronMealPlans': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/lookupPatronMealPlans'},
            'inventorySearch': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/inventorySearch'},
            'inventoryItems': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/inventoryItems'},
            'inventoryList': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/inventoryList'},
            'lookupUpc': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/upcLookup'},
            'lookupUPCs': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/upcLookups', isArray: true},
            // NOTE: `posMenuAll` not in use anymore. Consider removal?
            'posMenu': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/menu/locationPOSMenus'},
            'allMenuItems': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/menu/allMenuItems'},
            'menuItems': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/menuItems'},
            'closeModal': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/closeModal'},
            'currentCashierShift': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/currentCashierShift'},
            'voidTransaction': {method: 'POST', url: EnvConfig.host + '/freshideas/web/cashier/voidTransaction'},
            'refundTransaction': {method: 'POST', url: EnvConfig.host + '/freshideas/web/cashier/refundTransaction'},
            'refundTransactionByTender': {method: 'POST', url: EnvConfig.host + '/freshideas/web/cashier/refundTransactionByTender'},
            'prepareQuickChargeTransaction': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/prepareQuickChargeTransaction', isArray: true},
            'recentStats': {method: 'GET', url: EnvConfig.host + '/freshideas/web/reports/recentStats'},
            'transactionResult': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/transactionResult'},
            'signupNewPatron': {method: 'POST', url: EnvConfig.host + '/freshideas/web/cashier/signupNewPatron'},
            'emailReceipt': {method: 'POST', url: EnvConfig.host + '/freshideas/web/cashier/emailReceipt'},
            'linkTransactionToPatron': {method: 'POST', url: EnvConfig.host + '/freshideas/web/cashier/linkTransactionToPatron'},
            'linkReceiptToPatron': {method: 'POST', url: EnvConfig.host + '/freshideas/web/cashier/linkReceiptToPatron'},
            'searchTransactions': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/searchTransactions'},
            'lookupGiftCard': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/giftCard'},
            'incrementCancelledTransactions': {method: 'POST', url: EnvConfig.host + '/freshideas/web/cashier/shiftIncrementCancelledTransactions'},
            'emailEndOfShift': {
                method: 'POST',
                headers: {'Content-Type': undefined},
                transformRequest: angular.identity,
                url: EnvConfig.host + '/freshideas/web/cashier/emailEndOfShift'
            },
            'logCashDrawerActivity': {method: 'POST', url: EnvConfig.host + '/freshideas/web/cashier/cashDrawerActivity/log'},
            'getLabelledDiscounts': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/discounts', isArray: true},
            'getAllTaxRates': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/taxRates', isArray: true},
            'createStoreCredit': {method: 'POST', url: EnvConfig.host + '/freshideas/web/cashier/createStoreCredit'},
            'acquireReceiptCounterValue': {method: 'POST', url: EnvConfig.host + '/freshideas/web/cashier/acquireReceiptCounterValue'},
            'getItemTopModifiers': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/itemTopModifiers'},
            'getQuickChargeSession': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/getQuickChargeSession'},
            'startQuickChargeSession': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/cashier/startQuickChargeSession'},
            'endQuickChargeSession': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/cashier/endQuickChargeSession'},
            'switchMenu': {method: 'POST', url: EnvConfig.host + '/freshideas/web/cashier/switchMenu'},
            'getPatronOffers': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/offers', isArray: true},
            'addMissingUpc': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/cashier/addMissingUpc'},
            'reportFailingTransaction': {method: 'POST', url: EnvConfig.host + '/freshideas/web/cashier/reportFailingTransaction'},
            'getFailingTransactions': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/getFailingTransactions', isArray: true},
            'changeFiitServicePeriod': {method: 'POST', url: EnvConfig.host + '/freshideas/web/cashier/changeFiitServicePeriod'},
            'whatsNew': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/helpscout/whats-new'},
            'getNonKioskShiftCount': {method: 'GET', url: EnvConfig.host + '/freshideas/web/cashier/nonKioskShiftCount', cache: false},
        });

        // NOTE: `posMenuAll` not in use anymore. Consider removal?
        CashierShift.posMenuCached = function (params) {
            var paramStr = jQuery.param(params);
            var requestPromise = SharedDataService.posMenu[paramStr];

            if (!requestPromise) {
                var posMenuResponseObj = CashierShift.posMenu(params);

                requestPromise = posMenuResponseObj.$promise;
                SharedDataService.posMenu[paramStr] = requestPromise;
            }

            requestPromise.catch(function (error) {
                SharedDataService.posMenu[paramStr] = null;
            });

            return requestPromise;
        };

        return CashierShift;
    }]);

'use strict';

// Commented By AkAsh Mehta on August 20 2020
// Will find a better name and/or will try merging the current and the said file
// const CartBuilderService = require('../../external/transaction-tender-service.js')
const TransactionTenderService = require('../../external/transaction-tender-service.js');
const NownCalculationService = require('../../external/pos.meal-plan-calculations.js');
const Decimal = require('decimal.js').default;

/**
*** Commented By Akash Mehta on September 8 2020
*** This service is supposed to be used by the front end.
*** Please expose this service to other components as and when required
*** This angular service can be considered as a wrapper over the plain javascript
*** `TransactionTenderService`.
***/
export default angular.module('freshideas.services.transaction', [])
    .service('TransactionService', [
        '$log',
        '$modal',
        'CartBuilderService',
        'CashierShift',
        'CommonOfflineCache',
        'CompanyAttributesService',
        'CurrentSession',
        'GatewayFiit',
        'GuestLabelService',
        'PosStatusService',
        'Pure',
        'SharedDataService',
        'SmbPosService',
        'UI_TENDER_TYPE',
        'PosAlertService',
        'TRANSACTION_SOURCE',
        'OfflineTransactionService',
        function (
            $log,
            $modal,
            CartBuilderService,
            CashierShift,
            CommonOfflineCache,
            CompanyAttributesService,
            CurrentSession,
            GatewayFiit,
            GuestLabelService,
            PosStatusService,
            Pure,
            SharedDataService,
            SmbPosService,
            UI_TENDER_TYPE,
            PosAlertService,
            TRANSACTION_SOURCE,
            OfflineTransactionService) {
            const INVALID_REQUEST = 400;

            // Commented By Akash Mehta on August 21 2020
            // Available Tender types is used to show the options on the tender screen
            var getAdjustmentRounding = function () {
                return TransactionTenderService.getAdjustmentRounding();
            };


            /** Commented By Akash Mehta on September 8 2020
            *** This function is to get available tender types.
            *** It is mainly used by the tender screen on the front-end and
            *** based on the some attributes set for the company, we get the final
            *** structure of the available tender types
            ***/
            const getAvailableTenderTypes = async function (getMobilePaymentTenders = false, isKiosk = false) {
                var appPayLabel = CurrentSession.getOrganizationAppName() + ' App';

                const result = TransactionTenderService.getAvailableTenderTypes(getMobilePaymentTenders, appPayLabel,
                    CompanyAttributesService.hasOtherTenderEnabled(),
                    CompanyAttributesService.hasGiftCardsEnabled(),
                    PosStatusService.isOffline(),
                    GatewayFiit.isEnabled());

                if (isKiosk) {
                    const suspendEnabled = CompanyAttributesService.hasSuspendEnabled();
                    const payAtCounterEnabled = CompanyAttributesService.hasPayAtCounterEnabled();
                    let canAcceptPaymentAtCounter = false;

                    if (suspendEnabled && payAtCounterEnabled) {
                        let shiftCount = await CashierShift.getNonKioskShiftCount().$promise.catch(console.error);
                        canAcceptPaymentAtCounter = shiftCount && shiftCount.data && shiftCount.data > 0;
                    }

                    for (const tenderType of result) {
                        if (tenderType.value === UI_TENDER_TYPE.CARD && CompanyAttributesService.isKioskCreditCardDisabled()) {
                            tenderType.isEnabled = false;
                        } else if (tenderType.value === UI_TENDER_TYPE.GIFTCARD && CompanyAttributesService.isKioskGiftCardDisabled()) {
                            tenderType.isEnabled = false;
                        } else if (tenderType.value === UI_TENDER_TYPE.PAYATCOUNTER) {
                            tenderType.isEnabled = suspendEnabled && payAtCounterEnabled && canAcceptPaymentAtCounter;
                        }
                    }
                }

                return result;
            };

            /** Commented By Akash Mehta on September 8 2020
            *** This function is to select a tender object.
            *** It is mainly tries to imitate the behaviour and events that happen
            *** on selecting a tender from the tender screen on the front-end
            *** It in turn calls the selectTender function in the TransactionTenderService.
            ***/
            var selectTender = function (tenderObj) {
                if (!tenderObj || !tenderObj.value) {
                    $log.error({
                        message: 'Invalid tender Object. No tender selected',
                    });

                    return null;
                }

                return TransactionTenderService.selectTender(tenderObj);
            };

            /** Commented By Akash Mehta on September 8 2020
            *** This function is to get the tender configuration object based on the tenderType.
            *** This is only to be used by the front-end. Please do not use this behaviour for external services.
            *** We should find a better way and definitions (of the tender obj) when rewriting the transaction tender
            *** workflow.
            *** This function in turn calls the getTenderByUiTenderType function in the TransactionTenderService.
            ***/
            var getTenderByUiTenderType = function (uiTenderType, getMobilePaymentTenders = false, toSelect = false) {
                var tender = TransactionTenderService.getTenderByUiTenderType(uiTenderType, getMobilePaymentTenders, toSelect);

                if (!tender) {
                    $log.error({
                        message: 'Invalid tender type. No tenderType passed',
                    });
                }

                return tender;
            };

            /** Commented By Akash Mehta on September 8 2020
            *** This function is to generate a transaction payload and post it to the backend.
            *** The payload generation work is being handled by generateNownTransactionPayload function in TransactionTenderService.
            *** If there is an invalid payload, we fail the transaction. (Need to verify/test its behaviour for offline)
            ***/
            var tenderTransaction = function (transactionObject, nestedCartItems, allTenders, isQuickChargeTransaction = false,
                originalTransactionId, patron, ticketPreGeneratedReceiptCounter, buzzerCode, {isSplitTransaction = false, isTransactionAlreadyInitialized = false, adjustments} = {}) {

                var fiitTransactionDetails;
                if (GatewayFiit.isEnabled()) {
                    fiitTransactionDetails = {};

                    if (isQuickChargeTransaction) {
                        var quickChargeSession = CommonOfflineCache.getQuickChargeSession();
                        if (!quickChargeSession) {
                            return Promise.reject({
                                error: 'error.invalid.quickcharge.session'
                            });
                        }

                        fiitTransactionDetails.quickChargeSessionId = quickChargeSession.quickChargeSessionId;
                    }

                    fiitTransactionDetails.menuPeriodId = (CommonOfflineCache.getCurrentMenuPeriod())
                        ? CommonOfflineCache.getCurrentMenuPeriod().menuPeriodId : undefined;

                    /** Commented By Akash Mehta on August 19 2020
                    *** Not the best place to do this. Please find a better place.
                    ***/
                    if (patron && patron.fiitMpsAccount && patron.fiitMpsAccount.patronId) {
                        fiitTransactionDetails.fiitPatronId = patron.fiitMpsAccount.patronId;
                    }
                }

                var guestLabel = GuestLabelService.getGuestLabel();

                try {
                    let menu = CommonOfflineCache.getCurrentMenu();

                    var transactionPayload = TransactionTenderService.generateNownTransactionPayload(transactionObject, allTenders, {
                        nestedCartItems: nestedCartItems,
                        guestLabel: guestLabel,
                        originalTransactionId: originalTransactionId,
                        ticketPreGeneratedReceiptCounter: ticketPreGeneratedReceiptCounter,
                        buzzerCode: buzzerCode,
                        fiitTransactionDetails: fiitTransactionDetails,
                        isSplitTransaction: isSplitTransaction,
                        isTransactionAlreadyInitialized: isTransactionAlreadyInitialized,
                        menuId: menu ? menu.menuId : undefined,
                        adjustments: adjustments
                    });

                    // Commented By Akash Mehta
                    // We need to identify whether there was an error in generating a trasaction object
                    // or whether there was an error in posting it. We should go into offline only if
                    // there was an error while posting
                    var transactionRequestPromise;
                    if (isSplitTransaction) {
                        if (isTransactionAlreadyInitialized) {
                            transactionRequestPromise = CashierShift.addTenderToTransaction({}, transactionPayload);
                        } else {
                            transactionRequestPromise = CashierShift.initializeTransaction({}, transactionPayload);
                        }
                    } else {
                        transactionRequestPromise = CashierShift.tenderTransaction({}, transactionPayload);
                    }

                    return {
                        promise: transactionRequestPromise.$promise,
                        transactionPayload: transactionPayload
                    };
                } catch (error) {
                    PosAlertService.showAlertByName('general-error', {
                        message: 'There was an error while processing your transaction. Please contact the tech support ASAP'
                    });

                    $log.error({
                        message: 'Error while generating transaction payload',
                        context: {
                            error: error,
                            user: CurrentSession.getUser().username,
                            transactionObject: transactionObject,
                            tenders: allTenders
                        }
                    });

                    throw error;
                }
            };

            /** Commented By Akash Mehta on September 8 2020
            *** This function runs a mock transaction for FIIT. If the mock transaction fails, we return a reject, else we return the
            *** response from  runFiitTransactionMock function in TransactionTenderService.
            *** This function can be used from anywhere in the POS to run a mock fiit transaction
            ***/
            var runFiitTransactionMock = function (transactionObj, patron, useMealEquivalency, adjustments, useFiitMealPlanPriority) {
                return TransactionTenderService.runFiitTransactionMock(transactionObj, patron, !!useMealEquivalency,
                    adjustments, !!useFiitMealPlanPriority)
                    .then(function (data) {
                        return data;
                    }).catch(function (error) {
                        $log.error({
                            'message': '[FATAL] Error while running a fiit transaction mock',
                            'context': {
                                'error': error,
                                'transactionObj': transactionObj,
                                'userName': (CurrentSession.getUser()) ? CurrentSession.getUser().username : ''
                            }
                        });
                        var data = {
                            loading: false,
                            approvedAmount: 0,
                            approvedDCBAmount: 0,
                            approvedMealEqAmount: 0,
                            approvedMeal: 0,
                            outstandingAmount: 0,
                            payments: [],
                            tenderAmounts: {},
                            items: []
                        };

                        var exception = error.exception || {};
                        var appCode = exception.code || -1;
                        var message = exception.message || '';
                        var items = error.items || [];

                        data.error = {
                            appCode: appCode,
                            message: message,
                            items: items
                        };

                        return Promise.reject(data);
                    });
            };

            /** Commented By Akash Mehta on September 8 2020
            *** This function processes the response returned by running a mock transaction for FIIT.
            *** If the processing fails, we throw a honeybadger and throw and error, else we
            *** return the payload generated by the generateFiitTransactionPayload function in the TransactionTenderService.
            *** This function can be used from anywhere in the POS to processes a mock fiit transaction response.
            ***/
            var processFiitTransactionMockResponse = function (nownTransactionObj, mockResponse, fiitPatronId, nownSelectedTender) {
                try {
                    return TransactionTenderService.generateFiitTransactionPayload(nownTransactionObj, {
                        fiitMockResponse: mockResponse,
                        fiitPatronId: fiitPatronId,
                        nownTendersUsed: [nownSelectedTender]
                    });
                } catch (error) {
                    $log.error({
                        message: '[FATAL] Unable to generate Fiit transaction object from the calculation response',
                        context: {
                            calculationResponse: JSON.stringify(mockResponse),
                            user: (CurrentSession.getUser()) ? CurrentSession.getUser().username : '',
                            error: error
                        }
                    });

                    throw error;
                }
            };

            /** Commented By Akash Mehta on September 8 2020
            *** This function updates the Nown Cart based on the response returned by processing a  FIIT mock transaction response.
            *** If the processing fails, we throw a honeybadger and return a reject, else we
            *** return the response returned by the updateNownCartFromFiitTransactionMock function in the TransactionTenderService.
            *** This function can be used from anywhere in the POS to update the card based on the post-processing response of a mock
            *** fiit transaction.
            ***/
            var updateNownCartFromFiitTransactionMock = function (processedFlatCartItems, mockResponse, nownAdjustments) {
                try {
                    return TransactionTenderService.updateNownCartFromFiitTransactionMock(processedFlatCartItems, mockResponse,
                        nownAdjustments);
                } catch (error) {
                    $log.error({
                        message: 'Unable to update Nown cart from the FIIT MOCK response',
                        context: {
                            calculationResponse: JSON.stringify(mockResponse),
                            user: (CurrentSession.getUser()) ? CurrentSession.getUser().username : '',
                            error: error
                        }
                    });

                    throw error;
                }
            };

            /** Commented By Akash Mehta on September 8 2020
            *** This function posts a transaction to the FIIT backend.
            *** It checks whether we have used a fiitTender or not. If we have and we are unable to find one,
            *** we throw a honeybager else we call the  function `generateNownTransactionPayload` in the `TransactionTenderService`
            *** to generate a FIIT transaction payload. Once successful, we post the newly generated FIIT transaction payload to the
            *** FIIT backend. Else, if there is an error, we throw a honeybadger,
            ***/
            var postTransactionToFiit = function (transactionObj, allTenders, patron, transactionId) {
                if (!allTenders || !allTenders.length || !GatewayFiit.isEnabled()) {
                    throw new Error('No tenders found Or Nown-FIIT integration is disabled !');
                }

                var captureTransactionObj;
                try {
                    var fiitTender = allTenders.find((nownTender) => nownTender.transactionTenderDetail
                        && nownTender.transactionTenderDetail.otherType == 'fiitmps');

                    if (transactionObj.hasFiitTender && !fiitTender) {
                        var errorObj = {
                            message: 'Unable to find FIIT Tender when Complete Gateway Function called',
                            context: {
                                transactionUuid: transactionObj.transactionUuid,
                                transactionId: transactionId,
                                locationId: transactionObj.locationId
                            }
                        };

                        $log.error(errorObj);
                        return;
                    }

                    let fiitMockResponse = (fiitTender && fiitTender.transactionTenderDetail) ?
                        fiitTender.transactionTenderDetail.fiitCalculationResponseObj : null;

                    let fiitPatronId = (patron && patron.fiitMpsAccount) ? patron.fiitMpsAccount.patronId : null;

                    let fiitTransactionPayload = TransactionTenderService.generateFiitTransactionPayload(transactionObj, {
                        fiitMockResponse: fiitMockResponse,
                        fiitPatronId: fiitPatronId,
                        nownTendersUsed: allTenders
                    });

                    const fiitServicePeriodId = CommonOfflineCache.getCurrentFiitServicePeriodId();
                    const quickChargeSessionId = CommonOfflineCache.getQuickChargeSession() ?
                    CommonOfflineCache.getQuickChargeSession().quickChargeSessionId : undefined;

                    fiitTransactionPayload.quickChargeSessionId = quickChargeSessionId;

                    captureTransactionObj = {
                        request: fiitTransactionPayload,
                        fiitPosStationId: (patron && patron.fiitMpsAccount) ? patron.fiitMpsAccount.fiitPosStationId : undefined,
                        fiitCashierShiftId: (patron && patron.fiitMpsAccount) ? patron.fiitMpsAccount.fiitCashierShiftId : undefined,
                        fiitLocationId: (patron && patron.fiitMpsAccount) ? patron.fiitMpsAccount.fiitLocationId : undefined,
                        fiitServicePeriodId: fiitServicePeriodId,
                    };

                    return GatewayFiit.recordTransactionOnFiit(captureTransactionObj).then(function (response) {
                        return Promise.resolve(response);
                    }).catch(function (error) {
                        // Commented By Akash Mehta
                        // Any better way to handle this.
                        // It should never fail

                        // Add this transaction to the queue for later processing
                        OfflineTransactionService.pushFiitMpsTransaction(captureTransactionObj);
                        $log.error({
                            message: '[FATAL] Unable to post transaction Obj To FIIT',
                            context: {
                                errorObj: error,
                                fiitPayLoad: captureTransactionObj
                            }
                        });
                    });
                } catch (error) {
                    // Commented By Akash Mehta
                    // Any better way to handle this.
                    // It should never fail

                    // OfflineTransactionService.pushFiitMpsTransaction(captureTransactionObj);
                    $log.error({
                        message: 'Unable to post transaction Obj To FIIT',
                        context: {
                            errorObj: error,
                            fiitPayLoad: captureTransactionObj
                        }
                    });
                }
            };

            /** Commented By Akash Mehta on September 8 2020
            *** Context :
            *** 1. allTenders - An array of tenders already selected/used to pay for the current transaction
            *** 2. selectedTender - A transaction object (created by selectTender function above) that should be present in the
                                    all tenders objects.
            *** 3. adjustments - An adjustments object maintained by the controller/component calling this function
            *** 4. updatedAmount - The new updated Amount that we need to assign to the selected tender.
            ***
            *** This function updates the selectedTender in the allTenders object with the new amount passed. These variables are
            *** being maintained by the controllers/components calling this function on the Nown Frontend.
            *** We need to rewrite the behaviour of selecting new and/or maintaing/updating already selected tenders.
            ***/
            var updateSelectedTenderAmount = function (allTenders, selectedTender, adjustments, updatedAmount) {

                var tenderIndex = allTenders.indexOf(selectedTender);

                if (tenderIndex > -1) {
                    var adjustmentField = selectedTender.field;
                    if (adjustmentField) {
                        var decimalCurrentAdjustmentAmount = new Decimal(selectedTender.amount);
                        var decimalOriginalAdjustmentAmount = new Decimal(adjustments[adjustmentField]);
                        var decimalUpdatedAdjustmentAmount = new Decimal(updatedAmount);
                        var decimalNewAdjustmentAmount = decimalOriginalAdjustmentAmount
                            .minus(decimalCurrentAdjustmentAmount)
                            .plus(decimalUpdatedAdjustmentAmount);

                        adjustments[adjustmentField] = decimalNewAdjustmentAmount.toNearest(SharedDataService.baseDollar).toNumber();
                        selectedTender.amount = updatedAmount;
                    }

                    return;
                }
            };

            /** Commented By Akash Mehta on September 8 2020
            *** We use this concept of posData which is and object containing info about the transaction like the current tender,
            *** all tenders selected for this transaction, if this transaction is a split transaction or not, transactionId (if the
            *** transaction is already initalized in the case of split transaction), etc.
            *** This function generates the required object so it can be called from any part of the POS.
            *** We need to rewrite/clearly define this concept of posData when rewriting transaction tender workflow.
            ***/
            var generateTenderData = function () {
                return TransactionTenderService.generateTenderData();
            };

            /** Commented By Akash Mehta on September 8 2020
            *** We use this concept of posData which is and object containing info about the transaction like the current tender,
            *** all tenders selected for this transaction, if this transaction is a split transaction or not, transactionId (if the
            *** transaction is already initalized in the case of split transaction), etc.
            *** This function generates the required object so it can be called from any part of the POS.
            *** We need to rewrite/clearly define this concept of posData when rewriting transaction tender workflow.
            ***/
            var addSelectedTender = function (tenderData, selectedTender, adjustments) {
                return TransactionTenderService.addSelectedTender(tenderData, selectedTender, adjustments);

            };


            /** Commented By Akash Mehta on September 8 2020
            *** Context :
            *** The concept of quickCharge is really simple. When quick charge is enabled, we try to tender a transaction for a basket
            *** containing only the quick charge item and tendered against only meal units of the selected/scanned patron. This feature
            *** allows campuses to process multiple students quickly into the dining halls without the hassle of manually selecting an
            *** item and tendering the transaction for each patron. Two settings to know for quick charge are :
            *** 1. Quick Charge Item - A item setting per menu period, which points out which quick charge item is enabled against
            ***                        which menu period. The item needs to be meal plan exchangeable (Please updated this if the
            ***                        requirement/behaviour changes in the future)
            *** 2. Quick Charge Session - A unique session per menu period per shift which allows the cashier to run quick charge
            ***                           transactions. A session can be controlled currently on the home page using a toggle.
            ***
            *** Purpose:
            *** This function is used to run a quick charge transaction on the Nown POS frontend. The workflow is as follows:
            *** 1. We gather all the required data. If any of the required data is missing wr throw a honeybadger and reject the request.
            *** 2. Else, we create a cart for a single quickCharge item using the CartBuilderService.
            *** 3. Once the cart is ready, we run a mock FIIT transaction using the patron's meal balances and the newly created cart. If
            ***    the mock response returns and error or indicates a dcb balance used or a remaining balance or if no meal units are used,
            ***    then we reject the request.
            *** 4. Next, we process the mock response.
            *** 5. Next, based on the processed response, we update the Nown Cart to ensure that tax is applied correctly based on the
            ***    selected tender
            *** 6. Once the cart has been updated, we need to tender the transaction.
            *** 7. Once the transaction has been tendered, we post the transaction to FIIT to deduct the patron balance.
            ***/
            var runQuickChargeTransaction = async function (patron) {
                try {

                    var quickChargeSession = CommonOfflineCache.getQuickChargeSession();

                    if (!patron || !quickChargeSession || !quickChargeSession.quickChargeItem) {
                        var gatewayResponse = {
                            exception: {
                                code: INVALID_REQUEST,
                                message: 'Missing one or more required parameters !!',
                                data: {
                                    patron: !!patron,
                                    quickChargeSession: !!quickChargeSession,
                                    quickChargeItem: !!((quickChargeSession) ? quickChargeSession.quickChargeItem : false)
                                }
                            },
                            error: true
                        };
                        return Promise.reject(gatewayResponse);
                    }

                    var quickchargeItem = quickChargeSession.quickChargeItem;
                    var tender = TransactionTenderService.getTenderByUiTenderType(UI_TENDER_TYPE.GATEWAY, false);
                    var tenderData = generateTenderData();
                    var selectedTender = TransactionTenderService.selectTender(tender);
                    var nownAdjustments = NownCalculationService.getNownAdjustmentsObject();
                    nownAdjustments.lockPrice = true;
                    selectedTender.transactionTenderDetail = {
                        otherType: 'fiitmps',
                        otherDisplayName: 'FIIT Meal Card'
                    };
                    var nestedCartItems = [];
                    var processedFlatCartItems = [];
                    var posData = NownCalculationService.createPosData(processedFlatCartItems, patron, true,
                        SmbPosService.shift.locationId, {}, CurrentSession.getCompany().organization.settings);

                    CartBuilderService.addItemToCart(nestedCartItems, quickchargeItem);

                    var transactionObj = CartBuilderService.processCartForPos(nestedCartItems, processedFlatCartItems,
                        nownAdjustments, [], undefined, posData, patron);


                    let mockResponse = await this.runFiitTransactionMock(transactionObj, patron, false, nownAdjustments, true);

                    const isApproved = mockResponse.approvedMealEqAmount || mockResponse.approvedMeal
                        || (mockResponse.approvedDCBAmount && !CompanyAttributesService.disableDCBQuickcharge());

                    const hasValidAmounts = mockResponse.approvedAmount && !mockResponse.outstandingAmount;

                    if (mockResponse.error || !isApproved || !hasValidAmounts) {
                        if (mockResponse.error) {
                            return Promise.reject(mockResponse);
                        } else {
                            return Promise.reject({
                                insufficientBalance: true
                            });
                        }
                    }

                    processFiitTransactionMockResponse(transactionObj, mockResponse, patron.fiitMpsAccount.patronId, selectedTender);
                    updateNownCartFromFiitTransactionMock(processedFlatCartItems, mockResponse, nownAdjustments);

                    TransactionTenderService.addSelectedTender(tenderData, selectedTender, nownAdjustments);

                    transactionObj = CartBuilderService.updateCart(processedFlatCartItems, nownAdjustments, [], undefined, posData, patron);
                    if (!transactionObj || transactionObj.remainingBalance) {
                        return Promise.reject('There is a Remaining Balance of : ' + transactionObj.remainingBalance);
                    }

                    if (patron && patron.fullName) {
                        GuestLabelService.setGuestLabel(patron.fullName);
                    }

                    const tenderTransaction = async () => {
                        let serviceResponse = null;
                        try {
                            serviceResponse = this.tenderTransaction(transactionObj, nestedCartItems, tenderData.allTenders,
                                true, null, patron, null, null);
                        } catch (error) {
                            serviceResponse = null;
                            $log.error({
                                message: 'Unable to tender transaction For QUICKCHARGE',
                                context: {
                                    errorObj: error,
                                    transactionObj: transactionObj,
                                    user: CurrentSession.getUser().username
                                }
                            });
                        }

                        if (serviceResponse == null) {
                            return Promise.reject('Unable to tender transaction');
                        }

                        let tenderTransactionResponse = await serviceResponse.promise;

                        // no need to await postTransactionToFiit, on fail, it will be queued offline for later processing
                        postTransactionToFiit(transactionObj, tenderData.allTenders, patron, tenderTransactionResponse.transactionId);
                        return Promise.resolve(tenderTransactionResponse);
                    };

                    // we want user confirmation before we charge DCB
                    if (mockResponse.approvedDCBAmount && mockResponse.approvedDCBAmount > 0 && CompanyAttributesService.enableDCBQuickchargeConfirmation()) {
                        return new Promise((resolve, reject) => {
                            PosAlertService.showAlertByName('general-alert', {
                                title: 'Patron has no meal units available for this service period.',
                                message: 'Would you like to charge their DCB account instead?',
                                buttonTitleOk: 'PROCEED using DCB',
                                modalCallback: () => resolve(tenderTransaction()),
                                dismissModalCallback: () => reject({preventDCB: true, errorMessage: 'DCB Use Declined'})
                            });
                        });
                    } else {
                        return tenderTransaction();
                    }
                } catch (error) {
                    $log.error(error);
                    return Promise.reject(error);
                }
            };

            var _hasFiitmpsTender = function (tenders) {
                tenders = tenders || [];
                var fiitMpsTender = tenders.find(function (nownTender) {
                    return nownTender.transactionTenderDetail && nownTender.transactionTenderDetail.otherType === 'fiitmps';
                });

                return (fiitMpsTender) ? true : false;
            };

            var startVoidTransaction = function (transaction, modalCloseCallBack, modalDismissCallBack) {
                // Commented By Akash Mehta on Sept 10 2020
                // What is the best way to show/return an error here ?
                if (!transaction || !transaction.transactionId) {
                    PosAlertService.showAlertByName('general-alert', {
                        title: 'No valid transaction found to void',
                    });
                    return;
                }

                CashierShift.transactionResult({
                    transactionId: transaction.transactionId
                }, function (transactionResponse) {
                    var modalInstance = $modal.open({
                        templateUrl: 'pos/refund/pos.transaction.refund.tpl.html',
                        controller: 'PosTransactionRefundCtrl',
                        windowClass: 'smb-pos smb-pos__void-modal',
                        backdrop: 'static',
                        animation: false,
                        resolve: {
                            patron: function () {
                                return transaction.patron;
                            },
                            isLucova: function () {
                                return (transaction.sourceId == TRANSACTION_SOURCE.LUCOVA_PAYMENT
                                    || transaction.sourceId == TRANSACTION_SOURCE.PREORDER);
                            },
                            transaction: function () {
                                return transactionResponse;
                            },
                            description: function () {
                                return '';
                            },
                            mustRefundServicePeriod: function () {
                                return true;
                            },
                            terminalResponses: function () {
                                return transaction.terminalResponses;
                            },
                            refundType: function () {
                                return 'void';
                            },
                            alteredTransactionItems: function () {
                                return null;
                            },
                            hasFiitMpsTender: function () {
                                return _hasFiitmpsTender(transactionResponse.tenders);
                            }
                        }
                    });

                    modalInstance.result.then(function () {
                        SmbPosService.loadStatus();
                        if (modalCloseCallBack) {
                            modalCloseCallBack();
                        }
                    }, function () {
                        if (modalDismissCallBack) {
                            modalDismissCallBack();
                        }
                    });
                }, function () { });
            };

            return {
                tenderTransaction: tenderTransaction,
                runFiitTransactionMock: runFiitTransactionMock,
                processFiitTransactionMockResponse: processFiitTransactionMockResponse,
                updateNownCartFromFiitTransactionMock: updateNownCartFromFiitTransactionMock,
                postTransactionToFiit: postTransactionToFiit,
                runQuickChargeTransaction: runQuickChargeTransaction,
                generateTenderData: generateTenderData,
                addSelectedTender: addSelectedTender,
                updateSelectedTenderAmount: updateSelectedTenderAmount,
                getAvailableTenderTypes: getAvailableTenderTypes,
                getAdjustmentRounding: getAdjustmentRounding,
                getTenderByUiTenderType: getTenderByUiTenderType,
                selectTender: selectTender,
                startVoidTransaction: startVoidTransaction
            };
        }
    ]);

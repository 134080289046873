'use strict';

import Pure from '../../external/pure';

const _validatePrinterSettings = function (printer, stationConfigToAdd = null) {
    let formValidity = {
        isValid: true,
        printerDetails: {},
        stationConfig: {}
    };

    formValidity.isValid = formValidity.isValid && printer.printerName.length > 0
        && printer.selectedManufacturer != null && printer.selectedModel != null;

    switch (printer.protocol) {
        case 'tcp':
            formValidity.isValid = formValidity.isValid && Pure.ipTest(printer.ipAddress) && (printer.port > 0 && printer.port < 65535);
        break;
        case 'bt':
            formValidity.isValid = formValidity.isValid && (printer.bluetoothName && printer.bluetoothName.length > 0);
        break;
        case 'usb':
            formValidity.isValid = formValidity.isValid && (printer.usbName && printer.usbName.length > 0) && printer.usbPid > 0 && printer.usbVid > 0;
        break;
        default:
            // do nothing
    }

    if (!formValidity.isValid) {
        formValidity.printerDetails.msg = 'setupWizard.merchant.printer.edit.erorr.missing.details.msg';
    }

    if (stationConfigToAdd) {
        if (stationConfigToAdd.posStationId <= 0) {
            formValidity.stationConfig.msg = 'setupWizard.merchant.printer.edit.erorr.station.config.select.msg';
            formValidity.isValid = false;
        }

        for (const stationConfig of printer.assignedStationSettings) {
            if (stationConfig.posStationId == stationConfigToAdd.posStationId) {
                formValidity.stationConfig.msg = 'setupWizard.merchant.printer.edit.erorr.station.config.duplicate.msg';
                formValidity.isValid = false;
                break;
            }
        }
    } else if (printer.assignedStationSettings.length) {
        let stationIds = [];
        for (const stationConfig of printer.assignedStationSettings) {
            if (stationConfig.posStationId <= 0) {
                formValidity.stationConfig.msg = 'setupWizard.merchant.printer.edit.erorr.station.config.select.msg';
                formValidity.isValid = false;
                break;
            }

            if (stationIds.includes(stationConfig.posStationId)) {
                formValidity.stationConfig.msg = 'setupWizard.merchant.printer.edit.erorr.station.config.duplicate.msg';
                formValidity.isValid = false;
                break;
            }

            stationIds.push(stationConfig.posStationId);
        }
    }

    return formValidity;
};

const paramList = [
    '$scope',
    '$translate',
    '$log',
    'Platform',
    'IpScanner',
    'BridgedPromise',
    'ElectronPrinter',
    'PosAlertService',
    'Locations',
    'PRINTER_CONSTANTS'
];
const WizardEditPrinterCtrl = function (
    $scope,
    $translate,
    $log,
    Platform,
    IpScanner,
    BridgedPromise,
    ElectronPrinter,
    PosAlertService,
    Locations,
    PRINTER_CONSTANTS
) {
    // ------ BEGIN: DI ------
    this.ngScope = $scope;
    this.ngTranslate = $translate;
    this.ngLog = $log;
    this.Platform = Platform;
    this.IpScanner = IpScanner;
    this.BridgedPromise = BridgedPromise;
    this.ElectronPrinter = ElectronPrinter;
    this.PosAlertService = PosAlertService;
    this.Locations = Locations;
    this.PRINTER_CONSTANTS = PRINTER_CONSTANTS;
    // ------ END: DI --------

    const _parentCtrl = this.ngScope.setupMerchantPrinterReceipt;

    // ------ BEGIN: instance Props & Methods ------
    this.selectedPrinter = _parentCtrl.selectedPrinter;
    this.locationPrinters = _parentCtrl.printers.entries || [];
    this.sessionCompany = _parentCtrl.sessionCompany;
    this.locationPosStations = this.ngScope.merchant.stations;
    this.showEditPrinter = (_parentCtrl.editAction != 'action__printer-add');
    this.manufacturerList = this.loadManufacturerList(this.selectedPrinter.protocol);
    this.connectionTypes = this.PRINTER_CONSTANTS.CONNECTION_TYPES;
    this.fontSizes = [
        {name: 'Small', value: this.PRINTER_CONSTANTS.FONT_SIZE.SMALL},
        {name: 'Medium', value: this.PRINTER_CONSTANTS.FONT_SIZE.MEDIUM},
        {name: 'Large', value: this.PRINTER_CONSTANTS.FONT_SIZE.LARGE},
    ];
    this.feedDirectionOptions = [
        {name: 'Normal', value: this.PRINTER_CONSTANTS.FEED_DIRECTION.NORMAL},
        {name: 'Inverted', value: this.PRINTER_CONSTANTS.FEED_DIRECTION.INVERTED},
    ];
    this.selectedPrinter.formValidity = {
        printerDetails: {},
        stationConfig: {}
    };
    this.printerListToSave = [];
    // ------ END: instance Props & Methods ------
    // INIT
    this.isScaning = false;
    this.showManualAdd = false;
    this.errors = {
        printerScan: {}
    };

    if (this.showEditPrinter && !this.selectedPrinter.isNew) {
        const manufacturerList = this.loadManufacturerList(this.selectedPrinter.protocol);
        this.selectedPrinter.selectedManufacturer = _.findWhere(manufacturerList, {value: this.selectedPrinter.printerManufacturer});
        this.selectedPrinter.selectedModel = _.findWhere(this.selectedPrinter.selectedManufacturer.printers, {value: this.selectedPrinter.printerModel});
    }

    const deregisterPrinterSaveEvnt = this.ngScope.$on('printer-screen__edit:save', this.savePrinters.bind(this));
    this.ngScope.$on('$destroy', function () {
        deregisterPrinterSaveEvnt();
    });
};

WizardEditPrinterCtrl.prototype.loadManufacturerList = function (protocol) {
    return (protocol == 'tcp') ? this.PRINTER_CONSTANTS.tcpIpManufacturers
        : ((protocol == 'usb') ? this.PRINTER_CONSTANTS.usbManufacturers
            : this.PRINTER_CONSTANTS.bluetoothManufacturers);
};

WizardEditPrinterCtrl.prototype.toggleStationSettings = function (stationConfig, key) {
    const _self = this;
    const isDisabled = (key != 'labelPrinter')
        ? (_self.selectedPrinter.selectedManufacturer && _self.selectedPrinter.selectedManufacturer.receiptType == 'sticker')
        : (_self.selectedPrinter.selectedManufacturer && _self.selectedPrinter.selectedManufacturer.receiptType != 'sticker');

    if (!isDisabled) {
        switch (key) {
            case 'secondaryReceiptPrinter':
                stationConfig.defaultSecondaryReceiptPrinter = false;
                // enabling secondaryPrinting
                if (!stationConfig.secondaryReceiptPrinter) {
                    _self.PosAlertService.showAlertByName('set-all-items-kitchen-printer', {
                        title: 'general.confirmation.default.kitchen.printer.ttl',
                        message: 'general.confirmation.default.kitchen.printer.msg',
                        modalCallback: function () {
                            stationConfig.defaultSecondaryReceiptPrinter = true;
                        }
                    });
                }
            break;
            default:
        }

        stationConfig[key] = !stationConfig[key];
    }
};

WizardEditPrinterCtrl.prototype.addStationSettings = function () {
    const formValidity = _validatePrinterSettings(this.selectedPrinter);
    this.selectedPrinter.formValidity = {};

    if (formValidity.isValid) {
        const stationSettingsTemplate = {
            isNew: true,
            posStationId: 0,
            locationPrinterId: this.selectedPrinter.locationPrinterId,
            tenderReceiptPrinter: this.selectedPrinter.selectedManufacturer.receiptType != 'sticker',
            secondaryReceiptPrinter: this.selectedPrinter.selectedManufacturer.receiptType != 'sticker',
            defaultSecondaryReceiptPrinter: false,
            labelPrinter: this.selectedPrinter.selectedManufacturer.receiptType == 'sticker'
        };
        this.selectedPrinter.assignedStationSettings.push(stationSettingsTemplate);
    } else {
        this.selectedPrinter.formValidity = {
            isError: true,
            printerDetails: {msg: formValidity.printerDetails.msg},
            stationConfig: {msg: formValidity.stationConfig.msg}
        };
    }
};

WizardEditPrinterCtrl.prototype.removeStationSettings = function (indexToRemove) {
    let newStationConfigList = [];
    let left = this.selectedPrinter.assignedStationSettings.slice(0, indexToRemove);
    let right = this.selectedPrinter.assignedStationSettings.slice(indexToRemove + 1, this.selectedPrinter.assignedStationSettings.length);
    Array.prototype.push.apply(newStationConfigList, left);
    Array.prototype.push.apply(newStationConfigList, right);
    this.selectedPrinter.assignedStationSettings = newStationConfigList;
    this.selectedPrinter.formValidity = {};
};

WizardEditPrinterCtrl.prototype.validatePrinterSettings = function (printerListToValidate = []) {
    let printerListToSave = [];

    for (const printer of printerListToValidate) {
        let formValidity = _validatePrinterSettings(printer);
        printer.formValidity = {
            isError: false,
            printerDetails: {},
            stationConfig: {}
        };

        if (formValidity.isValid) {
            printerListToSave.push({
                locationPrinterId: printer.locationPrinterId,
                // locationId: printer.locationId,
                printerName: printer.printerName,
                bluetoothName: printer.bluetoothName,
                printerManufacturer: printer.selectedManufacturer.value,
                printerModel: printer.selectedModel.value,
                printerEmulation: printer.selectedModel.emulation,
                printerPortSettings: printer.selectedModel.portSettings,
                protocol: printer.protocol || 'tcp',
                ipAddress: printer.ipAddress,
                port: printer.port,
                usbName: printer.usbName,
                usbPid: printer.usbPid,
                usbVid: printer.usbVid,
                receiptSize: printer.selectedModel.receiptSize,
                customAttributes: JSON.stringify(printer.selectedManufacturer.customAttributes),
                assignedStationSettings: printer.assignedStationSettings
            });
        } else {
            printer.formValidity.isError = true;
            printer.formValidity.printerDetails.msg = formValidity.printerDetails.msg;
            printer.formValidity.stationConfig.msg = formValidity.stationConfig.msg;
        }
    }

    return printerListToSave;
};

WizardEditPrinterCtrl.prototype.savePrinters = function () {
    const _self = this;
    const printerListToSave = (_self.ngScope.setupMerchantPrinterReceipt.editAction != 'action__printer-add' || _self.showManualAdd)
        ? [_self.selectedPrinter] : _self.printerListToSave;

    const validatedPrinterList = _self.validatePrinterSettings(printerListToSave);
    if (validatedPrinterList.length == printerListToSave.length) {
        _self.Locations.upsertPrinters({locationId: _self.sessionCompany.locationId}, {payload: validatedPrinterList}, function (response) {
            _self.ngScope.setupMerchantPrinterReceipt.goToScreen('landing__printer-screen', true);
        }, function (err) {
            _self.ngLog.log('[editPrinter] error occured while saving printer', err);
            _self.ngScope.settingsUpdated(false);
        });
    }
};

WizardEditPrinterCtrl.prototype.showNotification = function () {
    const NOTIFICATION_DURATION = 2000;
    const _self = this;

    _self.errors.printerScan = {
        noShow: true
    };
    _self.isScaning = false;
    _self.showManualAdd = false;

    if (_self.selectedPrinter.protocol == 'tcp') {
        _self.showManualAdd = true;
    }

    setTimeout(function () {
        _self.errors.printerScan = {
            noShow: false
        };
    }, NOTIFICATION_DURATION);
};

WizardEditPrinterCtrl.prototype.scanSelectedPrinterType = function () {
    const timeOutPromise = new Promise(function (resolve, reject) {
        const SCAN_TIMEOUT__INTERVAL = 20000;
        setTimeout(resolve, SCAN_TIMEOUT__INTERVAL);
    });
    const _self = this;
    if (!_self.selectedPrinter.protocol.length
        || !_self.selectedPrinter.assignedStationSettings.length) {
        return;
    }

    const selectedPosStationId = _self.selectedPrinter.assignedStationSettings[0].posStationId;
    _self.printerListToSave = [];
    _self.isScaning = true;

    if (this.selectedPrinter.protocol == 'bt') {
        Promise.race([
            timeOutPromise,
            _self.BridgedPromise.dispatch('getBluetoothPrinters', {})
        ]).then(function (data) {
            if (!data || !data.bluetoothPrinters || !JSON.parse(data.bluetoothPrinters).length) {
                _self.showNotification();
                return;
            }

            const connectedBtPrinters = JSON.parse(data.bluetoothPrinters);
            let btNamesInUse = [];
            // filter selected station bluetooth printers
            for (const printer of _self.locationPrinters) {
                for (const stationConfig of printer.assignedStationSettings) {
                    if (printer.protocol == 'bt' && stationConfig.posStationId == selectedPosStationId) {
                        printer.isFound = connectedBtPrinters.includes(printer.bluetoothName);
                        const manufacturerList = _self.loadManufacturerList(printer.protocol);
                        printer.selectedManufacturer = _.findWhere(manufacturerList, {value: printer.printerManufacturer});
                        printer.selectedModel = _.findWhere(printer.selectedManufacturer.printers, {value: printer.printerModel});

                        btNamesInUse.push(printer.bluetoothName);
                        _self.printerListToSave.push(printer);
                    }
                }
            }

            for (const btName of connectedBtPrinters) {
                if (!btNamesInUse.includes(btName)) {
                    // this is needed to get a deep copy of new printer template
                    _self.printerListToSave.push({
                        printerName: '',
                        bluetoothName: btName,
                        ipAddress: null,
                        port: null,
                        selectedManufacturer: null,
                        selectedModel: null,
                        protocol: _self.selectedPrinter.protocol,
                        isFound: true,
                        isNew: true,
                        // locationId: _self.selectedPrinter.locationId,
                        assignedStationSettings: [{
                            posStationId: _self.selectedPrinter.assignedStationSettings[0].posStationId
                        }]
                    });
                }
            }

            if (_self.printerListToSave.length) {
                _self.selectDetechedPrinter(_self.printerListToSave[0], 0);
            }

            _self.showEditPrinter = true;
            _self.isScaning = false;
        }).catch(function (error) {
            _self.ngLog.error('Error scanning for printers:', error);
            _self.showNotification();
        });
    } else if (this.selectedPrinter.protocol == 'usb') {
        Promise.race([
            timeOutPromise,
            _self.ElectronPrinter.scanForUsbPrinters()
        ]).then(function (printerObjectList = []) {
            if (!printerObjectList.length) {
                _self.showNotification();
                return;
            }

            const foundVIDs = printerObjectList.map((p) => p.VID);
            const foundPIDs = printerObjectList.map((p) => p.PID);

            // filter selected station usb printers
            for (const printer of _self.locationPrinters) {
                for (const stationConfig of printer.assignedStationSettings) {
                    if (printer.protocol == 'usb' && stationConfig.posStationId == selectedPosStationId) {
                        printer.isFound = foundPIDs.includes(printer.usbPid) && foundVIDs.includes(printer.usbVid);
                        const manufacturerList = _self.loadManufacturerList(printer.protocol);
                        printer.selectedManufacturer = _.findWhere(manufacturerList, {value: printer.printerManufacturer});
                        printer.selectedModel = _.findWhere(printer.selectedManufacturer.printers, {value: printer.printerModel});

                        _self.printerListToSave.push(printer);
                    }
                }
            }

            for (const foundPrinter of printerObjectList) {
                let isExists = false;

                for (const stationPrinter of _self.printerListToSave) {
                    if (stationPrinter.usbPid == foundPrinter.PID
                        && stationPrinter.usbVid == foundPrinter.VID) {
                        isExists = true;
                        break;
                    }
                }

                if (!isExists) {
                    _self.printerListToSave.push({
                        printerName: '',
                        usbName: foundPrinter.productName,
                        usbPid: foundPrinter.PID,
                        usbVid: foundPrinter.VID,
                        selectedManufacturer: null,
                        selectedModel: null,
                        protocol: _self.selectedPrinter.protocol,
                        isFound: true,
                        isNew: true,
                        // locationId: _self.selectedPrinter.locationId,
                        assignedStationSettings: [{
                            posStationId: _self.selectedPrinter.assignedStationSettings[0].posStationId
                        }]
                    });
                }
            }

            if (_self.printerListToSave.length) {
                _self.selectDetechedPrinter(_self.printerListToSave[0], 0);
            }

            _self.showEditPrinter = true;
            _self.isScaning = false;
        }).catch(function (error) {
            _self.ngLog.error('Error scanning for printers:', error);
            _self.showNotification();
        });
    } else if (_self.selectedPrinter.protocol == 'tcp') {
        let tcpScanPromise;
        if (_self.Platform.isElectron()) {
            tcpScanPromise = _self.IpScanner.scan(_self.PRINTER_CONSTANTS.PDL_PORT);
        } else {
            tcpScanPromise = _self.BridgedPromise.dispatch('discoverCardTerminal', {port: _self.PRINTER_CONSTANTS.PDL_PORT});
        }

        try {
            Promise.race([
                timeOutPromise,
                tcpScanPromise
            ]).then(function (response) {
                if (!response || !response.cardTerminals || !JSON.parse(response.cardTerminals).length) {
                    _self.showNotification();
                    return;
                }

                const foundIpList = JSON.parse(response.cardTerminals);
                let ipsInUse = [];

                // filter selected station tcp printers
                for (const printer of _self.locationPrinters) {
                    for (const stationConfig of printer.assignedStationSettings) {
                        if (printer.protocol == 'tcp' && stationConfig.posStationId == selectedPosStationId) {
                            printer.isFound = foundIpList.includes(printer.ipAddress);
                            const manufacturerList = _self.loadManufacturerList(printer.protocol);
                            printer.selectedManufacturer = _.findWhere(manufacturerList, {value: printer.printerManufacturer});
                            printer.selectedModel = _.findWhere(printer.selectedManufacturer.printers, {value: printer.printerModel});

                            ipsInUse.push(printer.ipAddress);
                            _self.printerListToSave.push(printer);
                        }
                    }
                }

                for (const ip of foundIpList) {
                    if (!ipsInUse.includes(ip)) {
                        _self.printerListToSave.push({
                            printerName: '',
                            selectedManufacturer: null,
                            selectedModel: null,
                            ipAddress: ip,
                            port: _self.PRINTER_CONSTANTS.PDL_PORT,
                            protocol: _self.selectedPrinter.protocol,
                            isFound: true,
                            isNew: true,
                            // locationId: _self.selectedPrinter.locationId,
                            assignedStationSettings: [{
                                posStationId: _self.selectedPrinter.assignedStationSettings[0].posStationId
                            }]
                        });
                    }
                }

                if (_self.printerListToSave.length) {
                    _self.selectDetechedPrinter(_self.printerListToSave[0], 0);
                }

                _self.showEditPrinter = true;
                _self.isScaning = false;
            }).catch(function (error) {
                // _self.ngScope.isDoneScanning = true;
                // _self.ngScope.isError = true;
                _self.ngLog.error('error scanning for network printers', error);
                _self.showNotification();
            });
        } catch (error) {
            // _self.ngScope.isDoneScanning = true;
            // _self.ngScope.isError = true;
            _self.ngLog.error('error scanning for network printers', error);
            _self.showNotification();
        }
    }
};

WizardEditPrinterCtrl.prototype.selectDetechedPrinter = function (printer, selectedIndex) {
    this.selectedPrinter = printer;
    this.selectedPrinter.index = selectedIndex;
};

WizardEditPrinterCtrl.$inject = paramList;
export default WizardEditPrinterCtrl;
